<template>
  <v-row id="bar" class="ma-0 pl-2">
    <div
      class="text-h5 text-sm-h3 text-md-h2 align-center justify-start d-flex white--text"
    >
      {{ msg }}
    </div>
  </v-row>
</template>

<script>
export default {
  name: "PageBar",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
#bar {
  height: 120px;
  background-color: #2d569e;
  line-height: 130px;
}
</style>
