<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
