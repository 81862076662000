var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"#f2f7ff","height":"100%"},attrs:{"fluid":""}},[_c('PageBar',{attrs:{"msg":"ACCOUNT"}}),_c('v-tabs',{staticClass:"ma-0 pa-0",attrs:{"centered":"","dark":"","background-color":"#2d569e","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"to":"/account/profile"}},[_vm._v(" Profile ")]),_c('v-tab',{attrs:{"to":"/account/education"}},[_vm._v(" Education ")]),_c('v-tab',{attrs:{"to":"/account/training"}},[_vm._v(" Training ")]),_c('v-tab',{attrs:{"to":"/account/certification"}},[_vm._v(" Certification ")]),_c('v-tab',{attrs:{"to":"/account/publication"}},[_vm._v(" Publication ")]),_c('v-tab',{attrs:{"to":"/account/academicservice"}},[_vm._v(" Academic Service ")]),(_vm.userType !== 'RES_STUDENT')?_c('v-tab',{attrs:{"to":"/account/researcher"}},[_vm._v(" researcher ")]):_vm._e(),_c('v-tab',{attrs:{"to":"/account/blog"}},[_vm._v(" Blog ")]),_c('v-menu',{key:_vm.projectTabKey,staticClass:"white",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"to":_vm.tabMenu}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.projectTab())+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{staticClass:"lighten-3 text-uppercase white"},[_c('v-list-item',{staticClass:"white",attrs:{"to":"/account/project"},on:{"click":function($event){return _vm.forceRerender()}},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v(" project ")]),_c('v-list-item',{staticClass:"white",attrs:{"to":"/account/master"},on:{"click":function($event){return _vm.forceRerender()}},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v(" master ")]),_c('v-list-item',{staticClass:"white",attrs:{"to":"/account/phd"},on:{"click":function($event){return _vm.forceRerender()}},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v(" phd ")]),_c('v-list-item',{staticClass:"white",attrs:{"to":"/account/research"},on:{"click":function($event){return _vm.forceRerender()}},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v(" research ")])],1)],1),(
        _vm.$store.state.user.user_type === 'STAFF' && _vm.$store.state.user.is_admin
      )?_c('v-btn',{staticClass:"my-auto py-0",attrs:{"text":""},on:{"click":function($event){_vm.resetpassword_dialog = true}}},[_vm._v(" Reset password ")]):_vm._e()],1),_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[(_vm.$store.state.admin.viewing)?_c('v-row',{staticClass:"mt-2 mb-2"},[_c('div',{staticClass:"pa-5 mx-auto",attrs:{"width":"100%"}},[_c('div',{staticClass:"d-inline"},[_vm._v(" You are watching user id: "+_vm._s(_vm.$store.state.user.user_id)+" ")]),_c('v-btn',{staticClass:"mx-auto d-block",on:{"click":function($event){_vm.$store.commit('adminStopView');
              _vm.$router.replace({ name: 'admin' });}}},[_vm._v("GO BACK")])],1)]):_vm._e(),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mb-10 pa-0",attrs:{"cols":"12","md":"10"}},[_c('v-card',{staticClass:"pb-5",attrs:{"width":"100%"}},[(
                _vm.selectComponent === 'profile' ||
                  _vm.selectComponent === 'researcherprofile'
              )?_c('Profile'):_vm._e(),(_vm.selectComponent === 'education')?_c('Education'):_vm._e(),(_vm.selectComponent === 'publication')?_c('Publication'):_vm._e(),(_vm.selectComponent === 'project')?_c('Project'):_vm._e(),(
                _vm.selectComponent === 'seniorproject' ||
                  _vm.selectComponent === 'isproject'
              )?_c('SeniorProject'):_vm._e(),(_vm.selectComponent === 'master' || _vm.selectComponent === 'phd')?_c('Master'):_vm._e(),(_vm.selectComponent === 'seniordetail')?_c('SeniorDetail'):_vm._e(),(
                _vm.selectComponent === 'masterdetail' ||
                  _vm.selectComponent === 'createmaster' ||
                  _vm.selectComponent === 'createphd' ||
                  _vm.selectComponent === 'phddetail'
              )?_c('MasterDetail'):_vm._e(),(
                _vm.selectComponent === 'createsenior' ||
                  _vm.selectComponent === 'createis'
              )?_c('CreateSenior'):_vm._e(),(_vm.selectComponent === 'certification')?_c('Certification'):_vm._e(),(_vm.selectComponent === 'training')?_c('Training'):_vm._e(),(
                _vm.selectComponent === 'publicationdetail' ||
                  _vm.selectComponent === 'createpublication'
              )?_c('PublicationDetail'):_vm._e(),(_vm.selectComponent === 'research')?_c('ResearchProject'):_vm._e(),(_vm.selectComponent === 'academicservice')?_c('AcademicService'):_vm._e(),(_vm.selectComponent === 'researcher')?_c('Researcher'):_vm._e(),(_vm.selectComponent === 'blog')?_c('Blog'):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.resetpassword_dialog),callback:function ($$v) {_vm.resetpassword_dialog=$$v},expression:"resetpassword_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"color":"#296d98"}},[_vm._v(" Reset password confirm. ")]),_c('v-card-text',[_vm._v("ยืนยันการรีเซ็ทรหัสผ่าน")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.resetpassword_dialog = false}}},[_vm._v(" ยกเลิก ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(" ยืนยัน ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }