<template>
  <v-container class="pt-0" fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row
        class="pr-5 white--text pl-5 title"
        style="background-color: #296d98; height: 50px; line-height: 50px"
        align="center"
      >
        Create
        <v-spacer />
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="10" class="body-1">
          <div>Title <div class="d-inline red--text">*</div></div>
          <v-text-field v-model="title" :rules="rules.titleRules" dense></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
      <v-col cols="10" class="body-1">
        Branch
        <v-text-field v-model="branch" dense></v-text-field>
      </v-col>
    </v-row> -->
      <!--- Test Tag--->
      <v-row justify="center">
        <v-col cols="10">
          Tags
          <v-combobox
            v-model="tag"
            :items="items"
            :search-input.sync="search"
            hide-selected
            label="Tags"
            multiple
            persistent-hint
            small-chips
            :rules="[
                checktags(tag) ||
                  'Each tag must be les than 60 characters',
              ]"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
      <v-col cols="10" class="body-1">
        Tag
        <v-text-field v-model="tag" dense></v-text-field>
      </v-col>
    </v-row> -->
      <v-row justify="center" align="center">
        <v-col cols="10" class="body-1">
          Student 1
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="stuCode1"
                dense
                label="Code"
                :rules="rules.codeRules"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="firstNameStu1"
                dense
                label="First Name"
                :rules="rules.studentNameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="lastNameStu1"
                dense
                label="Last Name"
                :rules="rules.studentNameRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="10" class="body-1">
          Student 2
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="stuCode2"
                dense
                label="Code"
                :rules="rules.codeRules"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="firstNameStu2"
                dense
                label="First Name"
                :rules="rules.studentNameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="lastNameStu2"
                dense
                label="Last Name"
                :rules="rules.studentNameRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10" class="body-1">
          <div>Project Semester <div class="d-inline red--text">*</div></div>
          <v-select
            :items="semesterItems"
            v-model="projectSemester"
            dense
            :rules="rules.yearRules"
          ></v-select>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10" class="body-1">
          Abstract
          <v-textarea v-model="abstract" outlined></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          Status of project
          <v-checkbox v-model="is_done" label="Done"></v-checkbox>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn @click="save()" color="primary">Create</v-btn>
        <v-btn @click="cancle()" class="mr-5 ml-5 white--text" color="red"
          >Cancel</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import api from "../../../../../api.config";
import axios from "axios";
import rules from "../../../../../rules";

export default {
  data() {
    return {
      rules,
      valid: true,
      title: "",
      tag: [],
      stuCode1: "",
      stuCode2: "",
      firstNameStu1: "",
      lastNameStu1: "",
      firstNameStu2: "",
      lastNameStu2: "",
      projectSemester: "",
      abstract: "",
      is_done: false,
      items: [],
      search: null,
    };
  },
  methods: {
    checktags(tags) {
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          if (tag.length > 60) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    cancle() {
      this.$router.go(-1);
    },
    save() {
      if (this.$refs.form.validate()) {
        let _this = this;
        var headers = {
          authorization: this.$store.state.user.token,
        };
        let data = {
          adviser_user_id1: this.$store.state.user.user_id,
          student_code1: this.stuCode1,
          student_firstname1: this.firstNameStu1,
          student_surname1: this.lastNameStu1,
          student_code2: this.stuCode2,
          student_firstname2: this.firstNameStu2,
          student_surname2: this.lastNameStu2,
          project_type:
            this.$route.params.component === "createsenior" ? "PROJECT" : "IS",
          project_title: this.title,
          project_semester: this.projectSemester,
          project_abstract: this.abstract,
          project_isdone: this.is_done,
          tags: this.tag,
        };
        for (const key of Object.keys(data)) {
          if (data[key] == "") delete data[key];
        }
        axios
          .post(api.routes.user.create.seniorProject, data, {
            headers: headers,
          })
          .then(() => {
            _this.$router.go(-1);
          });
      }
    },
    fetchData() {
      let _this = this;
      let url = api.routes.user.get.skillAll;
      axios.get(url).then((item) => {
        _this.items = item.data.result;
      });
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    semesterItems: {
      get() {
        let year = new Date().getFullYear();
        let arry = [];
        for (let index = year; index > year - 40; index--) {
          arry.push(index.toString());
        }
        return arry;
      },
    },
  },
};
</script>

<style></style>
