const BASE_URL = 'https://expert.it.kmitl.ac.th/api'
// const BASE_URL = "http://localhost:3000/api";
const BASE_URL2 = "http://expert.it.kmitl.ac.th:3000/api";
const SCHOLAR_URL = "https://scholar.it.kmitl.ac.th/api/v1";
// const CARBON_URL = 'http://10.0.15.6:8000/api/public/'
module.exports = {
  routes: {
    academicservice: {
      category: BASE_URL + "/academicservice/category",
    },
    project: BASE_URL + "/project",
    project_supervisor: BASE_URL + "/project/supervisor",
    dashboard: BASE_URL + "/dashboard/info",
    publication: BASE_URL + "/publication",
    tagrecomment: BASE_URL2 + "/publication/recommendation/tag",
    base: BASE_URL,
    profile: BASE_URL + "/user/profile/",
    studentProfile: BASE_URL + "/user/profile/student/",
    image: BASE_URL + "/user/image/",
    skill: BASE_URL + "/user/skill/",
    fetchSkill: BASE_URL + "/user/skill/fetch/",
    login: {
      default: BASE_URL + "/authen/login",
      ldap: BASE_URL + "/authen/login/ldap",
      checktoken: BASE_URL + "/authen/checktoken",
      reset: BASE_URL + "/authen/resetpassword",
    },
    author: {
      name: SCHOLAR_URL + "/author/name",
      id: SCHOLAR_URL + "/author/id",
    },
    user: {
      get: {
        certificateAll: BASE_URL + "/user/certificate/all/",
        educationAll: BASE_URL + "/user/education/all/",
        trainingAll: BASE_URL + "/user/training/all/",
        seniorProjectAll: BASE_URL + "/user/seniorproject/all/",
        seniorProjectById: BASE_URL + "/user/seniorproject/",
        skillAll: BASE_URL + "/web/skill/getAll",
        researcherAll: BASE_URL + "/user/research/all/",
        researcher: BASE_URL + "/user/research/",
        scholar: BASE_URL + "/googlescholar/user/",
        searchScholar: BASE_URL + "/googlescholar/search/",
        publicationAllById: BASE_URL + "/publication/user/",
        scival: BASE_URL + "/scival/authors/metrics/",
        publicationById: BASE_URL + "/publication/",
        getRecommentTag: BASE_URL + "/publication/recommendation/tag/",
        academicService: BASE_URL + "/academicservice/user/",
        researchProject: BASE_URL + "/research/user/",
        researcherPeople: BASE_URL + "/user/researcher/",
      },
      delete: {
        certificate: BASE_URL + "/user/certificate/",
        education: BASE_URL + "/user/education/",
        training: BASE_URL + "/user/training/",
        seniorProject: BASE_URL + "/user/seniorproject/",
        academicService: BASE_URL + "/academicservice/",
        researchProject: BASE_URL + "/research/",
        publicationById: BASE_URL + "/publication/",
      },
      update: {
        certificate: BASE_URL + "/user/certificate/",
        education: BASE_URL + "/user/education/",
        training: BASE_URL + "/user/training/",
        seniorProjectById: BASE_URL + "/user/seniorproject/",
        addTagPublication: BASE_URL + "/publication/tag/",
        putPublicationInCV: BASE_URL + "/publication/cv/",
        researchProject: BASE_URL + "/research/",
      },
      create: {
        certificate: BASE_URL + "/user/certificate/",
        education: BASE_URL + "/user/education/",
        training: BASE_URL + "/user/training/",
        seniorProject: BASE_URL + "/user/seniorproject",
        createResearcher: BASE_URL + "/user/createresearcher",
        researcher: BASE_URL + "/user/research",
        link: BASE_URL + "/user/link/",
        academicService: BASE_URL + "/academicservice/",
        researchProject: BASE_URL + "/research/",
      },
    },
    admin: {
      profile: BASE_URL + "/admin/user",
      userskills: BASE_URL + "/admin/user/skills",
      alluser: BASE_URL + "/admin/user/all",
      image: BASE_URL + "/admin/user/image",
      lablist: BASE_URL + "/admin/lab/all",
      lab: BASE_URL + "/admin/lab",
      addmember: BASE_URL + "/admin/lab/member/add",
      deletemember: BASE_URL + "/admin/lab/member/delete",
      // syncScholar: BASE_URL + '/new_publication',
      syncSciVal: BASE_URL + "/scival/authors/sync",
      createuserbyxlsx: BASE_URL + "/admin/user/createbyxlsx",
    },
    web: {
      universityByName: BASE_URL + "/web/university/findByName/",
      peopleAll: BASE_URL + "/web/people/getall",
      peopleByName: BASE_URL + "/web/user/search/",
      getAllUserFullName: BASE_URL + "/web/people/getall/fullname",
      randomExpert: BASE_URL + "/web/people/random/",
      googlescholarByUser: BASE_URL + "/googlescholar/user/",
      allPublication: BASE_URL + "/publication",
      randomPublication: BASE_URL + "/publication/random/",
    },
    lab: {
      create: BASE_URL + "/lab",
      all: BASE_URL + "/lab/all",
      byid: BASE_URL + "/lab/",
      addmember: BASE_URL + "/lab/member/add/",
      deletemember: BASE_URL + "/lab/member/delete/",
      image: BASE_URL + "/lab/image/",
    },
    blog: {
      allBlog: BASE_URL + "/blog",
      eachBlog: BASE_URL + "/blog/",
      authorBlog: BASE_URL + "/blog/author/",
      imageBlog: BASE_URL + "/blog/image/",
    },
  },
};
