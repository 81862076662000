<template>
  <v-container class="pt-0">
    <v-row align="center">
      <v-col cols="5" md="3" lg="2" class="mt-5">
        <div class="">
          <div v-if="imageBinary == ''" class="text-center no-img">Image</div>
          <v-img
            v-if="imageBinary != ''"
            class="mx-auto"
            :src="this.imageBinary"
            style="object-fit: fill"
            width="200px"
            aspect-ratio="1"
          >
          </v-img>
          <div class="mt-3">
            <v-btn
              color="primary"
              class="mx-auto red text-none d-block"
              rounded
              depressed
              :loading="isSelecting"
              @click="onButtonClick"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              Upload image
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="handleImage"
            />
          </div>
        </div>
      </v-col>
      <v-col class="text-left">
        <p class="title">
          {{ userData.firstname_en }} {{ userData.lastname_en }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="subtitle1">Identify information</p>
      </v-col>
    </v-row>
    <hr class="mb-5" />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">User type <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-select
            v-model="userData.user_type"
            :items="user_type_list"
            label="type"
            :rules="rules.userTypeRules"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">is Admin <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-select
            v-model="userData.is_admin"
            :items="[true, false]"
            :rules="rules.isAdminRules"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Username <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.username"
            outlined
            dense
            required
            :rules="rules.usernameRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Password <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.password"
            type="password"
            outlined
            dense
            required
            :rules="rules.passwordRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="subtitle1">Account Information (ภาษาไทย)</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Prefix <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.name_prefix_th"
            outlined
            dense
            :rules="rules.nameTHRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Firstname <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.firstname_th"
            outlined
            dense
            :rules="rules.nameTHRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Lastname <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.lastname_th"
            outlined
            dense
            :rules="rules.nameTHRules"
          />
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row>
        <v-col>
          <p class="subtitle1">Account Information (ภาษาอังกฤษ)</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Prefix <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.name_prefix_en"
            outlined
            dense
            :rules="rules.nameENRules"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Firstname <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.firstname_en"
            outlined
            dense
            :rules="rules.nameENRules"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Lastname <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.lastname_en"
            outlined
            dense
            :rules="rules.nameENRules"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">Position <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.position"
            outlined
            dense
            :rules="rules.positionRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Bio</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-textarea v-model="userData.bio" outlined :rules="rules.bioRules" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Scival ID</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            dense
            v-model="userData.scival_id"
            outlined
            :rules="rules.scivalIdRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="userData.user_type == 'RESEARCH STUDENT'">
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Student ID</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            dense
            v-model="userData.student_id"
            outlined
            :rules="rules.codeStrictRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="subtitle1">Contact Information</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Office Tel</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.office_tel"
            :rules="rules.officeTelRules"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Mobile</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.mobile"
            :rules="rules.mobileRules"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">E-mail <div class="d-inline red--text">*</div> </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.email"
            :rules="rules.emailRules"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">ORCID</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="userData.orcid"
            outlined
            dense
            :rules="rules.orcidRules"
          />
        </v-col>
      </v-row>
      <hr class="mb-5" />
    </v-form>
    <v-row justify="end" class="pr-5 mt-10 mb-5">
      <div class="mx-auto">
        <v-btn @click="saveToDb()" color="primary">+ CREATE</v-btn>
      </div>
    </v-row>
    <v-alert
      z-index="999"
      transition="scale-transition"
      v-model="alert.show"
      style="
        z-index: 999;
        width: 200px;
        position: fixed;
        bottom: 0px;
        right: 20px;
      "
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
  </v-container>
</template>

<script>
const apiConfig = require("../../../api.config");
const rules = require("../../../rules");
import axios from "axios";
export default {
  data() {
    return {
      alertType: "",
      valid: true,
      rules: rules,
      alert: {
        type: "",
        message: "",
        show: false,
        complete: false,
        fail: false,
      },
      user_type_list: ["LECTURER", "STAFF", "RESEARCH STUDENT"],
      confirmpassword: "",
      selected_user_type: "",
      isSelecting: false,
      skillData: {
        skill_array: [],
        new_name: "",
      },
      userData: {
        username: "",
        password: "",
        usertype: "",
        user_id: "",
        name_prefix_th: "",
        name_prefix_en: "",
        firstname_th: "",
        firstname_en: "",
        lastname_th: "",
        lastname_en: "",
        position: "",
        bio: "",
        mobile: "",
        office_tel: "",
        email: "",
        orcid: "",
        user_type: "",
        is_admin: false,
        student_id:"",
      },
      imageBinary: "",
    };
  },
  created() {
    setTimeout(() => {
      this.alert.show = false;
    }, 5000);
  },
  methods: {
    alertmsg(type, msg) {
      this.alert.type = type;
      this.alert.message = msg;
      this.alert.show = true;
      setTimeout(() => {
        this.alert.show = false;
      }, 5000);
    },
    async saveToDb() {
      if (this.$refs.form.validate()) {
        try {
          //update profile
          let dataObj = this.userData;
          if (dataObj.user_type == "RESEARCH STUDENT")
            dataObj.user_type = "RES_STUDENT";

          for (var key in dataObj) {
            if (
              dataObj[key] === null ||
              dataObj[key] === undefined ||
              dataObj[key] === ""
            ) {
              delete dataObj[key];
            }
          }

          let attempt = (
            await axios.post(apiConfig.routes.admin.profile, dataObj, {
              headers: { authorization: this.$store.state.user.token },
            })
          ).data;

          if (attempt.statusCode == 200) {
            try {
              if (this.selectedFile) {
                await this.uploadAvatar(
                  this.selectedFile,
                  attempt.result.user_id
                );
              }
            } catch (error) {
              console.log(error);
            }

            this.alertmsg("success", "Create complete");
            this.$refs.form.reset();
            this.imageBinary = "";
            this.isSelecting = false;
          } else {
            this.alertmsg(
              "error",
              attempt.message ||
                "Something went wront... plese try again later."
            );
          }
        } catch (error) {
          this.alertmsg(
            "error",
            "Something went wront... plese try again later."
          );
        }
      }
    },
    async uploadAvatar(file, user_id) {
      try {
        let formData = new FormData();
        formData.append("file", file);
        const result = (
          await axios.put(apiConfig.routes.image + user_id, formData, {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          })
        ).data;
        if (result.statusCode == 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    handleImage(e) {
      this.selectedFile = e.target.files[0];
      this.createBinaryImage(e.target.files[0]);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    createBinaryImage(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageBinary = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

<style>
.graytext {
  color: gray;
}

.no-img {
  line-height: 150px;
  margin: auto;
  width: 200px;
  height: 150px;
  border: 5px dashed lightgrey;
  border-radius: 5px;
  color: lightgrey;
  min-width: 100px;
  max-width: 150px;
  min-height: 100px;
}
</style>
