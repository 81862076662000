<template>
  <v-container class="pt-0" fluid>
    <v-row
      class="pr-5 white--text pl-5 title"
      style="background-color: #296d98; height: 50px; line-height: 50px"
      align="center"
    >
      Detail
      <v-spacer />
      <v-btn color="white" icon v-on:click="changeEdit()">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row v-if="pageLoading" class="mt-10 mb-10" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-container v-if="!pageLoading">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-5" align="center">
          <v-col cols="8" class="body-1">
            <div>Title <div v-if="edit" class="d-inline red--text">*</div></div>
            <p class="mt-4" v-if="!edit">{{ data.project_title }}</p>
            <v-text-field
              v-if="edit"
              dense
              v-model="data.project_title"
              :rules="rules.titleRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="8" class="body-1">
            Tag
            <p class="mt-4" v-if="!edit">{{ tagToString() }}</p>
            <v-combobox
              v-if="edit"
              v-model="data.skills"
              :items="items"
              :search-input.sync="search"
              hide-selected
              multiple
              persistent-hint
              small-chips
              :rules="[
                checktags(data.skills) ||
                  'Each tag must be les than 60 characters',
              ]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="8" class="body-1">
            Student 1
            <v-row v-if="!edit">
              <v-col cols="3">
                <p>{{ data.student_code1 }}</p>
              </v-col>
              <v-col cols="4">
                <p>{{ data.student_firstname1 }}</p>
              </v-col>
              <v-col cols="5">
                <p>{{ data.student_surname1 }}</p>
              </v-col>
            </v-row>
            <v-row v-if="edit">
              <v-col cols="3">
                <v-text-field
                  dense
                  v-model="data.student_code1"
                  label="Code"
                  :rules="rules.codeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  v-model="data.student_firstname1"
                  label="First Name"
                  :rules="rules.studentNameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  dense
                  v-model="data.student_surname1"
                  label="Last Name"
                  :rules="rules.studentNameRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="8" class="body-1">
            Student 2
            <v-row v-if="!edit">
              <v-col cols="3">
                <p>{{ data.student_code2 }}</p>
              </v-col>
              <v-col cols="4">
                <p>{{ data.student_firstname2 }}</p>
              </v-col>
              <v-col cols="5">
                <p>{{ data.student_surname2 }}</p>
              </v-col>
            </v-row>
            <v-row v-if="edit">
              <v-col cols="3">
                <v-text-field
                  dense
                  v-model="data.student_code2"
                  label="Code"
                  :rules="rules.codeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  v-model="data.student_firstname2"
                  label="First Name"
                  :rules="rules.studentNameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  dense
                  v-model="data.student_surname2"
                  label="Last Name"
                  :rules="rules.studentNameRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row justify="center">
      <v-col cols="8" class="body-1">
        Start date
        <v-text-field type="date" dense></v-text-field>
      </v-col>
      </v-row> -->
        <v-row justify="center" align="center">
          <v-col cols="8" class="body-1">
            <div>Project Semester <div v-if="edit" class="d-inline red--text">*</div></div>
            <p class="mt-4" v-if="!edit">{{ data.project_semester }}</p>
            <!-- <v-text-field
          v-if="edit"
          v-model="data.project_semester"
        ></v-text-field> -->
            <v-select
              v-if="edit"
              :items="semesterItems"
              v-model="data.project_semester"
              dense
              :rules="rules.yearRules"
            ></v-select>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="8" class="body-1">
            Abstract
            <p class="mt-4" v-if="!edit">{{ data.project_abstract }}</p>
            <v-textarea
              outlined
              v-model="data.project_abstract"
              v-if="edit"
              :rules="rules.abstractRules"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="8" class="text-body-1">
            Status of project
            <p class="mt-4" v-if="!edit">
              {{ data.project_isdone ? "Done" : "On Going" }}
            </p>
            <v-checkbox
              v-if="edit"
              v-model="data.project_isdone"
              label="Done"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="edit" justify="end">
          <v-btn color="primary" @click="save()">Save</v-btn>
          <v-btn @click="cancle()" color="red" class="white--text ml-5 mr-5"
            >Cancel</v-btn
          >
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import rules from "../../../../../rules";
import axios from "axios";
import api from "../../../../../api.config";
export default {
  data() {
    return {
      rules,
      valid: true,
      data: null,
      items: ["Gaming", "Programming", "Vue", "Vuetify"],
      search: null,
      edit: false,
      tempData: null,
      pageLoading: true,
    };
  },
  methods: {
    checktags(tags) {
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          if (tag.length > 60) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    fetchData() {
      let _this = this;
      let url = api.routes.user.get.seniorProjectById + this.$route.query.id;
      var headers = {
        authorization: this.$store.state.user.token,
      };
      axios
        .get(url, { headers: headers })
        .then((item) => {
          let data = item.data.result.sproject;
          let skill = [];
          data.skills.forEach((item) => {
            skill.push(item.skill_name);
          });
          data.skills = skill;
          _this.data = data;
        })
        .finally(() => {
          let _this = this;
          let url = api.routes.user.get.skillAll;
          axios
            .get(url)
            .then((item) => {
              _this.items = item.data.result;
            })
            .finally(() => {
              this.pageLoading = false;
            });
        });
    },
    tagToString() {
      let result = "";
      this.data.skills.forEach((item, index) => {
        if (index === 0) {
          result += item;
        } else {
          result += ", " + item;
        }
      });
      return result;
    },
    changeEdit() {
      this.edit = !this.edit;
      this.tempData = JSON.parse(JSON.stringify(this.data));
    },
    cancle() {
      this.data = this.tempData;
      this.edit = !this.edit;
    },
    save() {
      if (this.$refs.form.validate()) {
        let _this = this;
        const url =
          api.routes.user.update.seniorProjectById +
          this.$route.query.id.toString();
        const headers = {
          authorization: this.$store.state.user.token,
        };
        const data = {
          adviser_user_id1: this.data.adviser_user_id1,
          student_code1: this.data.student_code1,
          student_firstname1: this.data.student_firstname1,
          student_surname1: this.data.student_surname1,
          student_code2: this.data.student_code2,
          student_firstname2: this.data.student_firstname2,
          student_surname2: this.data.student_surname2,
          project_title: this.data.project_title,
          project_semester: this.data.project_semester,
          project_abstract: this.data.project_abstract,
          project_isdone: this.data.project_isdone,
          tags: this.data.skills,
        };
        for (const key of Object.keys(data)) {
          if (data[key] == "") delete data[key];
        }
        axios.put(url, data, { headers: headers }).then(() => {
          _this.edit = !_this.edit;
        });
      }
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    semesterItems: {
      get() {
        let year = new Date().getFullYear();
        let arry = [];
        for (let index = year; index > year - 40; index--) {
          arry.push(index.toString());
        }
        return arry;
      },
    },
  },
};
</script>

<style></style>
