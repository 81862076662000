<template>
  <v-row class="ma-0 pa-2 white justify-start justify-sm-center" align="center">
    <div class="d-flex justify-start align-center my-1" v-if="filterItem">
      <span class="text-body-1 flex-grow-1 flex-shrink-0" style="min-width:70px"
        >Filter by</span
      >
      <div class="px-3">
        <v-select
          :items="filterItem"
          v-model="filterVal"
          outlined
          hide-details=""
          dense
        ></v-select>
      </div>
    </div>
    <div class="d-flex justify-start align-center my-1">
      <span class="text-body-1 flex-grow-1 flex-shrink-0" style="min-width:70px"
        >Sort by</span
      >
      <div class="px-3">
        <v-select
          :items="item1"
          v-model="data1Val"
          outlined
          hide-details=""
          dense
        ></v-select>
      </div>
    </div>
    <div class="d-flex justify-start align-center my-1">
      <span
        class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
        style="min-width:70px"
        >Order by</span
      >
      <div class="px-3">
        <v-select
          :items="item2"
          :item-value="(v) => v.value"
          :item-text="(v) => v.name"
          v-model="data2Val"
          outlined
          hide-details=""
          dense
        ></v-select>
      </div>
    </div>
    <div class="d-flex justify-start align-center my-1">
      <span
        class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
        style="min-width:70px"
        >Search</span
      >
      <div class="px-3">
        <v-text-field
          outlined
          hide-details=""
          :placeholder="placeholder"
          dense
          v-model="searchVal"
          clearable
        ></v-text-field>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    data1: String,
    item1: Array,
    data2: String,
    item2: Array,
    search: String,
    filterItem: Array,
    filter: String,
    placeholder: String,
  },
  computed: {
    data1Val: {
      get() {
        return this.data1;
      },
      set(v) {
        this.$emit("changeData1", v);
      },
    },
    data2Val: {
      get() {
        return this.data2;
      },
      set(v) {
        this.$emit("changeData2", v);
      },
    },
    searchVal: {
      get() {
        return this.search;
      },
      set(v) {
        this.$emit("Search", v);
      },
    },
    filterVal: {
      get() {
        return this.filter;
      },
      set(v) {
        this.$emit("Filter", v);
      },
    },
  },
};
</script>

<style scoped>
</style>
