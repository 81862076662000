<template>
  <Layout class="page-contrainer">
      <PageBar :msg="userType === 'RES_STUDENT' ? 'RESEARCHER' : 'LECTURER'" />
      <!-- Sort -->
      <SortBy
        :data1="order1"
        :item1="items1"
        @changeData1="(v) => dataChangeHandler(v, 'order1')"
        :data2="order2"
        :item2="items2"
        @changeData2="(v) => dataChangeHandler(v, 'order2')"
        :search="searchText"
        @Search="(v) => (searchText = v)"
        placeholder="Search by Name"
      />
      <Loading v-if="loading" class="content" />
      <v-row justify="center" v-else class="ma-0 content">
        <v-col cols="12" md="11" lg="10" xl="8">
          <v-row jutify="center" class="ma-0">
            <v-col
              cols="12"
              md="6"
              xl="4"
              v-for="item in people"
              :key="item.user_id"
              class="d-flex justify-center"
            >
              <PeopleCard
                :name="
                  (item.name_prefix_en || '') +
                    ' ' +
                    item.firstname_en +
                    ' ' +
                    item.lastname_en
                "
                :photo="item.file !== null ? apiBase + item.file.url : null"
                :uid="item.user_id"
                :expertise="item.skills"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0" justify="center" align="center">
        <v-pagination
          class="mt-5 mb-5"
          v-model="page"
          :length="allPage"
          :total-visible="7"
        ></v-pagination>
      </v-row>
  </Layout>
</template>

<script>
import PageBar from "../components/PageBar";
import PeopleCard from "../components/PeopleCard";
import Loading from "../components/common/Loading";
import api from "../../api.config";
import axios from "axios";
import SortBy from "../components/SortBy";
import Layout from "../components/layout/Layout.vue";

export default {
  name: "Researcher",
  components: {
    PageBar,
    PeopleCard,
    Loading,
    SortBy,
    Layout,
  },
  data() {
    return {
      order1: "Name",
      order2: "ASC",
      items1: ["Name", "Position"],
      items2: [
        { name: "A - Z", value: "ASC" },
        { name: "Z - A", value: "DESC" },
      ],
      loading: true,
      people: null,
      page: 1,
      searchText: "",
      apiBase: api.routes.base,
      allPage: null,
      userType:
        this.$route.params.userType === "researcher"
          ? "RES_STUDENT"
          : "LECTURER",
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$route.params.userType"(to) {
      this.page = 1;
      this.userType = to === "researcher" ? "RES_STUDENT" : "LECTURER";
      this.fetchData();
    },
    searchText() {
      this.page = 1;
      this.fetchData();
    },
    async order1() {
      await this.fetchData();
    },
    async order2() {
      await this.fetchData();
    },
    page() {
      this.fetchData();
    },
  },
  methods: {
    dataChangeHandler(v, change) {
      if (change === "order1") this.order1 = v;
      else {
        this.order2 = v;
      }
    },
    fetchData() {
      this.loading = true;
      let url = api.routes.web.peopleAll + "/" + this.userType;
      let params = {
        page: this.page,
        size: 8,
        orderby: this.order1,
        orderoption: this.order2,
        search_text: this.searchText,
      };
      if (this.searchText === "") delete params["search_text"];
      console.log(params);
      axios.get(url, { params }).then((item) => {
        this.allPage = item.data.pageCount;
        this.people = item.data.result.rows;
      });
      this.loading = false;
    },
  },
};
</script>
