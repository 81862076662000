<template>
  <v-container class="pt-0">
    <v-row class="mt-5">
      <v-col class="mt-5 col-12">
        <div>
          <div v-if="!imageBinary" class="text-center no-img-lab">รูปภาพ</div>
          <v-img
            v-if="imageBinary"
            class="mx-auto"
            :src="this.imageBinary"
            style="object-fit: fill"
            width="350px"
            height="250px"
          >
          </v-img>
          <div class="mt-3">
            <v-btn
              color="primary"
              class="mx-auto red text-none d-block"
              rounded
              depressed
              :loading="isSelecting"
              @click="onButtonClick"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              อัพโหลดรูปภาพ
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="handleImage"
            />
          </div>
          <div class="mt-1" v-if="imageBinary">
            <v-btn
              color="error"
              class="mx-auto text-none d-block"
              rounded
              depressed
              :loading="isSelecting2"
              @click="deleteImage({ type: 'tempimg' })"
            >
              ลบรูปภาพออก
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-5">
        <v-col>
          <p class="subtitle1">ข้อมูลห้องปฏิบัติการณ์</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <div class="body-1 graytext">
            ชื่อห้องปฏิบัติการณ์
            <div class="d-inline red--text">*</div>
          </div>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-text-field
            v-model="labData.lab_title"
            outlined
            dense
            :rules="rules.labNameRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">รายละเอียด</p>
        </v-col>
        <v-col cols="4" class="pa-0 pt-1">
          <v-textarea v-model="labData.lab_description" outlined />
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mb-5">
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">สี</p>
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-color-picker
          mode="hex"
          v-model="labData.lab_color"
          class=""
          show-swatches
        ></v-color-picker>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">สีตัวอักษร</p>
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-radio-group v-model="labData.lab_text_color">
          <v-radio label="ขาว" value="white"></v-radio>
          <v-radio label="ดำ" value="black"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <hr class="mb-5 mt-5" />
    <v-row justify="end" class="pr-5">
      <div class="mx-auto">
        <v-btn @click="saveToDb()" color="primary">+ สร้างห้อง</v-btn>
      </div>
    </v-row>
    <v-alert
      z-index="999"
      transition="scale-transition"
      v-model="alert.show"
      style="
        z-index: 999;
        width: 200px;
        position: fixed;
        bottom: 0px;
        right: 20px;
      "
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
  </v-container>
</template>

<script>
const apiConfig = require("../../../api.config");
const rules = require("../../../rules");
import axios from "axios";
export default {
  data() {
    return {
      imageBinary: null,
      selectedFile: null,
      isSelecting: false,
      isSelecting2: false,
      valid: true,
      rules: rules,
      alert: {
        show: false,
        type: "success",
        message: "",
      },
      labData: {
        lab_title: "",
        lab_description: "",
        lab_color: "",
        lab_text_color: "black",
      },
    };
  },
  async mounted() {},
  methods: {
    deleteImage({ type }) {
      this.isSelecting2 = true;
      if (type == "tempimg") {
        this.imageBinary = null;
      } else if (type == "dbimg") {
        console.log("ok");
      }
      this.isSelecting2 = false;
    },
    handleImage(e) {
      this.selectedFile = e.target.files[0];
      this.createBinaryImage(e.target.files[0]);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    createBinaryImage(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageBinary = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    alertmsg(type, msg) {
      this.alert.type = type;
      this.alert.message = msg;
      this.alert.show = true;
      setTimeout(() => {
        this.alert.show = false;
      }, 5000);
    },
    async uploadImage(file, lab_id) {
      let uploadResult = "uploadFail";
      try {
        let formData = new FormData();
        formData.append("file", file);
        uploadResult = (
          await axios.put(apiConfig.routes.lab.image + lab_id, formData, {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          })
        ).data;
      } catch (error) {
        console.log(error);
      }
      console.log(uploadResult);
      if (uploadResult.result.statusCode != 200) uploadResult = "uploadFail";
      return uploadResult;
    },
    async saveToDb() {
      if (this.$refs.form.validate()) {
        try {
          //update profile
          let dataObj = this.labData;
          for (const key of Object.keys(dataObj)) {
            if (!dataObj[key]) delete dataObj[key];
          }
          let attempt = (
            await axios.post(apiConfig.routes.lab.create, dataObj, {
              headers: { authorization: this.$store.state.user.token },
            })
          ).data;
          if (attempt.statusCode == 200) {
            let uploadResult;
            if (this.selectedFile) {
              uploadResult = await this.uploadImage(
                this.selectedFile,
                attempt.result.lab_id
              );
            }
            if (uploadResult) {
              if (uploadResult == "uploadFail") {
                this.alertmsg("error", "Fail to upload image");
              }
            }

            this.alertmsg("success", "Create complete");
            this.$refs.form.reset();
            this.isSelecting = false;
            this.selectedFile = null;
            this.imageBinary = null;
          } else {
            this.alertmsg(
              "error",
              attempt.message ||
                "Something went wront... plese try again later."
            );
          }
        } catch (error) {
          console.log(error);
          this.alertmsg(
            "error",
            "Something went wront... plese try again later."
          );
        }
      }
    },
  },
};
</script>

<style>
.graytext {
  color: gray;
}

.no-img-lab {
  margin: auto;
  width: 350px;
  height: 250px;
  line-height: 250px;
  border: 5px dashed lightgrey;
  border-radius: 5px;
  color: lightgrey;
}
</style>