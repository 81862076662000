<template>
  <v-container class="pt-0" style="min-height: 820px" fluid>
    <v-row
      class="pr-5 white--text pl-5 title"
      style="background-color: #296d98; height: 50px"
      align="center"
    >
      <v-btn @click="goback" class="mt-3" color="grey darken-2" dark>
        <v-icon dark left> mdi-arrow-left </v-icon>ย้อนกลับ
      </v-btn>
      <v-spacer />
      <v-btn class="mt-3" color="white" icon v-on:click="edit = !edit">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-col class="mt-5 col-12">
        <div>
          <div v-if="!imageBinary" class="text-center no-img-lab">รูปภาพ</div>
          <v-img
            v-if="imageBinary"
            class="mx-auto"
            :src="this.imageBinary"
            style="object-fit: fill"
            width="350px"
            height="250px"
          >
          </v-img>
          <div class="mt-3" v-if="edit == true">
            <v-btn
              color="primary"
              class="mx-auto red text-none d-block"
              rounded
              depressed
              :loading="isSelecting"
              @click="onButtonClick"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              อัพโหลดรูปภาพ
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="handleImage"
            />
          </div>
          <div class="mt-1" v-if="imageBinary && edit == true">
            <v-btn
              color="error"
              class="mx-auto text-none d-block"
              rounded
              depressed
              :loading="isSelecting2"
              @click="deleteImage({ type: 'tempimg' })"
            >
              ลบรูปภาพออก
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <p class="subtitle1">ข้อมูลห้องปฏิบัติการณ์</p>
      </v-col>
    </v-row>
    <hr class="mb-5" />
    <v-row>
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">ชื่อห้องปฏิบัติการณ์</p>
      </v-col>
      <v-col cols="4" v-if="edit == false">
        <p class="body-1 graytext">{{ labData.lab_title }}</p>
      </v-col>
      <v-col cols="4" class="pa-0 pt-1" v-if="edit == true">
        <v-text-field v-model="labData.lab_title" outlined dense />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">รายละเอียด</p>
      </v-col>
      <v-col cols="4" v-if="edit == false">
        <p class="body-1 graytext">{{ labData.lab_description }}</p>
      </v-col>
      <v-col cols="4" class="pa-0 pt-1" v-if="edit == true">
        <v-textarea
          v-model="labData.lab_description"
          outlined
          :rules="rules.descriptionRules"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">สี</p>
      </v-col>
      <v-col cols="4" v-if="edit == false">
        <p class="body-1 graytext">{{ labData.lab_color }}</p>
      </v-col>
      <v-col cols="4" class="pa-0 pt-1" v-else>
        <v-color-picker
          mode="hex"
          v-model="labData.lab_color"
          class=""
          show-swatches
        ></v-color-picker>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">สีตัวอักษร</p>
      </v-col>
      <v-col cols="4" v-if="edit == false">
        <p class="body-1 graytext">
          {{
            labData.lab_text_color == "white"
              ? "ขาว"
              : labData.lab_text_color == "black"
              ? "ดำ"
              : undefined
          }}
        </p>
      </v-col>
      <v-col cols="4" class="pa-0 pt-1" v-else>
        <v-radio-group v-model="labData.lab_text_color">
          <v-radio label="ขาว" value="white"></v-radio>
          <v-radio label="ดำ" value="black"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <hr class="mb-5 mt-5" />
    <v-row>
      <v-col cols="2" class="ml-10 justify-center">
        <p class="body-1 graytext">จำนวนสมาชิกในห้อง</p>
      </v-col>
      <v-col cols="4">
        <p class="body-1 graytext">
          {{ labData.users ? labData.users.length : 0 }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="subtitle1">สมาชิกภายในห้องปฏิบัติการ</p>
      </v-col>
    </v-row>
    <hr class="mb-5" />
    <v-row v-if="edit == true">
      <v-col cols="2" class="ml-10">
        <p class="body-1 graytext">สมาชิกที่ต้องการเพิ่ม</p>
      </v-col>
      <v-col cols="4" class="pa-0 pt-1">
        <v-autocomplete
          v-model="adduser.userToSaveList"
          :disabled="adduser.isUpdating"
          :items="adduser.userList"
          outlined
          chips
          color="blue-grey lighten-2"
          label="Select"
          item-text="name"
          item-value="user_id"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <v-avatar left>
                <v-img :src="data.item.avatar"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2" class="ml-10">
        <v-btn @click="addMember()" color="primary">เพิ่ม</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <div style="width: 100%" v-if="labData.users && labData.users.length > 0">
        <div
          v-for="(user, index) in labData.users"
          :key="index"
          style="margin-top: 10px"
          class="mx-auto col-6 d-inline-block"
        >
          <v-card elevation="2" class="pa-0 ma-0">
            <v-row class="pa-2 ma-0">
              <div>
                <v-img
                  v-if="user.file"
                  width="100"
                  height="120"
                  :src="
                    user.file
                      ? apiBase + user.file.url
                      : '../../assets/no-img.png'
                  "
                ></v-img
                ><v-img
                  v-if="!user.file"
                  width="100"
                  height="120"
                  src="../../assets/no-img.png"
                ></v-img>
              </div>
              <div>
                <v-card-title
                  >{{ user.name_prefix_th || "-" }}
                  {{ user.firstname_th || "-" }}
                  {{ user.lastname_th || "-" }}</v-card-title
                >
                <v-card-title
                  >{{ user.name_prefix_en || "-" }} {{ user.firstname_en }}
                  {{ user.lastname_en }}</v-card-title
                >
                <v-card-subtitle>{{ user.position || "-" }}</v-card-subtitle>
              </div>
              <v-btn
                v-if="edit == true"
                style="position: absolute; top: 5px; right: 5px"
                @click="deleteMember(user.user_id)"
                color="error"
                text
                >ลบ</v-btn
              >
            </v-row>
          </v-card>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center text-h6 grey--text"
        style="height: 20vh; width: 100%"
      >
        No Data
      </div>
      <hr class="mb-5" />
    </v-row>
    <hr class="mb-5 mt-5" />
    <v-row justify="center" class="mt-5 pr-5 mb-5" v-if="edit == true">
      <v-btn @click="updateToDb()" color="primary" class="mx-2">ตกลง</v-btn>
      <v-btn @click="cancle()" color="red" class="mx-2 white--text">
        ยกเลิก</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
const apiConfig = require("../../../api.config");
const rules = require("../../../rules");
import axios from "axios";

export default {
  props: {
    lab_id: Number,
  },
  data() {
    return {
      imageBinary: null,
      selectedFile: null,
      isSelecting: false,
      isSelecting2: false,
      edit: false,
      apiBase: apiConfig.routes.base,
      rules: rules,
      adduser: {
        autoUpdate: true,
        isUpdating: false,
        userToSaveList: [],
        userList: [],
        newUser: "",
      },
      labData: {
        lab_title: "",
        lab_description: "",
        lab_color: "",
        lab_text_color: "",
        lab_img_url: "",
        users: [],
      },
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    deleteImage({ type }) {
      this.isSelecting2 = true;
      if (type == "tempimg") {
        this.imageBinary = null;
      } else if (type == "dbimg") {
        console.log("ok");
      }
      this.isSelecting2 = false;
    },
    handleImage(e) {
      this.selectedFile = e.target.files[0];
      this.createBinaryImage(e.target.files[0]);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    createBinaryImage(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageBinary = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    async deleteMember(user_id) {
      await axios.put(
        apiConfig.routes.lab.deletemember + this.labData.lab_id,
        { user_ids: [user_id] },
        {
          headers: { authorization: this.$store.state.user.token },
        }
      );
      await this.fetchData();
    },
    async addMember() {
      await axios.put(
        apiConfig.routes.lab.addmember + this.labData.lab_id,
        { user_ids: this.adduser.userToSaveList },
        {
          headers: { authorization: this.$store.state.user.token },
        }
      );
      await this.fetchData();
    },
    cancle() {
      this.edit = !this.edit;
    },
    remove(item) {
      const index = this.adduser.userToSaveList.indexOf(item.user_id);
      if (index >= 0) this.adduser.userToSaveList.splice(index, 1);
    },
    goback() {
      this.$parent.targetLabId = null;
      this.$parent.viewing = false;
      this.$parent.fetchData();
    },
    async fetchData() {
      this.adduser.userToSaveList = [];
      let atp = (
        await axios.get(apiConfig.routes.lab.byid + this.lab_id, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;

      if (atp.statusCode === 200) {
        if (atp.result.file && atp.result.file.url) {
          atp.result.lab_img_url = this.apiBase + atp.result.file.url;
          delete atp.result.file;
        }
        this.labData = atp.result;
        this.imageBinary = this.labData.lab_img_url;
      }

      let atp2 = (
        await axios.get(apiConfig.routes.web.getAllUserFullName, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp2.statusCode === 200) {
        let user_list = [];
        this.labData.users.map((user) => {
          user_list.push(user.user_id);
        });
        this.adduser.userList = atp2.result.filter((user) => {
          return !user_list.includes(user.user_id);
        });
      }
    },
    async deleteImageFromDB(lab_id) {
      console.log("delIMG");
      let delResult = "deleteFail";
      try {
        delResult = (
          await axios.delete(apiConfig.routes.lab.image + lab_id, {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          })
        ).data;
      } catch (error) {
        console.log(error);
      }

      if (delResult.result.statusCode != 200) delResult = "uploadFail";
      return delResult;
    },
    async uploadImage(file, lab_id) {
      let uploadResult = "uploadFail";
      try {
        let formData = new FormData();
        formData.append("file", file);
        uploadResult = (
          await axios.put(apiConfig.routes.lab.image + lab_id, formData, {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          })
        ).data;
      } catch (error) {
        console.log(error);
      }
      console.log(uploadResult);
      if (uploadResult.result.statusCode != 200) uploadResult = "uploadFail";
      return uploadResult;
    },
    async updateToDb() {
      let lab = {};

      if (this.labData.lab_title) lab.lab_title = this.labData.lab_title;
      if (this.labData.lab_description)
        lab.lab_description = this.labData.lab_description;
      if (this.labData.lab_color) lab.lab_color = this.labData.lab_color;
      if (this.labData.lab_text_color)
        lab.lab_text_color = this.labData.lab_text_color;

      for (const key of Object.keys(lab)) {
        if (!lab[key]) delete lab[key];
      }

      let labResult = await axios.put(
        apiConfig.routes.lab.byid + this.labData.lab_id,
        lab,
        {
          headers: { authorization: this.$store.state.user.token },
        }
      );

      if (labResult.data.statusCode == 200) {
        if (
          this.imageBinary &&
          this.imageBinary != this.labData.lab_img_url &&
          this.selectedFile
        ) {
          await this.uploadImage(this.selectedFile, this.labData.lab_id);
        }
        if (!this.imageBinary && this.labData.lab_img_url) {
          await this.deleteImageFromDB(this.labData.lab_id);
        }
      }
      await this.fetchData();
      this.edit = false;
    },
  },
};
</script>

<style>
.graytext {
  color: gray;
}

.no-img {
  line-height: 150px;
  margin: auto;
  width: 200px;
  height: 150px;
  border: 5px dashed lightgrey;
  border-radius: 5px;
  color: lightgrey;
  min-width: 100px;
  max-width: 150px;
  min-height: 100px;
}
</style>
