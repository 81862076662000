<template>
  <v-container>
    <v-row class="ma-0 mb-3">
      <h1 class="text-h6 text-md-h4">PERSONAL BLOG</h1>
    </v-row>
    <v-row class="ma-0 pa-2 justify-start justify-sm-center" align="center">
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
        >
          Order By
        </span>
        <div class="px-3">
          <v-select
            v-model="orderBy"
            :items="orderByItems"
            v-on:change="getBlog"
            label="Select Order By"
            outlined
            hide-details=""
            dense
          >
          </v-select>
        </div>
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
        >
          Order Option
        </span>
        <div class="px-3">
          <v-select
            v-model="orderOption"
            :items="orderOptionItems"
            v-on:change="getBlog"
            label="Select Option"
            outlined
            hide-details=""
            dense
          >
          </v-select>
        </div>
      </div>
    </v-row>
    <div v-if="blogs.length > 0">
      <template v-for="(blog, index) in blogs">
        <BlogCard
          class="mb-5"
          :blog="blog"
          :key="blog.blog_id"
          :index="index"
        />
      </template>
      <v-container class="max-width">
        <v-pagination
          v-model="pageNumber"
          :length="pageLength"
          @input="fetchData"
        ></v-pagination>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import debounce from "lodash/debounce";
// highlight.js style
import "highlight.js/styles/monokai-sublime.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
const apiConfig = require("../../../api.config");
import BlogCard from "./card/BlogCard.vue";
export default {
  name: "Blog",
  components: {
    BlogCard,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      pageNumber: 1,
      size: 2,
      pageLength: 0,
      blogs: [],
      orderBy: "Title",
      orderByItems: ["Title", "Date"],
      orderOptionItems: ["ASC", "DESC"],
      orderOption: "DESC",
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async getBlog() {
      this.pageNumber = 1;
      await this.fetchData();
    },
    onEditorChange: debounce(function(value) {
      this.content = value.html;
    }, 466),
    onEditorBlur(editor) {
      console.log("editor blur!", editor);
    },
    onEditorFocus(editor) {
      console.log("editor focus!", editor);
    },
    onEditorReady(editor) {
      console.log("editor ready!", editor);
    },
    async fetchData() {
      let atp;
      let params = {
        page: this.pageNumber,
        size: this.size,
        orderby: this.orderBy,
        orderoption: this.orderOption,
      };
      console.log(this.user.user_id);
      atp = await axios.get(
        apiConfig.routes.blog.authorBlog + this.user.user_id,
        {
          params,
        }
      );
      if (atp.data.statusCode == 200) {
        this.blogs = atp.data.result.data;
        this.pageLength = atp.data.result.pageCount;
      }
      console.log(this.blogs);
    },
  },
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}
</style>
