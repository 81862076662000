<template>
  <v-container fluid class="text-left pa-0">
    <v-row class="ma-0">
      <h1 class="pl-0 text-h4">PUBLICATION</h1>
    </v-row>
    <v-row class="justify-start justify-sm-center" align="center">
      <div class="d-flex justify-start align-center my-0">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
        >
          Publication by type
        </span>
        <div class="px-1">
          <v-select
            v-model="selectType"
            :items="type"
            label="Select Type"
            v-on:change="reloadPublication"
            outlined
            hide-details=""
            dense
          ></v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-0">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Publication by year
        </span>
        <div class="px-1">
          <v-select
            v-model="selectYear"
            :items="years"
            outlined
            v-on:change="reloadPublication"
            hide-details=""
            dense
            label="Select Year"
          ></v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-0">
        <span
          class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Search by
        </span>
        <div class="px-1">
          <v-text-field
            placeholder="Publication Name"
            v-model="search_text"
            :items="searchText"
            hide-details=""
            dense
            clearable
            autofocus="true"
          />
        </div>
      </div>
      <v-btn
        class="ma-2 pa-2"
        outlined
        color="indigo"
        v-on:click="showfiltertag()"
      >
        Filter by Tag
      </v-btn>
      <v-row
        class="ma-0 pt-4 justify-start justify-sm-center"
        v-if="filtertags"
      >
        <v-autocomplete
          v-model="selectTag"
          :items="tags"
          outlined
          dense
          chips
          small-chips
          label="Select tag"
          multiple
          v-on:change="reloadPublication"
        ></v-autocomplete>
      </v-row>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-row align="center" v-else>
      <v-col v-if="!notFound">
        <v-col cols="11">
          <v-card class="mx-auto mt-5" width="100%">
            <v-list flat>
              <template v-for="(item, index) in items">
                <PublicationList
                  :data="item"
                  :key="item.title + index.toString()"
                  :index="index"
                  detailcomponent_name="user_pubdetail_in_profile_page"
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-row class="ma-0" justify="center" align="center">
          <v-pagination
            class="mt-5 mb-5"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            @input="fetchData"
          ></v-pagination>
        </v-row>
      </v-col>
      <v-col cols="11" v-else>
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          class="mx-auto mt-5"
        >
          Not Found Any Publication
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
const apiConfig = require("../../../api.config");
import Loading from "../common/Loading";
import PublicationList from "../PublicationList.vue";
var qs = require("qs");
export default {
  props: {
    user: Object,
  },
  components: {
    Loading,
    PublicationList,
  },
  data: () => {
    return {
      type: ["All", "Article", "Book", "Book section", "Conference item"],
      items: [],
      tags: [],
      pageCount: 0,
      page: 1,
      size: 7,
      search_text: null,
      selectType: "All",
      selectYear: "All",
      selectTag: null,
      searchText: null,
      loading: true,
      notFound: true,
      filtertags: false,
    };
  },
  async mounted() {
    this.fetchData();
    this.fetchTagName();
  },
  watch: {
    search_text() {
      if (this.search_text == "") {
        this.search_text = null;
      }
      this.reloadPublication();
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      let result = [];
      result = Array.from(
        { length: year - 1995 },
        (value, index) => 1996 + index
      );
      result.push("All");
      return result.reverse();
    },
  },
  methods: {
    async reloadPublication() {
      this.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      let url =
        apiConfig.routes.publication +
        "/author" +
        "/" +
        this.user.firstname_en +
        "," +
        this.user.lastname_en;
      let params = {
        page: this.page,
        size: this.size,
        search_text: this.search_text,
        type_filter: this.selectType,
        year_filter: this.selectYear,
      };
      if (this.selectTag != null) {
        let tag_filter = qs.stringify(
          { tag_filter: this.selectTag },
          { indices: false }
        );
        url += "?" + tag_filter;
      }
      if (params.search_text == "") {
        params.search_text = null;
      }
      if (params.type_filter === "All" || params.type_filter == null) {
        params.type_filter = null;
      }
      if (params.type_filter === "Article" || params.type_filter === "Book") {
        params.type_filter = params.type_filter.toLowerCase();
      }
      if (params.type_filter === "Book section") {
        params.type_filter = "book_section";
      }
      if (params.type_filter === "Conference item") {
        params.type_filter = "conference_item";
      }
      if (params.year_filter === "All") {
        params.year_filter = null;
      }
      let atp = await axios.get(url, { params });
      // console.log(atp);
      if (atp.status == 200) {
        this.items = atp.data.result.data;
        this.page = atp.data.result.currentPage;
        this.pageCount = atp.data.result.pageCount;
        if (atp.data.result.data.length == 0) {
          this.notFound = true;
          this.loading = false;
        } else {
          this.notFound = false;
          this.loading = false;
        }
      }
    },
    showfiltertag() {
      if (this.filtertags === true) {
        this.filtertags = false;
      } else {
        this.filtertags = true;
      }
    },
    async fetchTagName() {
      this.tags = [];
      let url = apiConfig.routes.user.get.skillAll;
      await axios.get(url).then((item) => {
        this.tags = item.data.result;
      });
    },
  },
};
</script>

<style scoped>
.loadingHeight {
  height: 60vh;
}
</style>
