<template>
  <div>
    <v-sheet class="pa-0" style="position: relative; font-size: 20px">
      <div class="">
        <v-container class="overflow-y-auto pa-0 pb-3" fluid>
          <Header title="Publication" />
          <v-row
            class="pl-6 text-body-2 ma-0 flex-column flex-md-row"
            style="background-color: #f2f2f2"
            align="center"
          >
            <v-col cols="12" md="1" class="pa-0">Filter by Type</v-col>
            <v-col cols="12" md="2">
              <v-select
                flat
                solo
                v-model="selectType"
                :items="type"
                hide-details=""
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="pa-0">Filter by Year</v-col>
            <v-col cols="12" md="2">
              <v-select
                flat
                solo
                v-model="selectYear"
                :items="years"
                hide-details=""
                dense
              ></v-select>
            </v-col>
            <v-spacer />
            <v-col>
              <v-text-field
                solo
                hide-details=""
                dense
                flat
                v-model="selectName"
                :items="searchText"
                label="Search"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>
          <Loading v-if="pageLoading" class="loadingHeight" />
          <div v-else-if="!pageLoading && pageCount > 0">
            <template v-for="(item, index) in items">
              <PublicationList
                :data="item"
                :key="item.title + index.toString()"
                :index="index"
                detailcomponent_name="admin_pubdetail_in_account_page"
              />
            </template>
            <v-row class="ma-0" justify="center" align="center">
              <v-pagination
                class="mt-5 mb-5"
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                @input="fetchData"
              ></v-pagination>
            </v-row>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center text-h6 grey--text"
            style="height:40vh"
          >
            No Data
          </div>
        </v-container>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
const apiConfig = require("../../../../../api.config");
import Loading from "../../../common/Loading";
import Header from "../../layout/Header.vue";
import PublicationList from "../../../PublicationList.vue";
export default {
  components: {
    Loading,
    Header,
    PublicationList,
  },
  data: () => ({
    selectType: "All",
    selectYear: "All",
    selectName: "",
    type: ["All", "Article", "Book", "Book section", "Conference item"],
    items: [],
    pageLoading: true,
    pageCount: 0,
    page: 1,
    size: 6,
    searchText: null,
    user: null,
  }),
  async created() {
    await this.fetchUser();
    this.fetchData();
  },
  watch: {
    selectName() {
      this.page = 1;
      this.fetchData();
    },
    selectYear() {
      this.page = 1;
      this.fetchData();
    },
    selectType() {
      this.page = 1;
      this.fetchData();
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      let result = [];
      result = Array.from(
        { length: year - 1995 },
        (value, index) => 1996 + index
      );
      result.push("All");
      return result.reverse();
    },
  },
  methods: {
    async fetchUser() {
      let urlFetch =
        apiConfig.routes.profile +
        (this.$route.query.uid === undefined
          ? this.$store.state.user.user_id
          : this.$route.query.uid);
      console.log("urlFetch", urlFetch);
      this.skillKey += 1;
      let atp = (
        await axios.get(urlFetch, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp.statusCode == 200) {
        this.user = atp.result;
      }
    },
    async fetchData() {
      console.log(this.$store);
      let url =
        apiConfig.routes.publication +
        "/author" +
        "/" +
        this.user.firstname_en +
        "," +
        this.user.lastname_en;
      let params = {
        page: this.page,
        size: this.size,
        type_filter: null,
        year_filter: "",
        search_text: this.searchText,
      };
      // select Type
      if (this.selectType == "All") {
        params.type_filter = null;
      } else if (this.selectType == "Article") {
        params.type_filter = "article";
      } else if (this.selectType == "Book") {
        params.type_filter = "book";
      } else if (this.selectType == "Book section") {
        params.type_filter = "book_section";
      } else if (this.selectType == "Conference item") {
        params.type_filter = "conference_item";
      } else {
        params.type_filter = this.selectType;
      }
      // search Year
      if (this.selectYear != null) {
        if (this.selectYear == "All") {
          params.year_filter = null;
        } else {
          params.year_filter = this.selectYear;
        }
      }
      // search Text
      if (this.selectName === "") delete params["search_text"];
      else {
        params.search_text = this.selectName;
      }
      try {
        let atp = await axios.get(url, { params });
        if (atp.status == 200) {
          this.items = atp.data.result.data;
          this.page = atp.data.result.currentPage;
          this.pageCount = atp.data.result.pageCount;
          this.pageLoading = false;
        }
        console.log(this.items);
      } catch (e) {
        this.pageLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.loadingHeight {
  height: 80vh;
}
</style>
