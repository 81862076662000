<template>
  <v-menu
    :ref="menuRef"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dateVal"
    transition="scale-transition"
    offset-y
    min-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateVal"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        dense
        v-bind="attrs"
        v-on="on"
        outlined
        hide-details=""
        :rules="rule"
      ></v-text-field>
    </template>
    <v-date-picker
      :ref="refPicker"
      v-model="dateVal"
      no-title
      scrollable
      :max="maxDate || ''"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="saveHandler">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    refPicker: String,
    date: String,
    label: String,
    menuRef: String,
    rule: Array,
    maxDate: String
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateVal: {
      get() {
        return this.date.toString();
      },
      set(value) {
        this.onChangeHandler(value, "date");
      },
    },
  },
  methods: {
    onMenuChangeHandler(value) {
      this.$emit("onMenuChange", value);
    },
    onChangeHandler(value, emit) {
      this.$emit("onChange", { value, from: emit });
    },
    saveHandler() {
      this.$refs[this.menuRef].save(this.dateVal);
    },
  },
};
</script>

<style></style>
