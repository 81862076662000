<template>
  <Layout class="pubdetail">
    <PageBar msg="PUBLICATION DETAIL" />
    <Loading class="loadingHeight" v-if="loading === true" />
    <div v-if="loading === false">
      <v-row justify="center" class="pt-10 ma-0 pb-15">
        <v-col cols="12" sm="6" class="pa-0">
          <v-row justify="start" class="pb-5 ma-0">
            <v-col class="text-h4"> Detail </v-col>
            <v-btn icon v-on:click="iconEditTag()">
              <div v-if="edit == true">
                <v-icon color="blue darken-4">mdi-square-edit-outline</v-icon>
              </div>
              <div v-if="edit != true">
                <v-icon color="black">mdi-square-edit-outline</v-icon>
              </div>
            </v-btn>
          </v-row>
          <!-- Abstract Card -->
          <v-row justify="center" class="pb-5 ma-0" v-if="!notAbstract">
            <v-card class="pa-2 ma-0">
              <v-card-title>
                <span class="text-h4 font-weight-light">Abstract</span>
              </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div class="my-4 text-subtitle-1">
                    {{ items.abstract }}
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          <!-- Table Detail -->
          <v-simple-table class="pb-5">
            <thead>
              <tr>
                <th class="text-left">Key</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>title</td>
                <td>{{ items.title }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{{ items.l_type }}</td>
              </tr>
              <tr>
                <td>Date Type</td>
                <td>{{ items.date_type }}</td>
              </tr>
              <tr>
                <td>Publisher</td>
                <td>{{ items.publisher }}</td>
              </tr>
              <tr>
                <td>Date/Month/Year</td>
                <td>{{ items.date + "/" + items.month + "/" + items.year }}</td>
              </tr>
              <tr>
                <td>Publication</td>
                <td>{{ items.publication }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ items.l_status }}</td>
              </tr>
              <tr>
                <td>Refereed</td>
                <td>{{ items.refereed }}</td>
              </tr>
              <tr>
                <td>Scopus Id</td>
                <td>{{ items.scopus_eid }}</td>
              </tr>
              <tr>
                <td>Identification Number (DOI)</td>
                <td>{{ items.identifier }}</td>
              </tr>
              <tr>
                <td>Date Deposited</td>
                <td>{{ items.deposited_time }}</td>
              </tr>
              <tr v-for="item in items.divisions" :key="item.id">
                <td>Divisions</td>
                <td>
                  {{ item.name }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Contributors</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="pb-4">
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">First name</th>
                    <th class="text-left">Last name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(con, index) in contributors" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ con.first_name }}</td>
                    <td>{{ con.last_name }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Keywords</v-col>
          </v-row>
          <!-- show tags-->
          <v-row class="pb-0 ma-0" v-show="edit != true">
            <v-col class="overflow-auto">
              <v-chip-group mandatory active-class="white--text">
                <v-chip
                  class="blue darken-3 white--text"
                  v-for="(tag, index) in tagData.tags"
                  :key="index"
                >
                  <span>
                    <v-icon color="white">mdi-tag</v-icon>
                    {{ tag }}
                  </span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <!-- edit tags -->
          <div v-if="edit == true">
            <v-row v-for="(tag, index) in tagData.tags" :key="index">
              <v-col class="overflow-x-auto justify-start">
                <span class="pa-2 mx-2 blue darken-3 white--text rounded-pill">
                  <v-icon color="white">mdi-tag</v-icon>
                  {{ tag }}
                </span>
                <v-btn
                  fab
                  small
                  color="red lighten-1"
                  v-on:click="removeTag(tag)"
                >
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-col class="mt-4">
              <v-btn color="primary" small v-on:click="getTagrec()">
                Get Tag Recommemdation
              </v-btn>
            </v-col> -->
            <!-- addTag -->
            <v-form ref="form2" v-model="valid" lazy-validation>
              <v-row class="px-4 mt-2">
                <v-col cols="6">
                  <v-combobox
                    v-model="tagData.newtags"
                    :items="
                      items2.filter(
                        (e) => !tagData.tags.map((s) => s.tagName).includes(e)
                      )
                    "
                    :rules="rules.skillNameRules"
                    class="rounded-xl"
                    label="TAG NAME"
                    placeholder="TAG NAME"
                    solo
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col cols="4" class="pa-0 pt-2">
                  <v-btn
                    small
                    class="mx-2 mt-2"
                    fab
                    dark
                    color="indigo"
                    v-on:click="addTag()"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row justify="start" class="px-8 mt-2" v-if="edit == true">
              <v-btn class="mr-5" color="primary" v-on:click="saveToDB()">
                Save
              </v-btn>
              <v-btn color="red" class="white--text" v-on:click="iconEditTag()">
                Cancel
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layout/Layout.vue";
import PageBar from "../../components/PageBar";
import axios from "axios";
import api from "../../../api.config";
import Loading from "../../components/common/Loading";
import rules from "../../../rules";
// const item_article_detail_publication = require("../../../scholar_api_backup/item_article_detail_publication.json");
export default {
  components: { Layout, PageBar, Loading },
  data: () => ({
    rules,
    valid: true,
    loading: true,
    notAbstract: false,
    icons: false,
    edit: false,
    tagLoading: false,
    items: [],
    items2: [],
    contributors: [],
    tagName: [],
    tagData: {
      tags: [],
      recommended_tags: [],
      newtags: "",
    },
    skills: [],
    tag_reccom: [],
  }),
  async mounted() {
    this.fetchData();
    this.fetchSkill();
  },
  methods: {
    async fetchData() {
      this.skills = [];
      this.tagData.tags = [];
      this.contributors = [];
      await axios
        .get(
          api.routes.publication + "/" + this.$route.params.publication_id
        )
        .then((res) => {
          this.items = res.data.result;
          this.skills = res.data.result.skills;
          this.contributors = res.data.result.contributors;
          for (const key in this.skills) {
            this.tagData.tags.push(this.skills[key].skill_name);
          }
          if (res.data.result.abstract == "-") {
            this.notAbstract = true;
          }
          this.loading = false;
        });
    },
    fetchSkill() {
      let _this = this;
      let url = api.routes.user.get.skillAll;
      axios.get(url).then((item) => {
        _this.items2 = item.data.result;
      });
    },
    // เปิด/ปิดการแสดง editTag
    iconEditTag() {
      if (this.edit === true) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    iconStar() {
      if (this.icons === true) {
        this.icons = false;
      } else {
        this.icons = true;
      }
    },
    async removeTag(tag) {
      let newlist = this.tagData.tags.filter((item) => {
        return item != tag;
      });
      this.tagData.tags = newlist;
    },
    async getTagrec() {
      let url =
        api.routes.tagrecomment + "/" + this.$route.params.publication_id;
      const headers = {
        authorization: this.$store.state.user.token,
      };
      this.tag_reccom = [];
      await axios.get(url, { headers }).then((res) => {
        if (res.status == 200) {
          this.tag_reccom = res.data.result.recommended_tags;
          for (const key in this.tag_reccom) {
            this.tagData.tags.push(this.tag_reccom[key].skill_name);
          }
          this.tag_reccom = [];
          console.log(res.data);
          console.log(res.data.result.recommended_tags);
        }
      });
    },
    addTag() {
      if (this.$refs.form2.validate() && !!this.tagData.newtags) {
        this.tagData.tags.push(this.tagData.newtags);
      }
      this.tagData.newtags = "";
      this.$refs.form2.resetValidation();
    },
    async saveToDB() {
      //update tags
      let urlTags =
        api.routes.publication +
        "/tag/" +
        this.$route.params.publication_id;
      // const id = { publication_id: this.$route.params.publication_id };
      const headers = {
        authorization: this.$store.state.user.token,
      };
      let tags = { tags: this.tagData.tags };
      let atp = (await axios.put(urlTags, tags, { headers })).data;
      if (atp.statusCode == 200) {
        this.edit = false;
        await this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>  
