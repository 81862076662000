<template>
  <v-row
    class="pr-5 white--text pl-5 title ma-0"
    style="background-color: #296d98; height:50px; line-height: 50px;"
    align="center"
  >
    {{ title }}
    <v-spacer />
    <v-btn
      v-if="create"
      color="green darken-1"
      class="white--text"
      @click="createHandler"
    >
      <v-icon left>
        mdi-plus-circle-outline
      </v-icon>
      Create
    </v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    create: Boolean,
  },
  methods: {
    createHandler() {
      this.$emit("createHandler");
    },
  },
};
</script>

<style></style>
