<template>
  <v-row justify="end">
    <v-dialog v-model="dialogVal" max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline">Create Researcher</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="name_prefix_th"
                    label="Prefix TH*"
                    :rules="rules.nameTHRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="firstname_th"
                    label="FirstName TH*"
                    :rules="rules.nameTHRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="lastname_th"
                    label="Last Name TH*"
                    :rules="rules.nameTHRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="name_prefix_en"
                    label="Prefix EN*"
                    required
                    :rules="rules.nameENRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="firstname_en"
                    label="FirstName EN*"
                    :rules="rules.nameENRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="lastname_en"
                    label="Last Name EN*"
                    :rules="rules.nameENRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="student_id"
                    label="Student ID*"
                    :rules="rules.codeStrictRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="email"
                    label="Email*"
                    :rules="rules.emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    label="Username*"
                    :rules="rules.usernameRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    label="Password*"
                    type="password"
                    :rules="rules.passwordRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <Loading class="loadingHeight" style="height: 10%" v-if="loading" />
          </v-card-text>
          <v-card-actions v-if="!loading">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()"> Close </v-btn>
            <v-btn color="blue darken-1" text @click="create()"> Create </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="success" persistent max-width="290">
      <v-card>
        <v-card-title>
          <v-row justify="center">
            <v-icon size="100px" color="green">mdi-check-circle-outline</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text> Create new Researcher Successfully! </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="success = false">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert
      z-index="999"
      transition="scale-transition"
      v-model="alert.show"
      style="
        z-index: 999;
        width: 200px;
        position: fixed;
        bottom: 0px;
        right: 20px;
      "
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
  </v-row>
</template>

<script>
import api from "../../../api.config";
import axios from "axios";
import rules from "../../../rules";
import Loading from "../common/Loading.vue";
export default {
  components: {
    Loading,
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    dialogVal: {
      get() {
        return this.dialog;
      },
      set() {
        this.close();
      },
    },
  },
  data: () => ({
    alert: {
      show: false,
      type: "success",
      message: "",
    },
    loading: false,
    valid: true,
    rules,
    student_id: null,
    username: null,
    password: null,
    name_prefix_th: null,
    name_prefix_en: null,
    firstname_th: null,
    firstname_en: null,
    lastname_th: null,
    lastname_en: null,
    email: null,
    success: false,
  }),
  methods: {
    alertmsg(type, msg) {
      this.alert.type = type;
      this.alert.message = msg;
      this.alert.show = true;
      setTimeout(() => {
        this.alert.show = false;
      }, 5000);
    },
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = api.routes.user.create.createResearcher;
        let headers = {
          authorization: this.$store.state.user.token,
        };
        axios
          .post(
            url,
            {
              student_id: this.student_id,
              username: this.username,
              password: this.password,
              name_prefix_th: this.name_prefix_th,
              name_prefix_en: this.name_prefix_en,
              firstname_th: this.firstname_th,
              firstname_en: this.firstname_en,
              lastname_th: this.lastname_th,
              lastname_en: this.lastname_en,
              email: this.email,
            },
            { headers: headers }
          )
          .then((item) => {
            if (item.data.statusCode === 200) {
              this.loading = false;
              this.success = true;
              this.close();
              this.$emit("Create");
            } else {
              this.loading = false;
              this.alertmsg("error", item.data.message);
            }
          });
      }
    },
    close() {
      this.$emit("Close");
    },
  },
};
</script>

<style></style>
