var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"warp",attrs:{"href":_vm.newUrl + _vm.data.publication_id,"target":"_blank"}},[_c('v-list-item',{staticClass:"align-start"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex text-wrap",staticStyle:{"color":"#296d98"},domProps:{"textContent":_vm._s(_vm.data.title)}}),_c('v-list-item-subtitle',[_c('p',{staticClass:"ma-0 mt-1"},[_vm._v(_vm._s(_vm.data.l_type))]),_c('p',{staticClass:"ma-0 mt-1"},[_vm._v(_vm._s(isNaN(_vm.data.year) ? "-" : _vm.data.year))])])],1),_c('div',{staticClass:"d-sm-flex justify-end pt-2"},[_c('span',{staticClass:"ma-0 mr-3 pl-2 pt-1 d-flex align-center",class:{
            'green--text': _vm.data.is_published === 'pub',
            'gray--text': _vm.data.is_published === 'unpub',
            'orange--text': _vm.data.is_published === 'inpress',
          }},[_c('div',{staticClass:"mr-1",class:{
              green: _vm.data.is_published === 'pub',
              gray: _vm.data.is_published === 'unpub',
              orange: _vm.data.is_published === 'inpress',
            },staticStyle:{"height":"10px","width":"10px","border-radius":"50%"}}),_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.data.is_published == "inpress" ? _vm.capitalize(_vm.data.is_published) : _vm.capitalize(_vm.data.is_published) + "lish")+" ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }