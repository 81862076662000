<template>
  <v-container class="pt-0" fluid style="min-height: 60vh">
    <v-row
      class="px-5 py-2 white--text title"
      style="background-color: #296d98;"
      align="center"
    >
      {{ selectComponent === "master" ? "Master" : "Phd" }}
      <v-spacer />
      <v-btn
        color="green darken-1"
        class="white--text"
        v-on:click="view('create')"
      >
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        Create
      </v-btn>
    </v-row>
    <v-row
      class="pl-5 body-2"
      style="background-color: #f2f2f2;"
      align="center"
    >
      Order By
      <v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          :items="orderByItems"
          v-model="orderBy"
          dense
          class="mt-0"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          :items="sortByItems"
          v-model="sortBy"
          dense
          class="mt-0"
        ></v-select>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="2">
        <v-text-field
          solo
          flat
          label="Search"
          append-icon="mdi-magnify"
          hide-details=""
          v-model="search"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-row
      v-else-if="!loading && project && project.length > 0"
      v-for="item in project"
      :key="item.research_id"
    >
      <ProjectCard
        :title="item.project_topic"
        :tag="item.tags"
        :abstract="item.project_abstract"
        @View="view('detail', item.research_id)"
        @Remove="showDialog(item)"
        showViewBtn
        showDeleteBtn
      />
    </v-row>
    <div
      v-else
      class="d-flex justify-center align-center mt-16 text-h5 grey--text"
    >
      No Data
    </div>
    <v-row class="ma-0" justify="center" align="center">
      <v-pagination
        class="ma-0 mt-5"
        v-model="page"
        :total-visible="7"
        :length="pageLength"
      ></v-pagination>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialog" v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">
              Are you sure?
            </v-card-title>
            <v-card-text>{{ removeThis.project_topic }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="removeMaster(false)">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="removeMaster(true)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ProjectCard from "../../card/ProjectCard";
import api from "../../../../../api.config";
import axios from "axios";
import Loading from "../../../common/Loading";
export default {
  components: {
    ProjectCard,
    Loading,
  },
  data() {
    return {
      selectComponent: "",
      projectType: null,
      project: null,
      dialog: false,
      removeThis: null,
      loading: true,
      page: 1,
      pageLength: null,
      orderByItems: ["Title", "Create_date"],
      orderBy: "Title",
      sortByItems: ["ASC", "DESC"],
      sortBy: "ASC",
      search: "",
    };
  },
  methods: {
    showDialog: function(item) {
      this.removeThis = item;
      this.dialog = true;
    },
    removeMaster(bol) {
      if (bol) {
        let url = api.routes.user.get.researcher + this.removeThis.research_id;
        let headers = {
          authorization: this.$store.state.user.token,
        };
        this.project = this.project.filter((item) => {
          return item !== this.removeThis;
        });
        axios.delete(url, { headers: headers });
      }
      this.dialog = false;
      this.removeThis = null;
    },
    cancle: function(value, item) {
      item.edit = !item.edit;
      item.title = value.title;
      item.tag = value.tag;
      item.abstract = value.abstract;
    },
    view: function(to, uid) {
      let goTo;
      if (to === "create") {
        goTo = this.selectComponent === "master" ? "createmaster" : "createphd";
      } else {
        goTo = this.selectComponent === "master" ? "masterdetail" : "phddetail";
      }
      this.$router.push({
        name: "account",
        params: { component: goTo },
        query: { id: uid },
      });
    },
    changePage() {
      this.selectComponent = this.$route.params.component;
      this.projectType =
        this.$route.params.component === "master" ? "MASTER" : "PHD";
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let uid = this.$store.state.user.user_id;
      let url = api.routes.user.get.researcherAll + uid;
      let params = {
        page: this.page,
        size: 5,
        orderby: this.orderBy,
        orderoption: this.sortBy,
        type: this.$route.params.component === "master" ? "MASTER" : "PHD",
        search_text: this.search,
      };
      if (this.search === "") delete params["search_text"];
      axios.get(url, { params }).then((res) => {
        let data = res.data.result.items;
        console.log(data)
        this.project = data;
        this.pageLength = res.data.result.pageCount;
        if (this.pageLength < this.page) this.page = this.pageLength;
        if (this.pageLength < 1) this.page = 1;
      });
      this.loading = false;
    },
  },
  created() {
    this.changePage();
  },
  watch: {
    "$route.params.component": function() {
      this.changePage();
    },
    orderBy() {
      this.fetchData();
    },
    sortBy() {
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
  },
};
</script>

<style>
.loadingHeight {
  height: 80vh;
}
</style>
