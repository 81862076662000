<template>
  <Layout class="blogdetail">
    <PageBar msg="Blog Detail" />

    <v-row justify="center" class="pt-10 ma-0 pb-15">
      <v-col cols="12" sm="6" class="pa-2">
        <v-col class="d-flex flex-row-reverse">
          <v-btn icon v-on:click="editStatus()" class="mb-0">
            <div v-if="edit == true">
              <v-icon color="blue darken-4">mdi-square-edit-outline</v-icon>
            </div>
            <div v-if="edit != true">
              <v-icon color="black">mdi-square-edit-outline</v-icon>
            </div>
          </v-btn>
        </v-col>
        <div v-if="!edit">
          <v-col>
            <v-row class="text-h4">
              {{ titleBlog }}
            </v-row>
            <v-row>
              <v-card-subtitle class="text-caption pa-1">
                Written By: {{ blog.author.fullname_en }} ({{
                  blog.author.email
                }})
              </v-card-subtitle>
            </v-row>
            <v-row>
              <v-card-subtitle class="text-caption pa-2">
                Latest Updated: {{ updatedAgo }}
              </v-card-subtitle>
            </v-row>
          </v-col>
          <hr class="ma-2" />
          <div class="output ql-snow pa-5">
            <div class="ql-editor" v-html="content"></div>
          </div>
        </div>
        <div v-if="edit">
          <div class="mb-3">
            <div v-if="!imageBinary" class="text-center no-img-lab">
              Cover Picture *
            </div>
            <v-img
              v-if="imageBinary"
              class="mx-auto"
              :src="this.imageBinary"
              style="object-fit: fill"
              width="820px"
              height="312px"
            >
            </v-img>
            <div class="mt-3">
              <v-btn
                color="primary"
                class="mx-auto red text-none d-block"
                rounded
                depressed
                :loading="isSelecting"
                @click="onButtonClick"
              >
                <v-icon left> mdi-cloud-upload </v-icon>
                Upload image
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                @change="handleImage"
              />
            </div>

            <div class="mt-1" v-if="imageBinary">
              <v-btn
                color="error"
                class="mx-auto text-none d-block"
                rounded
                depressed
                :loading="isSelecting2"
                @click="deleteImage({ type: 'tempimg' })"
              >
                ลบรูปภาพออก
              </v-btn>
            </div>
          </div>
          <v-text-field
            v-model="titleBlog"
            hide-details=""
            outlined
            dense
            label="Blog Title *"
            class="mb-5"
          ></v-text-field>
          <quill-editor
            class="editor"
            ref="myTextEditor"
            v-model="content"
            :options="editorOption"
            @change="onEditorChange"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
          <v-btn text class="blue--text" @click="saveHandler()">Save</v-btn>
          <v-btn text class="blue--text" @click="cancleHandler()">Cancel</v-btn>
        </div>
      </v-col>
      <v-alert
        z-index="999"
        transition="scale-transition"
        v-model="alert.show"
        style="
        z-index: 999;
        width: 200px;
        position: fixed;
        bottom: 0px;
        right: 20px;
      "
        :type="alert.type"
      >
        {{ alert.message }}
      </v-alert>
    </v-row>
  </Layout>
</template>

<script>
import dedent from "dedent";
import hljs from "highlight.js";
import debounce from "lodash/debounce";
import axios from "axios";
const apiConfig = require("../../../api.config");
// highlight.js style
import "highlight.js/styles/monokai-sublime.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Layout from "../layout/Layout.vue";
import PageBar from "../PageBar.vue";
import { quillEditor } from "vue-quill-editor";

export default {
  components: { Layout, PageBar, quillEditor },
  data() {
    return {
      titleBlog: "",
      blog: null,
      edit: false,
      content: "",
      updatedAgo: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text, ["js"]).value,
          },
        },
      },
      imageBinary: null,
      isSelecting: false,
      selectedFile: null,
      isSelecting2: false,
      alert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  async created() {
    await this.fetchdata();
  },
  // async updated() {
  //   await this.fetchdata();
  // },
  methods: {
    async fetchdata() {
      try {
        let atp = await axios.get(
          apiConfig.routes.blog.eachBlog + this.$route.params.id
        );
        this.blog = atp.data.result;
        this.updatedAgo = atp.data.updated_timeago;
        this.titleBlog = this.blog.title;
        this.content = dedent`${this.blog.content}`;
        if (this.blog.coverImage.url) {
          this.imageBinary = apiConfig.routes.base + this.blog.coverImage.url;
        }
        console.log(atp);
      } catch (error) {
        console.log(error);
      }
    },
    editStatus() {
      this.edit = !this.edit;
    },
    cancleHandler() {
      this.edit = false;
    },
    async uploadImage(file, blog_id) {
      let uploadResult = "uploadFail";
      try {
        let formData = new FormData();
        formData.append("file", file);
        uploadResult = (
          await axios.put(apiConfig.routes.blog.imageBlog + blog_id, formData, {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          })
        ).data;
      } catch (error) {
        console.log(error);
      }
      console.log(uploadResult);
      if (uploadResult.result.statusCode != 200) uploadResult = "uploadFail";
      return uploadResult;
    },
    async saveHandler() {
      try {
        let dataObj = { title: this.titleBlog, content: dedent(this.content) };
        let attempt = (
          await axios.put(
            apiConfig.routes.blog.eachBlog + this.blog.blog_id,
            dataObj,
            {
              headers: { authorization: this.$store.state.user.token },
            }
          )
        ).data;
        if (attempt.statusCode == 200) {
          let deleteResult;
          let uploadResult;
          if (this.selectedFile) {
            deleteResult = await axios.delete(
              apiConfig.routes.blog.imageBlog + this.blog.blog_id,
              {
                headers: { authorization: this.$store.state.user.token },
              }
            );
            if (deleteResult.data.statusCode == 200) {
              uploadResult = await this.uploadImage(
                this.selectedFile,
                attempt.result.blog_id
              );
              if (uploadResult) {
                if (uploadResult == "uploadFail") {
                  this.alertmsg("error", "Fail to upload image");
                }
              }
            }
          }
          this.alertmsg("success", "Edit complete");
          this.edit = false;
          this.isSelecting = false;
          this.selectedFile = null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onEditorChange: debounce(function(value) {
      this.content = value.html;
    }, 466),
    onEditorBlur(editor) {
      console.log("editor blur!", editor);
    },
    onEditorFocus(editor) {
      console.log("editor focus!", editor);
    },
    onEditorReady(editor) {
      console.log("editor ready!", editor);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    handleImage(e) {
      this.selectedFile = e.target.files[0];
      this.createBinaryImage(e.target.files[0]);
    },
    createBinaryImage(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageBinary = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    deleteImage({ type }) {
      this.isSelecting2 = true;
      if (type == "tempimg") {
        this.imageBinary = null;
      } else if (type == "dbimg") {
        console.log("ok");
      }
      this.isSelecting2 = false;
    },
    alertmsg(type, msg) {
      this.alert.type = type;
      this.alert.message = msg;
      this.alert.show = true;
      setTimeout(() => {
        this.alert.show = false;
      }, 1000);
    },
  },
};
</script>

<style>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>
