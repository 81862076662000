import { render, staticRenderFns } from "./pubicationDetail.vue?vue&type=template&id=3304048c&"
import script from "./pubicationDetail.vue?vue&type=script&lang=js&"
export * from "./pubicationDetail.vue?vue&type=script&lang=js&"
import style0 from "./pubicationDetail.vue?vue&type=style&index=0&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCheckbox,VChip,VCombobox,VContainer,VForm,VIcon,VListItem,VListItemContent,VListItemTitle,VRow,VSelect,VTextField,VTextarea})
