<template>
  <v-container fluid class="pa-0">
    <v-alert
      style="position: fixed; z-index: 20; bottom: 0; right: 10px"
      dense
      type="success"
      v-model="succesalert"
      dismissible
      transition="scale-transition"
    >
      Update Successfully!!
    </v-alert>
    <div
      class="text-left white--text pl-5 title"
      style="background-color: #296d98; height: 50px; line-height: 50px"
    >
      Training
    </div>
    <v-row
      class="ma-0"
      align="center"
      justify="end"
      style="background-color: #f2f2f2"
    >
      <v-col cols="12" md="4">
        <v-text-field
          hide-details=""
          dense
          outlined
          append-icon="mdi-magnify"
          label="Title"
          v-model="search"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-container v-else class="a-5 mb-0" style="background-color: #ffffff">
      <div v-if="items.length > 0">
        <v-row v-for="item in itemFilter" :key="item.training_id">
          <TrainingCard
            :title="item.training_title"
            :start="moment(item.training_start_date)"
            :end="moment(item.training_end_date)"
            :showEditBtn="true"
            :showDeleteBtn="true"
            :edit="item.edit"
            @Edit="item.edit = !item.edit"
            @onChange="item[$event.from] = $event.value"
            @Cancle="cancle($event, item)"
            @Remove="showDialog(item)"
            @Save="save($event, item)"
          />
        </v-row>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center grey--text text-h6"
        style="min-height: 30vh"
      >
        No Data
      </div>
      <v-row justify="center" class="my-3">
        <v-btn icon color="primary" v-on:click="create()"
          ><v-icon size="40">mdi-plus</v-icon></v-btn
        >
      </v-row>
    </v-container>

    <template>
      <v-row justify="center">
        <v-dialog v-if="dialog" v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline"> Are you sure? </v-card-title>
            <v-card-text>{{ removeThis.training_title }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="removeItem(false)">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="removeItem(true)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import TrainingCard from "../../card/TrainingCard";
import axios from "axios";
import moment from "moment";
import api from "../../../../../api.config";
import Loading from "../../../common/Loading";
export default {
  components: {
    TrainingCard,
    Loading,
  },
  data: () => ({
    items: [],
    succesalert: false,
    loading: true,
    dialog: false,
    removeThis: null,
    search: "",
  }),
  computed: {
    itemFilter() {
      return this.items.filter((item) => {
        return item.training_title
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    showDialog: function (item) {
      this.removeThis = item;
      this.dialog = true;
    },
    save: function (event, item) {
      this.loading = true;
      let _this = this;
      var headers = {
        authorization: this.$store.state.user.token,
      };
      if (event.title != "" && event.year != "") {
        let url = api.routes.user.update.training + item.training_id;
        item.edit = !item.edit;

        axios
          .put(
            url,
            {
              training_title: item.training_title,
              training_start_date: item.training_start_date,
              training_end_date: item.training_end_date,
            },
            { headers: headers }
          )
          .then(() => {
            _this.succesalert = true;
          });
      } else {
        if (
          item.training_title != "" &&
          item.training_start_date != "" &&
          item.training_end_date != ""
        ) {
          let url =
            api.routes.user.create.training + this.$store.state.user.user_id;
          axios
            .post(
              url,
              {
                training_title: item.training_title,
                training_start_date: item.training_start_date,
                training_end_date: item.training_end_date,
              },
              { headers: headers }
            )
            .then((val) => {
              let result = val.data.result.training;
              // test
              var filtered = this.items.filter(function (value) {
                return value != item;
              });
              this.items = filtered;
              // test
              this.items.push({ ...result, edit: false });
            });
        }
      }
      this.loading = false;
    },
    cancle: function (value, item) {
      if (item.training_id) {
        item.edit = !item.edit;
        item.training_title = value.title;
        item.training_start_date = value.start;
        item.training_end_date = value.end;
      } else {
        var filtered = this.items.filter(function (value) {
          return value != item;
        });
        this.items = filtered;
      }
      // this.loading = false;
    },
    removeItem(temp) {
      if (temp) {
        let removeTemp = this.removeThis;
        let url = api.routes.user.delete.training + removeTemp.training_id;
        var headers = {
          authorization: this.$store.state.user.token,
        };
        axios.delete(url, { headers: headers });
        var filtered = this.items.filter(function (value) {
          return value != removeTemp;
        });
        this.items = filtered;
      }
      this.dialog = false;
      this.removeThis = null;
    },
    fetchData: function () {
      this.loading = true;
      var _this = this;
      var url =
        api.routes.user.get.trainingAll + this.$store.state.user.user_id;
      var headers = {
        authorization: this.$store.state.user.token,
      };
      axios.get(url, { headers: headers }).then((item) => {
        let result = item.data.result.training.map((item) => {
          item = { ...item, edit: false };
          return item;
        });
        _this.items = result;
      });
      this.loading = false;
    },
    create: function () {
      this.items.push({
        edit: true,
        training_title: "",
        training_start_date: new Date(),
        training_end_date: new Date(),
      });
    },
    moment(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.titleText {
  color: #296d98;
}
.loadingHeight {
  height: 80vh;
}
</style>
