<template>
  <v-container
    fluid
    class="pt-15"
    style="background-color: #f2f2f2; height: 100vh"
  >
    <v-row justify="center">
      <v-img
        alt="IT Logo"
        class="shrink"
        contain
        src="https://www.it.kmitl.ac.th/wp-content/themes/itkmitl2017wp/img/nav-thai.svg"
        transition="scale-transition"
        width="200"
      />
    </v-row>
    <v-row justify="center">
      <v-card class="mt-5 pa-5" width="300px">
        <v-chip
          v-if="login_info.fail"
          class="ma-2"
          close
          color="red"
          text-color="red"
          outlined
          label
          @click:close="login_info.fail = false"
        >
          Incorrect username or password.
        </v-chip>
        <v-container class="">
          <v-form @keyup.enter.native="login">
            <v-row>
              <p>Username</p>
            </v-row>
            <v-row>
              <v-text-field
                v-model="login_info.username"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row>
              <p>Password</p>
            </v-row>
            <v-row>
              <v-text-field
                type="password"
                hide-details
                v-model="login_info.password"
                @focus="login_info.password = ''"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row>
              <v-checkbox
                dense
                label="Ldap Authentication"
                hide-details
                v-model="login_info.ldap_check"
              ></v-checkbox>
            </v-row>
            <v-row class="mt-8">
              <v-progress-circular
              class="mx-auto"
                indeterminate
                color="primary"
                v-if="login_info.loading"
              ></v-progress-circular>
              <v-btn v-else block color="primary" @click="login()">Login</v-btn>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
// import apiConfig from "../../api.config";
// import axios from "axios";
export default {
  data() {
    return {
      login_info: {
        username: "",
        password: "",
        ldap_check: true,
        fail: false,
        loading: false,
      },
    };
  },
  methods: {
    test() {
      alert("click");
    },
    async login() {
      this.login_info.fail = false;
      this.login_info.loading = true;
      let ldapCheck = this.login_info.ldap_check;
      let user = {
        username: this.login_info.username,
        password: this.login_info.password,
      };
      if (user.username !== "" && user.password !== "") {
        await this.$store.dispatch("LogIn", { user, ldapCheck }).then((res) => {
          if (res === "Success") {
            this.$router.go(-1);
          } else if (res === "Error") {
            this.login_info.fail = true;
            this.login_info.password = "";
          }
          this.login_info.loading = false;
        });
      } else {
        this.login_info.fail = true;
      }
    },
  },
};
</script>

<style></style>
