<template>
  <Layout class="pubdetail">
    <PageBar msg="PUBLICATION DETAIL" />
    <Loading class="loadingHeight" v-if="loading === true" />
    <div v-if="loading === false">
      <v-row justify="center" class="pt-10 ma-0 pb-15">
        <v-col cols="12" sm="6" class="pa-0">
          <v-row justify="start" class="pb-2 ma-0">
            <v-col class="text-h4"> Detail </v-col>
          </v-row>
          <!-- Abstract Card -->
          <v-row justify="center" class="pb-5 ma-0" v-if="!notAbstract">
            <v-card class="pa-2 ma-0">
              <v-card-title>
                <span class="text-h4 font-weight-light">Abstract</span>
              </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div class="my-4 text-subtitle-1">
                    {{ items.abstract }}
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          <v-simple-table class="pb-4">
            <thead>
              <tr>
                <th class="text-left">Key</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Title</td>
                <td>{{ items.title }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{{ items.l_type }}</td>
              </tr>
              <tr>
                <td>Date Type</td>
                <td>{{ items.date_type }}</td>
              </tr>
              <tr>
                <td>Publisher</td>
                <td>{{ items.publisher }}</td>
              </tr>
              <tr>
                <td>Date/Month/Year</td>
                <td>{{ items.date + "/" + items.month + "/" + items.year }}</td>
              </tr>
              <tr>
                <td>Publication</td>
                <td>{{ items.publication }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ items.l_status }}</td>
              </tr>
              <tr>
                <td>Refereed</td>
                <td>{{ items.refereed }}</td>
              </tr>
              <tr>
                <td>Scopus Id</td>
                <td>{{ items.scopus_eid }}</td>
              </tr>
              <tr>
                <td>Identification Number (DOI)</td>
                <td>{{ items.identifier }}</td>
              </tr>
              <tr>
                <td>Date Deposited</td>
                <td>{{ items.deposited_time }}</td>
              </tr>
              <tr v-for="item in items.divisions" :key="item.id">
                <td>Divisions</td>
                <td>
                  {{ item.name }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Contributors</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="pb-4">
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">First name</th>
                    <th class="text-left">Last name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(con, index) in contributors" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ con.first_name }}</td>
                    <td>{{ con.last_name }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Keywords</v-col>
          </v-row>
          <!-- show tags-->
          <v-row class="pb-0 ma-0" v-show="edit != true">
            <v-col class="overflow-auto">
              <v-chip-group mandatory active-class="white--text">
                <v-chip
                  class="blue darken-3 white--text"
                  v-for="(tag, index) in tagData.tags"
                  :key="index"
                >
                  <span>
                    <v-icon color="white">mdi-tag</v-icon>
                    {{ tag }}
                  </span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import Layout from "./layout/Layout.vue";
import PageBar from "../components/PageBar";
import axios from "axios";
import api from "../../api.config";
import Loading from "../components/common/Loading";
import rules from "../../rules";
export default {
  components: { Layout, PageBar, Loading },
  async mounted() {
    this.fetchData();
  },
  data: () => ({
    rules,
    loading: true,
    notAbstract: false,
    items: [],
    contributors: [],
    skills: [],
    tagData: {
      tags: [],
    },
  }),
  methods: {
    async fetchData() {
      this.skills = [];
      this.tagData.tags = [];
      this.contributors = [];
      await axios
        .get(
          api.routes.publication + "/" + this.$route.params.publication_id
        )
        .then((res) => {
          this.items = res.data.result;
          this.skills = res.data.result.skills;
          this.contributors = res.data.result.contributors;
          for (const key in this.skills) {
            this.tagData.tags.push(this.skills[key].skill_name);
          }
          if (res.data.result.abstract == "-") {
            this.notAbstract = true;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>
