<template>
  <v-container fluid class="ma-0 pa-0 pb-5" style="min-height: 30vh">
    <Header title="Blog" create @createHandler="create()" />
    <v-row
      class="pl-5 text-body-2 ma-0 flex-column flex-md-row"
      style="background-color: #f2f2f2"
      align="center"
    >
    </v-row>
    <div class="pa-5" v-if="creating">
      <BlogCard @Cancle="cancle()" :creating="creating" @Create="fetchData()" />
    </div>
    <div class="pa-5" v-else>
      <template v-for="(blog, index) in blogs">
        <BlogCard
          @Cancle="cancle()"
          @Create="fetchData()"
          :creating="creating"
          :blog="blog"
          :key="blog.blog_id"
          :index="index"
        />
      </template>
      <v-container class="max-width">
        <v-pagination
          v-model="pageNumber"
          :length="pageLength"
          @input="fetchData"
        ></v-pagination>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Header from "../../layout/Header";
import BlogCard from "../../card/BlogCard.vue";
import axios from "axios";
const apiConfig = require("../../../../../api.config");

export default {
  components: { Header, BlogCard },
  data() {
    return {
      creating: false,
      blogs: [],
      loading: true,
      user: null,
      pageNumber: 1,
      size: 4,
      pageLength: 0,
    };
  },
  async created() {
    await this.fetchUser();
    this.fetchData();
  },
  methods: {
    async fetchUser() {
      let urlFetch =
        apiConfig.routes.profile +
        (this.$route.query.uid === undefined
          ? this.$store.state.user.user_id
          : this.$route.query.uid);
      let atp = (
        await axios.get(urlFetch, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp.statusCode == 200) {
        this.user = atp.result;
      }
    },
    async fetchData() {
      this.creating = false,
      this.loading = true;
      let atp;
      let params = {
        page: this.pageNumber,
        size: this.size,
        orderby: "Title",
        orderoption: "DESC",
      };
      atp = await axios.get(
        apiConfig.routes.blog.authorBlog + this.user.user_id,
        {
          params,
        }
      );
      if (atp.data.statusCode == 200) {
        this.blogs = atp.data.result.data;
        this.pageLength = atp.data.result.pageCount;
      }
      console.log(this.blogs);
    },
    create() {
      this.creating = true;
    },
    cancle() {
      this.creating = false;
    },
  },
};
</script>

<style>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>
