<template>
  <v-container fluid class="pa-0">
    <v-alert
      style="position:fixed; z-index:20; bottom: 0; right: 10px;"
      dense
      type="success"
      v-model="succesalert"
      dismissible
      transition="scale-transition"
    >
      Update Successfully!!
    </v-alert>
    <div
      class="text-left white--text pl-5 title"
      style="
            background-color: #296d98;
            height: 50px;
            line-height: 50px;
          "
    >
      Education
    </div>
    <Loading class="loadingHeight" v-if="loading" />
    <v-container v-else class="a-5 mb-0" style="background-color: #ffffff">
      <div>
        <div
          class="titleText text-no-wrap font-weight-medium text-left text-h5 mb-2"
        >
          Bachelor Degree
        </div>
        <div v-if="bachelor.length > 0">
          <v-row v-for="item in bachelor" :key="item.education_id" class="ma-0">
            <EducationCard
              :major="item.education_major"
              :university="item.education_univesity_name"
              :graduate="item.graduation_year"
              :showEditBtn="true"
              :showDeleteBtn="true"
              :edit="item.edit"
              @Edit="item.edit = !item.edit"
              @onChange="item[$event.from] = $event.value"
              @Cancle="cancle()"
              @Remove="showDialog(item)"
              @Save="save($event, item)"
            />
          </v-row>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center text-h6 grey--text"
          style="min-height:80px"
        >
          No Data
        </div>
        <v-row justify="center" class="ma-0 my-2">
          <v-btn icon color="primary" v-on:click="create('bachelor')"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
      </div>
      <div>
        <div
          class="titleText text-no-wrap font-weight-medium text-left text-h5 mb-2"
          style="textTitle"
        >
          Master Degree
        </div>
        <div v-if="master.length > 0">
          <v-row v-for="item in master" :key="item.education_id" class="ma-0">
            <EducationCard
              :major="item.education_major"
              :university="item.education_univesity_name"
              :graduate="item.graduation_year"
              :showEditBtn="true"
              :showDeleteBtn="true"
              :edit="item.edit"
              @Edit="item.edit = !item.edit"
              @onChange="item[$event.from] = $event.value"
              @Cancle="cancle()"
              @Remove="showDialog(item)"
              @Save="save($event, item)"
            />
          </v-row>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center text-h6 grey--text"
          style="min-height:80px"
        >
          No Data
        </div>
        <v-row justify="center" class="ma-0 my-2">
          <v-btn icon color="primary" v-on:click="create('master')"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
      </div>
      <div>
        <div
          class="titleText text-no-wrap font-weight-medium text-left text-h5 mb-2"
          style="textTitle"
        >
          Doctoral Degree
        </div>
        <div v-if="doctoral.length > 0">
          <v-row v-for="item in doctoral" :key="item.education_id" class="ma-0">
            <EducationCard
              :major="item.education_major"
              :university="item.education_univesity_name"
              :graduate="item.graduation_year"
              :showEditBtn="true"
              :showDeleteBtn="true"
              :edit="item.edit"
              @Edit="item.edit = !item.edit"
              @onChange="item[$event.from] = $event.value"
              @Cancle="cancle()"
              @Remove="showDialog(item)"
              @Save="save($event, item)"
            />
          </v-row>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center text-h6 grey--text"
          style="min-height:80px"
        >
          No Data
        </div>
        <v-row justify="center" class="ma-0 my-2">
          <v-btn icon color="primary" v-on:click="create('doctoral')"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
      </div>
    </v-container>
    <template>
      <v-row justify="center" class="ma-0">
        <v-dialog v-if="dialog" v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">
              Are you sure?
            </v-card-title>
            <v-card-text>{{ removeThis.education_univesity_name }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="removeItem(false)">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="removeItem(true)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
  <!-- http://universities.hipolabs.com/search?name=King%20m -->
</template>

<script>
import EducationCard from "../../card/EducationCard";
import axios from "axios";
import api from "../../../../../api.config";
import Loading from "../../../common/Loading";
export default {
  components: {
    EducationCard,
    Loading,
  },
  data: () => ({
    items: null,
    succesalert: false,
    removeThis: null,
    dialog: false,
    loading: true,
    bachelor: [],
    master: [],
    doctoral: [],
  }),
  methods: {
    removeItem(temp) {
      if (temp) {
        let item = this.removeThis;
        let url = api.routes.user.delete.education + item.education_id;
        var filtered;
        var headers = {
          authorization: this.$store.state.user.token,
        };
        axios.delete(url, { headers: headers });
        if (item.education_level === "bachelor") {
          filtered = this.bachelor.filter(function(value) {
            return value != item;
          });
          this.bachelor = filtered;
        } else if (item.education_level === "master") {
          filtered = this.master.filter(function(value) {
            return value != item;
          });
          this.master = filtered;
        } else {
          filtered = this.doctoral.filter(function(value) {
            return value != item;
          });
          this.doctoral = filtered;
        }
      }
      this.dialog = false;
      this.removeThis = null;
    },
    save: function(event, item) {
      this.loading = true;
      let _this = this;
      var headers = {
        authorization: this.$store.state.user.token,
      };
      if (event.major != "" && event.graduate != "" && event.university != "") {
        let url =
          api.routes.user.update.education +
          item.education_id +
          "/" +
          this.$store.state.user.user_id;
        item.edit = !item.edit;

        axios
          .put(
            url,
            {
              education_univesity_name: item.education_univesity_name,
              education_major: item.education_major,
              education_level: item.education_level,
              graduation_year: item.graduation_year,
            },
            { headers: headers }
          )
          .then(() => {
            _this.succesalert = true;
          });
      } else {
        if (
          item.education_univesity_name != "" &&
          item.education_major != "" &&
          item.graduation_year != ""
        ) {
          axios
            .post(
              api.routes.user.create.education +
                _this.$store.state.user.user_id,
              {
                education_univesity_name: item.education_univesity_name,
                education_major: item.education_major,
                education_level: item.education_level,
                graduation_year: item.graduation_year,
              },
              { headers: headers }
            )
            .then(() => {
              this.fetchData();
            });
        }
      }
      this.loading = false;
    },
    cancle: function() {
      this.fetchData();
    },
    showDialog: function(item) {
      this.removeThis = item;
      this.dialog = true;
    },
    fetchData: function() {
      this.bachelor = [];
      this.master = [];
      this.doctoral = [];
      var _this = this;
      this.loading = true;
      var url =
        api.routes.user.get.educationAll + this.$store.state.user.user_id;
      var headers = {
        authorization: this.$store.state.user.token,
      };
      axios.get(url, { headers: headers }).then((item) => {
        let result = item.data.result.education.map((item) => {
          item = { ...item, edit: false };
          if (item.education_level === "bachelor") this.bachelor.push(item);
          else if (item.education_level === "master") this.master.push(item);
          else this.doctoral.push(item);
          return item;
        });
        _this.items = result;
      });
      this.loading = false;
    },
    create: function(val) {
      if (val === "bachelor") {
        this.bachelor.push({
          edit: true,
          education_major: "",
          education_univesity_name: "",
          graduation_year: "",
          education_level: val,
        });
      } else if (val === "master") {
        this.master.push({
          edit: true,
          education_major: "",
          education_univesity_name: "",
          graduation_year: "",
          education_level: val,
        });
      } else {
        this.doctoral.push({
          edit: true,
          education_major: "",
          education_univesity_name: "",
          graduation_year: "",
          education_level: val,
        });
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.titleText {
  color: #296d98;
}
.loadingHeight {
  height: 80vh;
}
</style>
