<template>
  <v-app-bar app color="#F2F2F2" dark height="55px" elevate-on-scroll>
    <div class="d-flex align-center">
      <router-link
        style="text-decoration: none"
        to="/"
        @click.native="$scrollToTop"
      >
        <v-img
          alt="IT Logo"
          class="shrink mr-2"
          contain
          src="https://www.it.kmitl.ac.th/wp-content/themes/itkmitl2017wp/img/nav-thai.svg"
          transition="scale-transition"
          width="200"
        />
      </router-link>
    </div>

    <v-spacer />
    <div class="align-center d-none d-lg-flex">
      <router-link
        style="text-decoration: none"
        to="/"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>Home</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/people/teacher"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>Lecturer</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/people/researcher"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>RESEARCHER</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/lab"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>Laboratory</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/blog"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>BLOG</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/publication"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>PUBLICATION</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/project"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>PROJECT</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        to="/account"
        @click.native="$scrollToTop"
        v-if="$store.state.authenticated"
      >
        <v-btn class="black--text" text>
          <span>Account</span>
        </v-btn>
      </router-link>
      <router-link
        v-if="$store.state.authenticated && $store.state.user.is_admin"
        style="text-decoration: none"
        to="/admin"
        @click.native="$scrollToTop"
      >
        <v-btn class="black--text" text>
          <span>ADMIN</span>
        </v-btn>
      </router-link>
      <!-- <v-btn v-show="search.show" v-on:click="test()" icon color="black">
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <v-btn
        v-if="$store.state.authenticated"
        @click="logout"
        class="black--text"
        text
      >
        <span>LOGOUT</span>
      </v-btn>
      <!-- signin-->
      <router-link
        style="text-decoration: none"
        to="/login"
        @click.native="$scrollToTop"
        v-if="!$store.state.authenticated"
      >
        <v-btn class="black--text" text>
          <span>Login</span>
        </v-btn>
      </router-link>
      <!--signin-->
      <div class="d-flex align-center pt-5">
        <v-text-field
          v-show="!search.show"
          label="Prepend"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          v-on:focusout="test()"
          clearable
          color="black"
          hide-details
          autofocus
        ></v-text-field>
      </div>
    </div>
    <div class="d-flex d-lg-none black--text">
      <v-btn icon @click="handleClickMenu">
        <v-icon color="black">mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="showMenu" fullscreen hide-overlay>
      <v-card>
        <v-toolbar elevation="0" class="px-5">
          Menu
          <v-spacer />
          <v-btn icon @click="handleClickMenu">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="d-flex flex-column align-center">
          <router-link
            style="text-decoration: none"
            to="/"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>Home</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/people/teacher"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>Lecturer</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/people/researcher"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>RESEARCHER</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/lab"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>Laboratory</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/blog"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>BLOG</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/publication"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>PUBLICATION</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/project"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>PROJECT</span>
            </v-btn>
          </router-link>
          <router-link
            style="text-decoration: none"
            to="/account"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
            v-if="$store.state.authenticated"
          >
            <v-btn class="black--text" text>
              <span>Account</span>
            </v-btn>
          </router-link>
          <router-link
            v-if="$store.state.authenticated && $store.state.user.is_admin"
            style="text-decoration: none"
            to="/admin"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
          >
            <v-btn class="black--text" text>
              <span>ADMIN</span>
            </v-btn>
          </router-link>
          <v-btn
            v-if="$store.state.authenticated"
            @click="logout"
            class="black--text"
            text
          >
            <span>LOGOUT</span>
          </v-btn>
          <!-- signin-->
          <router-link
            style="text-decoration: none"
            to="/login"
            @click.native="
              $scrollToTop();
              handleClickMenu();
            "
            class="my-2"
            v-if="!$store.state.authenticated"
          >
            <v-btn class="black--text" text>
              <span>Login</span>
            </v-btn>
          </router-link>
        </div>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      errorbox: {
        show: false,
        message: "",
      },
      login_info: {
        username: "",
        password: "",
        ldap_check: 0,
        fail: false,
        loading: false,
      },
      search: {
        show: true,
        data: "",
      },
      showMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("LogOut");
      if (this.$route.name !== "Home") {
        this.$router.replace({ name: "Home" });
      }
    },
    test() {
      this.search.show = !this.search.show;
    },
    handleClickMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style>
.nav {
  overflow-x: hidden;
}
</style>
