<template>
  <v-container fluid class="text-left pa-0">
    <v-row class="ma-0">
      <h1 class="pl-0 text-h4">Funded Project</h1>
    </v-row>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="5">
        <v-text-field
          hide-details=""
          outlined
          dense
          label="Search"
          v-model="searchValue"
        ></v-text-field>
        <div class="d-flex align-center mt-2">
          <span class="mr-2 text-body-2">Filter By</span>
          <v-select
            hide-details=""
            outlined
            dense
            v-model="filterValue"
            :items="filterItems"
          ></v-select>
        </div>
        <div class="d-flex flex-row align-center">
          <span class="text-body-2 mr-1">Order By</span>
          <v-select
            class="mt-2"
            :items="orderByItems"
            :item-text="(v) => v.name"
            :item-value="(v) => v.value"
            v-model="orderByValue"
            dense
            label=""
            outlined
            hide-details=""
          />
        </div>
        <div class="d-flex align-center">
          <span class="text-body-2 mr-1">Sort By</span>
          <v-select
            class="mt-2"
            :items="sortByItems"
            :item-text="(v) => v.name"
            :item-value="(v) => v.value"
            v-model="sortByValue"
            dense
            label=""
            outlined
            hide-details=""
          />
        </div>
      </v-col>
    </v-row>
    <Loading v-if="loading" class="loadingHeight" />
    <div v-else>
    <v-row class="ma-0" v-if="data === null || data.length < 1">
        <v-col cols="12" md="" >
        <span>No Data</span>
        </v-col>
    </v-row>
    <v-row class="ma-0 mb-2 pb-2" v-else>
      <v-col cols="12" md="8" v-for="item in data" :key="item.research2_id">
        <ResearchCard 
            :data="item"
          />
      </v-col>
    </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from "../common/Loading.vue";
import api from "../../../api.config";
import axios from "axios";
import ResearchCard from "../account/card/ResearchCard.vue";
export default {
  components: {
    Loading,
    ResearchCard,
  },
  data() {
    return {
      searchValue: "",
      filterItems: ["ALL", "FINISHED", "CANCLED", "ONGOING"],
      filterValue: "ALL",
      orderByItems: [
        { name: "Title", value: "Title" },
        { name: "Start Date", value: "Start_date" },
      ],
      orderByValue: "Title",
      sortByItems: [
        { name: "A - Z", value: "ASC" },
        { name: "Z - A", value: "DESC" },
      ],
      sortByValue: "ASC",
      loading: true,
      data: null,
      page: 1,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let url = api.routes.user.get.researchProject + this.$route.params.userId;
      let params = {
        page: this.page,
        size: 8,
        orderby: this.orderByValue,
        orderoption: this.sortByValue,
      };
      if (this.searchValue !== "") params.search_text = this.searchValue;
      if (this.filterValue !== "ALL") params.filter = this.filterValue;
      let dataFromAPI = (await axios.get(url, { params })).data;
      if (dataFromAPI.statusCode === 200) {
        this.data = dataFromAPI.result.researchs;
      } else {
        alert("Call api error status code", dataFromAPI.statusCode);
      }
      console.log(dataFromAPI);
      this.loading = false;
    },
  },
};
</script>

<style scope>
.loadingHeight {
  height: 60vh;
}
</style>
