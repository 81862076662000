<template>
  <v-container class="pt-0" style="min-height: 55vh" fluid>
    <div class="text-left">
      <v-row class="ma-0 mt-15">
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Title</p>
        </v-col>
        <v-col cols="4">
          <p class="body-1 graytext">{{ lab.lab_title }}</p>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Description</p>
        </v-col>
        <v-col cols="4">
          <p class="body-1 graytext">{{ lab.lab_description }}</p>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="2" class="ml-10 justify-center">
          <p class="body-1 graytext">Number of members</p>
        </v-col>
        <v-col cols="4">
          <p class="body-1 graytext">
            {{ lab.users ? lab.users.length : 0 }}
          </p>
        </v-col>
      </v-row>
    </div>

    <v-row class="ma-0">
      <v-col class="ma-0">
        <p class="text-h5">Members</p>
      </v-col>
    </v-row>
    <hr class="mb-5" />
    <v-row
      v-if="lab.users.length > 0"
      justify="center"
    >
      <v-col
        v-for="(user, index) in lab.users"
        :key="index"
        class="mt-3 d-flex justify-center"
        cols="12"
        md="6"
        lg="4"
      >
        <PeopleCard
          :name="user.firstname_en + ' ' + user.lastname_en"
          :photo="user.file !== null ? apiBase + user.file.url : null"
          :uid="user.user_id"
          :expertise="user.skills"
        />
      </v-col>
      <hr class="mb-5" />
    </v-row>

    <div
      v-if="
        lab.users == null || lab.users == undefined || lab.users.length == 0
      "
    >
      <p class="mx-auto body-2 grey--text">No Data</p>
    </div>
  </v-container>
</template>

<script>
const apiConfig = require("../../api.config");
const rules = require("../../rules");
//import axios from "axios";
import PeopleCard from "./PeopleCard";

export default {
  components: {
    PeopleCard,
  },
  props: ["lab"],
  data() {
    return {
      edit: false,
      apiBase: apiConfig.routes.base,
      rules: rules,
      adduser: {
        autoUpdate: true,
        isUpdating: false,
        userToSaveList: [],
        userList: [],
        newUser: "",
      },
    };
  },
};
</script>

<style>
.graytext {
  color: gray;
}
</style>
