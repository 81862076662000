<template>
  <div style="width: 100%">
    <ConfirmDialog
      :show="showDialog"
      title="คำเตือน"
      message="ยืนยันการลบข้อมูล"
      agree="ยืนยัน"
      disagree="ยกเลิก"
      @confirm="confirm"
      @cancle="cancle"
    />
    <v-tabs>
      <v-tab @click="changeTab('ALL')">ทั้งหมด</v-tab>
      <v-tab @click="changeTab('LECTURER')">อาจารย์</v-tab>
      <v-tab @click="changeTab('RES_STUDENT')">นักศึกษา วิจัย</v-tab>
      <v-tab @click="changeTab('STAFF')">เจ้าหน้าที่</v-tab>
      <v-tab @click="changeTab('CREATE')"> + สร้างผู้ใช้</v-tab>
      <v-btn text @click="importuser" class="grey--text my-auto">
        + IMPORT FROM .XLSX FILE
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="handlefile"
      />
      <v-btn text class="grey--text my-auto" @click="syncSciVal()">
        Sync SciVal
      </v-btn>
      <div class="search-box">
        <v-text-field
          dense
          v-model="searchText"
          label="Search"
          outlined
          clearable
        ></v-text-field>
      </div>
    </v-tabs>
    <Loading class="loadingHeight" v-if="loading" />
    <div v-else>
      <div v-if="tab != 'CREATE' && !viewing">
        <v-row class="pa-0 ma-0" align="center" justify="center">
          <v-spacer />
          Sort by
          <div class="mr-2 ml-2 mt-3" style="width: 100px; height: 50px">
            <v-select solo dense v-model="order1" :items="items1"></v-select>
          </div>
          <div class="mr-2 ml-2 mt-3" style="width: 100px; height: 50px">
            <v-select solo dense v-model="order2" :items="items2"></v-select>
          </div>
          <v-spacer />
        </v-row>
        <v-row class="mx-auto" style="min-height: 820px">
          <v-card
            v-for="user in userList"
            :key="user.user_id"
            :loading="false"
            class="ml-5 mt-5"
            width="23%"
            height="50%"
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <div style="width: 100%; background-color: #add8e6">
              <v-img
                v-if="user.file && user.file.url"
                class="mx-auto"
                height="200"
                width="200"
                :src="api_base + user.file.url"
                alt="../../../assets/no-img.png"
              ></v-img>
              <v-icon
                v-if="!user.file"
                class="mx-auto"
                size="200"
                style="width: 100%"
              >
                mdi-account-outline
              </v-icon>
            </div>
            <div class="pa-2">
              <div class="subtitle-1 text-truncate">ชื่อภาษาไทย:</div>
              <v-card-title>
                <span class="d-inline-block text-truncate subtitle-1">
                  {{ user.name_prefix_th || "-" }} {{ user.firstname_th }}
                  {{ user.lastname_th }}
                </span></v-card-title
              >
              <div class="subtitle-1">ชื่อภาษาอังกฤษ:</div>
              <v-card-title>
                <span class="d-inline-block text-truncate subtitle-1">
                  {{ user.name_prefix_en || "-" }}
                  <br />
                  {{ user.firstname_en || "-" }}
                  {{ user.lastname_en || "-" }}
                </span></v-card-title
              >
              <div class="subtitle-1">Position:</div>
              <v-card-title>
                <span class="d-inline-block text-truncate subtitle-1">
                  {{ user.position || "-" }}
                </span></v-card-title
              >
            </div>

            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
              <div class="mx-auto">
                <v-btn color="#1C86F2" text @click="viewUser(user.user_id)">
                  ดูรายละเอียด
                </v-btn>
                <v-btn @click="openDeleteDialog(user.user_id)" color="red" text>
                  ลบข้อมูล
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <div class="text-center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-container class="max-width">
                      <v-pagination
                        v-model="pageNumber"
                        :length="pageLength"
                        @input="fetchUserList(user_type)"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="tab == 'CREATE'" style="min-height: 820px"><CreateUser /></div>
    </div>
  </div>
</template>

<script>
import CreateUser from "../CreateUser";
import ConfirmDialog from "../../common/ConfirmDialog.vue";
import axios from "axios";
const apiConfig = require("../../../../api.config");
import Loading from "../../common/Loading";
const XLSX = require("xlsx");

export default {
  components: {
    ConfirmDialog,
    CreateUser,
    Loading,
  },
  watch: {
    searchText: async function () {
      this.fetchUserList(this.user_type);
    },
    async order1() {
      await this.fetchUserList(this.user_type);
    },
    async order2() {
      await this.fetchUserList(this.user_type);
    },
  },
  data: () => ({
    target_user_id: null,
    showDialog: false,
    order1: "Name",
    order2: "ASC",
    items1: ["Name", "Position"],
    items2: ["DESC", "ASC"],
    loading: true,
    api_base: apiConfig.routes.base,
    pageNumber: 1,
    pageLength: 0,
    searchText: "",
    user_type: "ALL",
    target: {
      wantEdit: false,
      viewing: false,
      user_id: "",
    },
    wantEdit: false,
    tab: "ALL",
    userList: [],
    viewing: false,
  }),
  async mounted() {
    await this.fetchUserList("ALL");
  },
  methods: {
    changeTab(tab) {
      this.viewing = false;
      this.tab = tab;
      if (["ALL", "LECTURER", "RES_STUDENT", "STAFF"].includes(tab)) {
        this.pageNumber = 1;
        this.fetchUserList(tab);
      }
    },
    openDeleteDialog(user_id) {
      this.target_user_id = user_id;
      this.showDialog = !this.showDialog;
    },
    confirm() {
      this.deleteUser(this.target_user_id);
      this.showDialog = !this.showDialog;
    },
    cancle() {
      this.target_user_id = null;
      this.showDialog = !this.showDialog;
    },
    async handlefile(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      let saveToDb = this.saveToDb;
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheet = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1);
        saveToDb(sheet);
      };
      reader.readAsArrayBuffer(file);
    },
    async saveToDb(data) {
      this.loading = true;
      let attempt = await axios.post(
        apiConfig.routes.admin.createuserbyxlsx,
        { users: data },
        {
          headers: { authorization: this.$store.state.user.token },
        }
      );
      if (attempt.data.statusCode == 200)
        await this.fetchUserList(this.user_type);
      this.loading = false;
    },
    importuser() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    viewUser(user_id) {
      this.$store.commit("adminStartView", user_id);
      this.$router.push({ name: "account", params: { component: "profile" } });
    },
    async deleteUser(user_id) {
      let atp = (
        await axios.delete(apiConfig.routes.admin.profile + "/" + user_id, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp.statusCode === 200) {
        await this.fetchUserList(this.user_type);
      } else {
        alert("del fail");
        await this.fetchUserList(this.user_type);
      }
    },
    async fetchUserList(user_type) {
      this.loading = true;
      this.user_type = user_type;
      let url =
        apiConfig.routes.web.peopleAll +
        "/" +
        this.user_type +
        `?exclude=${
          this.$store.state.admin.admin_user_id ||
          this.$store.state.user.user_id
        }`;
      let params;
      if (this.searchText != "") {
        params = {
          page: this.pageNumber,
          size: 8,
          orderby: this.order1,
          orderoption: this.order2,
          search_text: this.searchText,
        };
      } else {
        params = {
          page: this.pageNumber,
          size: 8,
          orderby: this.order1,
          orderoption: this.order2,
        };
      }

      axios.get(url, { params }).then((item) => {
        this.pageLength = item.data.pageCount;
        this.userList = item.data.result.rows;
      });
      this.loading = false;
    },
    syncSciVal() {
      this.loading = true;
      let url = apiConfig.routes.admin.syncSciVal;
      axios.put(url).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.search-box {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: white;
  width: 300px;
}
.loadingHeight {
  height: 75vh;
}

.page-selector {
  position: absolute;
  bottom: -50px;
  width: 80%;
}
</style>
