<template>
  <Layout class="myconcainer" style="min-height: 95vh">
    <div class="about">
      <PageBar msg="LABORATORY" />
    </div>
    <SortBy
      :data1="order1"
      :item1="items1"
      :data2="order2"
      :item2="items2"
      @changeData1="(v) => dataChangeHandler(v, 'order1')"
      @changeData2="(v) => dataChangeHandler(v, 'order2')"
      :search="searchText"
      @Search="(v) => (SearchText = v)"
      placeholder="Search by Name"
    />

    <Loading class="loadingHeight" v-if="loading" />
    <v-row
      v-else
      class="content ma-0"
      justify="center"
      style="min-height: 30vh"
    >
      <v-row class="pa-3 pl-6 ma-0" justify="start">
        <v-col
          v-for="lab in labList"
          cols="12"
          sm="6"
          xl="4"
          :key="lab.lab_id"
          class="pa-3"
        >
          <LabCard
            v-on:click.native="selectLab(lab)"
            :title="lab.lab_title"
            :description="lab.lab_description"
            :count="lab.users.length"
            :backgroundColor="lab.lab_color"
            :textColor="lab.lab_text_color"
            :imgSrc="baseUrl + (lab.file !== null ? lab.file.url : '')"
          />
        </v-col>
      </v-row>
    </v-row>
    <div class="text-center">
      <v-pagination
        v-model="pageNumber"
        :length="pageLength"
        @input="fetchData"
      ></v-pagination>
    </div>
  </Layout>
</template>

<script>
import PageBar from "../components/PageBar";
import LabCard from "../components/LabCard";
import Loading from "../components/common/Loading";
import SortBy from "../components/SortBy";
import Layout from "../components/layout/Layout.vue";

import axios from "axios";
const apiConfig = require("../../api.config");

export default {
  components: {
    SortBy,
    PageBar,
    LabCard,
    Loading,
    Layout,
  },
  data: () => ({
    baseUrl: apiConfig.routes.base,
    order1: "Title",
    order2: "ASC",
    items1: ["Title", "Date"],
    items2: [
      { name: "A - Z", value: "ASC" },
      { name: "Z - A", value: "DESC" },
    ],
    page: 1,
    pageNumber: 1,
    pageLength: 0,
    searchText: "",
    labList: [],
    loading: true,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    dataChangeHandler(v, change) {
      if (change === "order1") this.order1 = v;
      else {
        this.order2 = v;
      }
    },
    selectLab(lab) {
      this.$router.push({
        name: "LabDetail",
        params: {
          id: lab.lab_id,
        },
      });
    },
    getcount(lab) {
      return lab.users ? lab.users.length : 0;
    },
    async fetchData() {
      this.loading = true;
      let atp;
      atp = (
        await axios.get(
          apiConfig.routes.lab.all +
            `?page=${this.pageNumber}&size=8&orderby=${
              this.order1
            }&orderoption=${this.order2}${
              this.searchText ? "&search_text=" + this.searchText : ""
            }`
        )
      ).data;
      // console.log(atp);
      // console.log(atp.result.rows);
      if (atp.statusCode === 200) {
        this.labList = atp.result.rows;
        this.pageLength = atp.pageCount;
      }
      this.loading = false;
      console.log(this.loading);
    },
  },
  watch: {
    searchText() {
      this.pageNumber = 1;
      this.fetchData();
    },
    order1() {
      this.fetchData();
    },
    order2() {
      this.fetchData();
    },
  },
};
</script>
<style scoped>
.myconcainer {
  background-color: #f2f7ff;
}

.bar {
  width: 100vw;
  height: 40px;
  background-color: #e5e5e5;
  text-align: center;
  line-height: 40px;
}

.button {
  display: inline-block;
  margin-left: 20px;
  height: 100%;
  border-width: 0px;
  width: 100px;
  background-color: #c4c4c4;
}

.selectInput {
  margin-left: 20px;
}

.b {
  display: inline-block;
  margin: 2px;
  color: #1c86f2;
}

.pageSelection {
  margin-top: 20px;
}

.loadingHeight {
  height: 60vh;
}
</style>
