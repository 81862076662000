<template>
  <v-card
    :color="backgroundColor"
    class="card justify-space-between d-flex flex-column mx-auto"
    min-height="400px"
    max-width="500px"
    v-bind:class="{
      'black--text': textColor == 'black',
      'white--text': textColor == 'white',
    }"
  >
    <v-img
      height="200px"
      aspect-ratio="2"
      :src="imageSrc"
      v-on:error="handleImageLoadError"
    ></v-img>
    <v-card-title>
      <v-icon large left :color="textColor || black"> mdi-home </v-icon>
      <span class="title font-weight-light">{{ seeMoreText(title, 55) }}</span>
    </v-card-title>

    <v-card-text class="text-body-1" v-bind:class="{
      'black--text': textColor == 'black',
      'white--text': textColor == 'white',
    }">
      {{ seeMoreText(description, 200) }}
    </v-card-text>

    <v-card-actions style="position: relative; bottom: 0">
      <div class="">
        <v-icon large left :color="textColor || black"> mdi-account </v-icon>
      </div>
      <div class="font-weight-light text-truncate">{{ count }}</div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: [
    "title",
    "description",
    "count",
    "backgroundColor",
    "imgSrc",
    "textColor",
  ],
  name: "SelectInput",
  data() {
    return {
      loadImageFail: false,
    };
  },
  methods: {
    viewDetail() {
      this.$router.push({ name: "profile" });
    },
    seeMoreText(value, limit) {
      if (value && value.length > limit) return value.slice(0, limit) + "...";
      return value;
    },
    handleImageLoadError() {
      this.loadImageFail = true;
    },
  },
  computed: {
    imageSrc() {
      console.log(this.loadImageFail);
      return this.loadImageFail
        ? require("../assets/no-photo.jpeg")
        : this.imgSrc;
    },
  },
  mounted() {
    console.log("this.imgSrc", this.imgSrc);
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
