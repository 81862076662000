<template>
  <Layout class="home">
    <Loading class="loadingHeight" v-if="loading" />
    <template v-else>
      <v-row justify="center" align="center" class="ma-0 pt-0 hero">
        <v-img
          class="abso-height-width"
          alt="it-kmitl"
          src="../assets/it-kmitl.jpeg"
          eager
        ></v-img>
        <div class="abso-height-width backround-gradient" />
        <div
          class="
            pa-0
            d-flex
            flex-column
            ma-0
            align-center
            justify-center
            px-2
            abso-height-width
          "
        >
          <h1
            class="
              mb-15
              white--text
              text-h4 text-md-h1 text-sm-h3 text-uppercase
            "
          >
            Expert System
          </h1>
          <div
            class="
              d-flex
              flex-column flex-sm-row
              justify-center
              align-center
              ma-0
              pa-0
            "
          >
            <span class="white--text text-h5 mr-2">Search By</span>
            <div class="d-flex justify-center align-center mt-1">
              <v-select
                :items="['Lecturer', 'Publication', 'Lab']"
                v-model="searchBy"
                solo
                hide-details=""
                class="mr-2 selector"
              />
              <v-autocomplete
                solo
                placeholder="Search"
                :items="searchItems"
                :search-input.sync="search"
                :loading="loadingSearch"
                v-model="searchData"
                hide-details=""
                class="mr-sm-2"
              />
            </div>
          </div>
        </div>
      </v-row>
      <!-- Lastest Publication -->
      <PageBar msg="OUR PUBLICATION" />
      <v-row class="mt-5 ma-0" justify="center">
        <v-col cols="12" md="9">
          <v-card class="mx-auto mt-5" width="100%">
            <v-list color="" two-line>
              <v-list-item-group multiple>
                <template v-for="(item, index) in listPublication">
                  <PublicationList
                    :data="item"
                    :key="item.title + index.toString()"
                    :index="index"
                    detailcomponent_name="user_pubdetail_in_home_page"
                  />
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="ma-0 mb-10" justify="center">
        <router-link to="/publication" @click.native="$scrollToTop"
          ><v-btn large depressed color="primary" class="mt-10"
            >See more</v-btn
          ></router-link
        >
      </v-row>
      <!-- random Expert -->
      <PageBar msg="OUR EXPERT" />
      <div class="section">
        <v-row justify="center" align="center" class="ma-0 mt-5">
          <v-col
            v-for="item in randomExpert"
            :key="item.user_id"
            cols="12"
            md="4"
          >
            <PubCard
              :fullName="item.firstname_en + ' ' + item.lastname_en"
              :imgSrc="item.file != null ? baseRoute + item.file.url : null"
              :skills="item.skills"
              :uid="item.user_id.toString()"
            />
          </v-col>
        </v-row>
        <router-link to="/people/teacher" @click.native="$scrollToTop"
          ><v-btn large depressed color="primary" class="mt-10"
            >See more</v-btn
          ></router-link
        >
      </div>
      <!-- AWARDED PROJECTS -->
      <PageBar msg="DASHBOARD" />
      <div class="section">
        <v-row class="ma-0 mt-6" justify="center" align="center">
          <v-col cols="12" md="8" class="pa-3">
            <v-row class="ma-0 py-3">
              <h1 class="pl-0 text-h4">Total Publication Each Year</h1>
            </v-row>
            <Loading v-if="loadingChart" :size="70" />
            <BarChart
              class="chart-contariner"
              :chartData="barChartData"
              :options="barChartOption"
              v-if="!loadingChart"
            />
            <v-row class="ma-0 py-3">
              <h1 class="pl-0 text-h4">Total Publication By Lab</h1>
            </v-row>
            <Loading v-if="loadingChart" :size="70" />
            <HorizontalBar
              class="chart-contariner"
              :chartData="barChartData2"
              :options="barChartOption2"
              v-if="!loadingChart"
            />
            <v-row class="ma-0 py-3">
              <h1 class="pl-0 text-h4">
                Lectures By Academic Positions (Percent)
              </h1>
            </v-row>
            <Loading v-if="loadingChart" :size="70" />
            <PieChart
              class="chart-contariner"
              :chartData="barChartData3"
              :options="barChartOption3"
              v-if="!loadingChart"
            />
            <v-row class="ma-0 py-3">
              <h1 class="pl-0 text-h4">
                Avg Publication Per Lecturer Each Year
              </h1>
            </v-row>
            <Loading v-if="loadingChart" :size="70" />
            <BarChart
              class="chart-contariner"
              :chartData="barChartData4"
              :options="barChartOption4"
              v-if="!loadingChart"
            />
            <!---- Chart.js ----->
          </v-col>
        </v-row>
      </div>
      <v-row
        class="ma-0 pa-2 white justify-start justify-sm-center"
        align="center"
      >
        <div class="d-flex justify-start align-center my-1">
          <div><v-icon color="blue darken-3 text-h1">mdi-book</v-icon></div>
          <div>
            <span
              class="text-h5 text-sm-h5 text-md-h5 pl-2"
              style="min-width: 70px"
            >
              {{ allPublication }}
            </span>
          </div>
          <div>
            <span
              class="text-h5 text-sm-h5 text-md-h5 pl-2"
              style="min-width: 70px"
              >Publication</span
            >
          </div>
        </div>

        <div class="d-flex justify-start align-center my-1 mx-3">
          <div><v-icon color="blue darken-3 text-h1">mdi-account</v-icon></div>
          <div>
            <span
              class="text-h5 text-sm-h5 text-md-h5 pl-2"
              style="min-width: 70px"
            >
              {{ allLecturer }}
            </span>
          </div>
          <div>
            <span
              class="text-h5 text-sm-h5 text-md-h5 pl-2"
              style="min-width: 70px"
              >Lecturer</span
            >
          </div>
        </div>
      </v-row>
    </template>
  </Layout>
</template>

<script>
import PubCard from "../components/PubCard";
import PageBar from "../components/PageBar";
import api from "../../api.config";
import axios from "axios";
import PublicationList from "../components/PublicationList";
import Layout from "../components/layout/Layout.vue";
import BarChart from "../components/common/chart/barChart.vue";
import HorizontalBar from "../components/common/chart/HorizontalBarChart.vue";
import PieChart from "../components/common/chart/PieChart.vue";
import Loading from "../components/common/Loading";

export default {
  name: "Home",
  components: {
    PublicationList,
    PubCard,
    PageBar,
    Layout,
    BarChart,
    HorizontalBar,
    PieChart,
    Loading,
  },
  data: () => ({
    baseRoute: api.routes.base,
    loading: true,
    chartPulication: [],
    chartPublicationByLab: [],
    chartAvgPub: [],
    randomExpert: null,
    model: 0,
    items: [],
    listPublication: null,
    searchBy: "Publication",
    loadingSearch: false,
    length: 10,
    search: null,
    searchResult: [],
    allPublication: 0,
    allLecturer: 0,
    searchData: null,
    barChartData: {},
    barChartOption: {},
    barChartData2: {},
    barChartOption2: {},
    barChartData3: {},
    barChartOption3: {},
    barChartData4: {},
    barChartOption4: {},
    loadingChart: true,
  }),
  computed: {
    searchItems() {
      let result;
      if (this.searchBy === "Publication") {
        result = this.searchResult.map((item) => item.title);
      } else if (this.searchBy === "Lab") {
        result = this.searchResult.map((item) => item.lab_title);
      } else {
        result = this.searchResult.map(
          (item) => item.firstname_en + " " + item.lastname_en
        );
      }

      return result;
    },
  },
  watch: {
    search() {
      this.loadingSearch = true;
      if (this.search != null && this.search != "") {
        if (this.searchBy === "Publication") {
          this.searchPublication();
        } else if (this.searchBy === "Lab") {
          this.searchLab();
        } else {
          this.searchLecturer();
        }
      } else {
        this.searchResult = [];
      }
      this.loadingSearch = false;
    },
    searchData() {
      let result;
      if (this.searchBy === "Publication") {
        result = this.searchResult.find(
          (item) => item.title === this.searchData
        );
      } else if (this.searchBy === "Lab") {
        result = this.searchResult.find(
          (item) => item.lab_title === this.searchData
        );
      } else {
        result = this.searchResult.find(
          (item) =>
            item.firstname_en + " " + item.lastname_en === this.searchData
        );
      }
      let params =
        this.searchBy === "Publication"
          ? { publication_id: result.publication_id }
          : this.searchBy === "Lab"
          ? { id: result.lab_id }
          : { userId: result.user_id };
      this.$router.push({
        name:
          this.searchBy === "Publication"
            ? "user_pubdetail"
            : this.searchBy === "Lab"
            ? "LabDetail"
            : "profile",
        params: params,
      });
    },
  },
  methods: {
    searchLecturer() {
      let url = api.routes.web.peopleAll + "/LECTURER";
      axios
        .get(url, {
          params: {
            page: 1,
            size: 10,
            orderby: "Name",
            orderoption: "ASC",
            search_text: this.search,
          },
        })
        .then((res) => {
          this.searchResult = res.data.result.rows;
        });
    },
    searchLab() {
      let url = api.routes.lab.all;
      axios
        .get(url, {
          params: {
            page: 1,
            size: 10,
            orderby: "Title",
            orderoption: "ASC",
            search_text: this.search,
          },
        })
        .then((res) => {
          // console.log(res.data.result.rows);
          this.searchResult = res.data.result.rows;
        });
    },
    searchPublication() {
      let url = api.routes.publication;
      axios
        .get(url, {
          params: {
            page: 1,
            size: 10,
            search_text: this.search,
          },
        })
        .then((res) => {
          // console.log(res);
          this.searchResult = res.data.result.data;
        });
    },
    async fetchData() {
      let url = api.routes.web.randomExpert + "3";
      await axios.get(url).then((res) => {
        this.randomExpert = res.data.result;
      });
      url = api.routes.publication;
      axios
        .get(url, {
          params: {
            page: 1,
            size: 10,
          },
        })
        .then((res) => {
          this.listPublication = res.data.result.data;
        });
      url = api.routes.toppublication;
      axios.get(url).then((res) => {
        this.items = res.data.result;
      });
      this.loading = false;
    },
    async fetchChart() {
      this.loadingChart = true;
      let url = api.routes.dashboard;
      await axios
        .get(url)
        .then((res) => {
          // console.log(res.data.result.countLecturerByNamePrefix);
          this.chartPulication = res.data.result.countPublicationEachYear;
          this.chartPublicationByLab = res.data.result.countPublicationByLab;

          this.chartLectureNamePrefix =
            res.data.result.countLecturerByNamePrefix;
          this.allPublication = res.data.result.countAllPublication;
          this.allLecturer = res.data.result.countAllLecturer;
        })
        .finally(() => {
          this.createChart();
          this.createChart2();
          this.createChart3();
          this.loadingChart = false;
        });
    },

    async fetchChart2() {
      this.loadingChart = true;
      let url_2 = api.routes.dashboard + "/avg_pub/perlecturer_eachyear/";
      await axios
        .get(url_2)
        .then((res) => {
          this.chartAvgPub = res.data.result.avgPublicationPerLecturerEachYear;
        })
        .finally(() => {
          this.createChart4();
          this.loadingChart = false;
        });
    },

    // Total Publication Each Year
    createChart() {
      // let data = {};
      let minYear = 0;
      let maxYear = 0;

      // prepare data
      this.chartPulication.forEach((item, index) => {
        // console.log(item.year, "data");
        // console.log(index + 1);
        // if (!["InPress", "Accepted"].includes(item.year)) {
        //   // filter publication type
        //   if (data[item.type] === undefined) data[item.type] = {};
        //   if (data[item.type][item.year] === undefined) {
        //     data[item.type][item.year] = 1;
        //   } else {
        //     data[item.type][item.year] += 1;
        //   }
        //   // find min year and max year
        if (index === 0) {
          maxYear = item.year;
          minYear = item.year;
        } else {
          if (item.year > maxYear && item.year != null) {
            maxYear = item.year;
          }
          if (item.year < minYear && item.year != null) {
            minYear = item.year;
          }
        }
        // }
      });
      // create list for year
      let yearList = [];
      let dataList = [];
      let maxPublication = 0;
      // let colorPalette = ["#003f5c", "#7a5195", "#ef5675", "#ffa600"];
      // let colorPalette = ["#2d569e", "#6c81be", "#a2b0de", "#d9e1ff"];
      // let colorPalette = ["#2d569e"];

      // // create Array Object for datasets and count max publication
      // Object.keys(data).forEach((key, index) => {
      //   let dataTemp = {
      //     label: key,
      //     data: [],
      //     backgroundColor: colorPalette[index],
      //   };
      //   for (const secKey in data[key]) {
      //     dataTemp.data.push(data[key][secKey]);
      //     if (data[key][secKey] > maxPublication)
      //       maxPublication = data[key][secKey];
      //   }
      //   dataList.push(dataTemp);
      // });

      // Y Axis
      let dataTemp = {
        label: "Total Publication Each Year",
        data: [],
        backgroundColor: "#2d569e",
      };

      for (const key in this.chartPulication) {
        dataTemp.data.push(this.chartPulication[key].count);
        // console.log(dataTemp);
        if (this.chartPulication[key].count > maxPublication)
          maxPublication = this.chartPulication[key].count;
      }
      dataList.push(dataTemp);
      console.log(dataList, "datalist 2");

      // X Axis
      for (let index = minYear; index <= maxYear; index++) {
        yearList.push(index.toString());
      }
      console.log(dataList, "datalist 2");

      this.barChartData = {
        labels: yearList,
        datasets: dataList,
      };
      this.barChartOption = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                max: maxPublication,
                min: 0,
                stepSize: 10,
              },
            },
          ],
        },
      };
      this.loadingChart = false;
    },

    // Total Publication By Lab
    createChart2() {
      // create list
      let labName = [];
      let dataList = [];
      let maxPublication = 0;

      //Y Axis
      let dataTemp = {
        label: "Total Publication By Lab",
        data: [],
        backgroundColor: "#ffa600",
      };
      for (const key in this.chartPublicationByLab) {
        if (this.chartPublicationByLab[key].publication_count !== undefined) {
          dataTemp.data.push(this.chartPublicationByLab[key].publication_count);
        }
        if (this.chartPublicationByLab[key].publication_count > maxPublication)
          maxPublication = this.chartPublicationByLab[key].publication_count;
      }
      // console.log(maxPublication, "Max");
      dataList.push(dataTemp);
      // console.log(dataList, "dataList");

      // X Axis
      for (const key in this.chartPublicationByLab) {
        if (this.chartPublicationByLab[key].lab_name != undefined) {
          labName.push(this.chartPublicationByLab[key].lab_name);
        }
      }
      // console.log(labName, "labName");

      this.barChartData2 = {
        labels: labName,
        datasets: dataList,
      };
      this.barChartOption2 = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [
            {
              ticks: {
                max: maxPublication + 1,
                min: 0,
                stepSize: 10,
              },
            },
          ],
          yAxes: [
            {
              scaleShowLabels: true,
            },
          ],
        },
      };
      this.loadingChart = false;
    },

    // lectureNamePrefix
    createChart3() {
      // create list
      let namePrefix = [];
      let dataList = [];

      //Y Axis
      let dataTemp = {
        label: "Lecture Name Prefix",
        data: [],
        backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
      };

      for (const key in this.chartLectureNamePrefix) {
        if (
          this.chartLectureNamePrefix[key].percent_of_assoc_prof_dr != undefined
        ) {
          let percent =
            this.chartLectureNamePrefix[key].percent_of_assoc_prof_dr.split(
              "%"
            );
          dataTemp.data.push(percent[0]);
        }
        if (
          this.chartLectureNamePrefix[key].percent_of_asst_prof_dr != undefined
        ) {
          let percent =
            this.chartLectureNamePrefix[key].percent_of_asst_prof_dr.split("%");
          dataTemp.data.push(percent[0]);
        }
        if (
          this.chartLectureNamePrefix[key].percent_of_asst_prof != undefined
        ) {
          let percent =
            this.chartLectureNamePrefix[key].percent_of_asst_prof.split("%");
          dataTemp.data.push(percent[0]);
        }
        if (this.chartLectureNamePrefix[key].percent_of_dr != undefined) {
          let percent =
            this.chartLectureNamePrefix[key].percent_of_dr.split("%");
          dataTemp.data.push(percent[0]);
        }

        // console.log(dataTemp, "chart3");
      }
      dataList.push(dataTemp);

      // X Axis
      for (const key in this.chartLectureNamePrefix) {
        if (this.chartLectureNamePrefix[key].name_prefix_en != undefined) {
          namePrefix.push(this.chartLectureNamePrefix[key].name_prefix_en);
        }
      }
      this.barChartData3 = {
        labels: namePrefix,
        datasets: dataList,
      };
      this.barChartOption3 = {
        responsive: true,
        maintainAspectRatio: false,
      };
      this.loadingChart = false;
    },

    createChart4() {
      // let data = {};
      let minYear = 0;
      let maxYear = 0;

      // prepare data
      this.chartAvgPub.forEach((item, index) => {
        if (index === 0) {
          maxYear = item.year;
          minYear = item.year;
        } else {
          if (item.year > maxYear && item.year != null) {
            maxYear = item.year;
          }
          if (item.year < minYear && item.year != null) {
            minYear = item.year;
          }
        }
        // }
      });
      // create list for year
      let yearList = [];
      let dataList = [];
      let maxPublication = 0;
      // Y Axis
      let dataTemp = {
        label: "Total Publication Avg Each Year",
        data: [],
        backgroundColor: "#f87979",
      };

      for (const key in this.chartAvgPub) {
        dataTemp.data.push(this.chartAvgPub[key].avg_publication_each_lecturer);
        if (
          this.chartAvgPub[key].avg_publication_each_lecturer > maxPublication
        )
          maxPublication = this.chartAvgPub[key].avg_publication_each_lecturer;
      }

      dataList.push(dataTemp);
      console.log(dataList, "datalist 4");

      // X Axis
      for (let index = minYear; index <= maxYear; index++) {
        yearList.push(index.toString());
      }
      console.log(yearList, "yearlist 4");

      this.barChartData4 = {
        labels: yearList,
        datasets: dataList,
      };
      this.barChartOption4 = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                max: maxPublication + 1,
                min: 0,
                stepSize: 0.5,
              },
            },
          ],
        },
      };
      this.loadingChart = false;
    },
  },

  async created() {
    this.fetchData();
    await this.fetchChart();
    await this.fetchChart2();
  },
};
</script>
