<template>
  <v-card
    class="mx-auto text-start"
    width="100%"
    max-width="350px"
    elevation="6"
  >
    <v-img
      v-if="imgSrcVal !== ''"
      height="400px"
      width="100%"
      :src="imgSrcVal"
      eager
    >
    </v-img>
    <v-row
      v-else
      style="height: 400px"
      justify="center"
      align="center"
      class="ma-0"
    >
      <v-col class=" justify-center align-center d-flex">
        <v-icon size="200px">mdi-account-outline</v-icon>
      </v-col>
    </v-row>

    <v-card-title> {{ fullName }} </v-card-title>
    <v-card-subtitle v-if="skills != null">
      <v-chip
        v-for="item in skills"
        :key="item.skill_id"
        class="ma-2 text-wrap"
        outlined
        color="primary"
        label
        style="height:auto"
      >
        {{ item.skill_name }}
      </v-chip>
    </v-card-subtitle>

    <v-card-actions>
      <v-btn color="orange lighten-2" v-on:click="view" text>
        Watch Profile
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PubCard",
  props: {
    imgSrc: String,
    fullName: String,
    skills: Array,
    uid: String,
  },
  methods: {
    view(e) {
      e.stopPropagation();
      this.$router.push({ name: "profile", params: { userId: this.uid } });
    },
  },
  computed: {
    imgSrcVal: {
      get() {
        if (this.imgSrc != null) {
          return this.imgSrc;
        } else {
          return "";
        }
      },
    },
  },
};
</script>

<style scoped>
.pub-card {
  width: 430px;
  height: 560px;
  flex-direction: column;
}
.img {
  width: 100%;
  height: 280px;
  background-color: gray;
}
.detail {
  width: 100%;
  height: 280px;
  background: white;
}
</style>
