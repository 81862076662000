<template>
  <v-card
    style="width: 100%; background-color: #f2f7ff"
    elevation="0"
    class="ma-2"
  >
    <v-row>
      <v-col class="pa-3" cols="3" v-if="showImage"
        ><div style="width: 100%; height: 100%" class="">
          <v-img
            :src="imageSrc"
            :width="imageSize"
            class="mt-4 mx-auto"
          ></v-img></div
      ></v-col>
      <v-col class="pa-0 pl-5" :cols="showImage != true ? 12 : 9">
        <v-form ref="editform" v-model="editvalid" lazy-validation>
          <v-card-title v-if="edit === false">{{ major }}</v-card-title>
          <v-card-title v-if="edit === true">
            <v-col class="pa-0">
              <div class="body-2 grey--text font-weight-regular">
                Major
                <div class="d-inline red--text body-2 font-weight-regular">
                  *
                </div>
              </div>
              <v-text-field
                class="mr-10"
                outlined
                v-model="majorVal"
                label="Major"
                :rules="rules.majorRules"
              />
            </v-col>
          </v-card-title>
          <v-card-subtitle v-if="edit === false" class="text-left">{{
            university
          }}</v-card-subtitle>
          <v-card-subtitle v-else>
            <v-col class="pa-0">
              <div class="body-2 grey--text font-weight-regular">
                University
                <div class="d-inline red--text body-2 font-weight-regular">
                  *
                </div>
              </div>
              <!-- <v-text-field class="mr-10" outlined v-model="universityVal" /> -->
              <v-autocomplete
                v-model="universityVal"
                :loading="loading"
                :items="items"
                :search-input.sync="search"
                cache-items
                class="mr-10"
                outlined
                hide-no-data
                hide-details
                label="University"
                :rules="rules.universityRules"
              ></v-autocomplete>
            </v-col>
          </v-card-subtitle>
          <v-card-text v-if="edit === false" class="text-left">
            Graduate Year {{ graduate }}</v-card-text
          >
          <v-card-text v-if="edit === true">
            <v-row>
              <v-col cols="12" md="5">
                <div class="body-2 grey--text font-weight-regular">
                  Year Graduated
                  <div class="d-inline red--text body-2 font-weight-regular">
                    *
                  </div>
                </div>
                <v-select
                  class="mr-10"
                  outlined
                  :items="semesterItems"
                  v-model="graduateVal"
                  dense
                  :rules="rules.yearRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="edit === false">
            <v-btn
              v-if="showViewBtn"
              @click="clicked('View')"
              text
              color="primary"
            >
              View </v-btn
            ><v-btn
              v-if="showEditBtn"
              @click="clicked('Edit')"
              text
              color="primary"
            >
              Edit </v-btn
            ><v-btn
              v-if="showDeleteBtn"
              @click="clicked('Remove')"
              text
              color="primary"
            >
              Delete
            </v-btn>
            <v-spacer v-if="showShowInCV" />
            <v-checkbox
              v-if="showShowInCV"
              class="mr-5"
              v-model="showInCV"
              :label="`Put in CV`"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions v-if="edit === true" class="justify-end mr-10 mb-5">
            <v-btn @click="save()" color="green" class="white--text"
              >Save</v-btn
            >
            <v-btn @click="cancle()" color="red" class="white--text"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import rules from "../../../../rules";
import axios from "axios";
import api from "../../../../api.config";
export default {
  props: {
    major: String,
    university: String,
    graduate: String,
    showImage: Boolean,
    imageSrc: String,
    showViewBtn: Boolean,
    showEditBtn: Boolean,
    showDeleteBtn: Boolean,
    showShowInCV: Boolean,
    showInCV: Boolean,
    imageSize: String,
    edit: Boolean,
  },
  data() {
    return {
      rules,
      editvalid: true,
      majorPrev: this.major,
      universityPrev: this.university,
      graduatePrev: this.graduate,
      loading: false,
      items: null,
      search: this.university,
      select: this.university,
    };
  },
  computed: {
    semesterItems: {
      get() {
        let year = new Date().getFullYear();
        let arry = [];
        for (let index = year; index > year - 40; index--) {
          arry.push(index.toString());
        }
        return arry;
      },
    },
    majorVal: {
      get() {
        return this.major;
      },
      set(value) {
        this.$emit("onChange", { value, from: "education_major" });
      },
    },
    universityVal: {
      get() {
        return this.university;
      },
      set(value) {
        this.$emit("onChange", { value, from: "education_univesity_name" });
      },
    },
    graduateVal: {
      get() {
        return this.graduate;
      },
      set(value) {
        this.$emit("onChange", { value, from: "graduation_year" });
      },
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    clicked(clickFrom) {
      if (clickFrom === "Edit") {
        this.majorPrev = this.majorVal;
        this.universityPrev = this.universityVal;
        this.graduatePrev = this.graduateVal;
      }
      this.$emit(clickFrom);
    },
    cancle() {
      this.$emit("Cancle", {
        major: this.majorPrev,
        university: this.universityPrev,
        graduate: this.graduatePrev,
      });
    },
    save() {
      if (this.$refs.editform.validate()) {
        this.$emit("Save", {
          major: this.majorPrev,
          university: this.universityPrev,
          graduate: this.graduatePrev,
        });
        this.$refs.editform.resetValidation();
      }
    },
    querySelections(v) {
      this.loading = true;
      let url = api.routes.web.universityByName + v;
      axios
        .get(url)
        .then((item) => {
          let data = item.data.result;
          let final = data.map((item) => {
            return item.name;
          });
          this.items = final;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.items = [this.university];
  },
};
</script>

<style></style>
