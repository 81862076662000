<script>
import { HorizontalBar } from "vue-chartjs";
export default {
  extends: HorizontalBar,
  props: {
    chartData: Object,
    options: Object,
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 50,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
