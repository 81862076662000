<template>
  <div style="width: 100%; min-height: 1000px">
    <v-row
      class="ma-0 pa-2 white justify-start justify-sm-center"
      align="center"
    >
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
        >
          Publication by type
        </span>
        <div class="px-3">
          <v-select
            v-model="selectType"
            :items="type"
            v-on:change="getPublication"
            label="Select Type"
            outlined
            hide-details=""
            dense
          ></v-select>
        </div>
      </div>

      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Publication by year
        </span>
        <div class="px-3">
          <v-select
            v-model="selectYear"
            :items="years"
            v-on:change="getPublication"
            outlined
            hide-details=""
            dense
            label="Select Year"
          ></v-select>
        </div>
      </div>

      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Search by
        </span>
        <div class="px-3">
          <v-text-field
            v-model="selectName"
            :items="searchText"
            hide-details=""
            v-on:change="getPublication"
            placeholder="Publication Name"
            dense
            clearable
          />
        </div>
      </div>
      <v-btn class="ma-2" outlined color="indigo" v-on:click="showfiltertag()">
        Filter by Tag
      </v-btn>
      <v-row
        class="ma-0 pt-6 white justify-start justify-sm-center"
        align="center"
        v-if="filtertags"
      >
        <v-col cols="10" md="8">
          <v-autocomplete
            v-model="selectTag"
            :items="tags"
            outlined
            dense
            chips
            small-chips
            label="Select tag"
            multiple
            v-on:change="getPublication"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-btn class="ma-2" outlined color="indigo" @click="syncScholar()">
        Sync Scholar
      </v-btn>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-row class="ma-5 pt-3" justify="center" style="min-height: 50vh" v-else>
      <v-col cols="11" v-if="!notFound">
        <v-card class="mx-auto mt-5" width="100%">
          <v-list flat>
            <template v-for="(item, index) in items">
              <PublicationList
                :data="item"
                :key="item.title + index.toString()"
                :index="index"
                detailcomponent_name="admin_pubdetail_in_admin_page"
              />
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="11" v-else>
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          class="mx-auto mt-5"
        >
          Not Found Any Publication
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify="center" align="center">
      <v-pagination
        class="mt-5 mb-5"
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="fetchData"
      ></v-pagination>
    </v-row>
  </div>
</template>

<script>
import PublicationList from "../../../components/PublicationList.vue";
import Loading from "../../../components/common/Loading.vue";
import axios from "axios";
const apiConfig = require("../../../../api.config");
var qs = require("qs");
export default {
  components: {
    Loading,
    PublicationList,
  },
  data: () => ({
    selectType: "All",
    selectYear: "All",
    selectName: "",
    selectTag: null,
    type: ["All", "Article", "Book", "Book section", "Conference item"],
    items: [],
    tags: [],
    size: 8,
    page: 1,
    pageCount: 0,
    searchText: null,
    loading: true,
    notFound: true,
    filtertags: false,
  }),

  mounted() {
    this.fetchData();
    this.fetchTagName();
  },

  computed: {
    years() {
      const year = new Date().getFullYear();
      let result = [];
      result = Array.from(
        { length: year - 1995 },
        (value, index) => 1996 + index
      );
      result.push("All");
      return result.reverse();
    },
  },

  watch: {
    selectName: function () {
      this.page = 1;
      this.getPublication();
    },
  },

  methods: {
    getPublication() {
      this.page = 1;
      this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      let atp;
      let url = apiConfig.routes.publication;
      let params = {
        page: this.page,
        size: 8,
        type_filter: null,
        year_filter: "",
        search_text: this.searchText,
      };
      if (this.selectTag != null) {
        let tag_filter = qs.stringify(
          { tag_filter: this.selectTag },
          { indices: false }
        );
        url += "?" + tag_filter;
      }
      // select Type
      if (this.selectType == "All") {
        params.type_filter = null;
      } else if (this.selectType == "Article") {
        params.type_filter = "article";
      } else if (this.selectType == "Book") {
        params.type_filter = "book";
      } else if (this.selectType == "Book section") {
        params.type_filter = "book_section";
      } else if (this.selectType == "Conference item") {
        params.type_filter = "conference_item";
      } else {
        params.type_filter = this.selectType;
      }
      // search Year
      if (this.selectYear != null) {
        if (this.selectYear == "All") {
          params.year_filter = null;
        } else {
          params.year_filter = this.selectYear;
        }
      }
      // search Text
      if (this.selectName === "") delete params["search_text"];
      else {
        params.search_text = this.selectName;
      }
      try {
        atp = await axios.get(url, { params });
        // console.log(params);
        if (atp.status == 200) {
          this.pageCount = atp.data.result.pageCount;
          this.items = atp.data.result.data;
          if (atp.data.result.data.length == 0) {
            this.notFound = true;
            this.loading = false;
          } else {
            this.notFound = false;
            this.loading = false;
          }
          // console.log(this.items);
        }
      } catch (e) {
        this.notFound = true;
        this.loading = false;
      }
    },

    syncScholar() {
      this.loading = true;
      let url = apiConfig.routes.publication + "/sync";
      axios
        .get(url, {
          headers: { authorization: this.$store.state.user.token },
        })
        .then(() => {
          this.loading = false;
        });
    },
    showfiltertag() {
      if (this.filtertags) {
        this.filtertags = false;
      } else {
        this.filtertags = true;
      }
    },
    fetchTagName() {
      this.tags = [];
      let url = apiConfig.routes.user.get.skillAll;
      axios.get(url).then((item) => {
        this.tags = item.data.result;
      });
    },
  },
};
</script>
