<template>
  <v-container fluid class="ma-0 pa-0 pb-5" style="min-height: 30vh">
    <Header title="Academic Service" create @createHandler="create()" />
    <v-row
      class="pl-5 text-body-2 ma-0 flex-column flex-md-row"
      style="background-color: #f2f2f2"
      align="center"
    >
      <v-col cols="12" md="1" class="pa-0"> Filter By </v-col>
      <v-col cols="12" md="2">
        <v-select
          flat
          solo
          v-model="category"
          :items="categoryItems"
          dense
          hide-details=""
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" class="pa-0"> Order By </v-col>
      <v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          v-model="orderBy"
          :items="orderByItems"
          dense
        ></v-select> </v-col
      ><v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          v-model="sortBy"
          :items="sortByItems"
          dense
        ></v-select>
      </v-col>
      <v-spacer />
      <v-col class="" cols="12" md="2">
        <v-text-field
          hide-details=""
          solo
          flat
          label="Search"
          append-icon="mdi-magnify"
          dense
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- content -->
    <Loading v-if="loading" style="min-height:20vh" />
    <div class="d-flex flex-column pa-2" v-else-if="items.length > 0">
      <div v-for="item in items" :key="item.academicservice_id">
        <AcademicCard
          :data="item"
          :categorys="categoryItems"
          @Remove="setRemoveItem(item)"
          @Save="fetchData"
          @onChange="item[$event.from] = $event.value"
          @Cancle="cancleHandler(item)"
          @changePutInCv="changePutInCV(item)"
        />
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="min-height: 30vh"
    >
      <span>No Data</span>
    </div>
    <div class="text-center mt-5">
      <v-pagination v-model="page" :length="pageLength"></v-pagination>
    </div>
    <ConfirmDialog
      :show="dialog"
      title="Delete"
      agree="Delete"
      disagree="Cancel"
      @onDialogChange="dialog = !dialog"
      @cancle="removeItem(false)"
      @confirm="removeItem(true)"
    />
  </v-container>
</template>

<script>
import Header from "../../layout/Header";
import AcademicCard from "../../card/AcademicCard";
import Loading from "../../../common/Loading";
import api from "../../../../../api.config";
import axios from "axios";
import ConfirmDialog from "../../../common/ConfirmDialog.vue";
export default {
  components: {
    Header,
    AcademicCard,
    Loading,
    ConfirmDialog,
  },
  data() {
    return {
      page: 1,
      pageLength: 0,
      loading: true,
      dialog: false,
      items: [],
      categoryItems: ["ALL"],
      category: "ALL",
      orderByItems: ["Title", "Start_date"],
      orderBy: "Title",
      sortByItems: ["ASC", "DESC"],
      sortBy: "ASC",
      search: "",
    };
  },
  watch: {
    page() {
      this.fetchData();
    },
    category() {
      this.fetchData();
    },
    orderBy() {
      this.fetchData();
    },
    sortBy() {
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
  },
  methods: {
    async changePutInCV(item) {
      console.log("changePutInCV");
      this.loading = true;
      const url =
        api.routes.user.create.academicService + item.academicservice_id;
      const headers = {
        authorization: this.$store.state.user.token,
      };
      console.log(headers);
      console.log(item.putinCV);
      await axios.put(url, { putinCV: item.putinCV }, { headers });
      this.fetchData();
    },
    cancleHandler(item) {
      if (item.id === undefined) {
        this.removeThis = item;
        this.removeItem(true);
      }
    },
    onChangeHandler(value) {
      value.edit = !value.edit;
    },
    create() {
      this.items.unshift({
        title: "",
        category: "",
        start_date: "",
        end_date: "",
        description: "",
        putinCV: true,
      });
      console.log(this.items);
    },
    setRemoveItem(item) {
      this.removeThis = item;
      this.dialog = true;
    },
    async removeItem(delOrNot) {
      let data = this.removeThis;
      if (delOrNot) {
        if (data.academicservice_id !== undefined) {
          let url =
            api.routes.user.delete.academicService + data.academicservice_id;
          const headers = {
            authorization: this.$store.state.user.token,
          };
          await axios.delete(url, { headers }).then((res) => {
            console.log(res.data);
          });
        }
        let result = this.items.filter((item) => {
          return item !== data;
        });
        this.items = result;
        this.dialog = false;
      } else {
        this.removeThis = null;
        this.dialog = false;
      }
    },
    fetchCategorys() {
      axios.get(api.routes.academicservice.category).then((res) => {
        console.log(res.data);
        this.categoryItems.push(...res.data.result);
      });
    },
    fetchData() {
      this.loading = true;
      let user_id = this.$store.state.user.user_id;
      let url = api.routes.user.get.academicService + user_id;
      let params = {
        page: this.page,
        size: 6,
        orderby: this.orderBy,
        orderoption: this.sortBy,
        filter: this.category,
        search_text: this.search,
      };
      if (this.category === "ALL") delete params["filter"];
      if (this.search === "") delete params["search_text"];
      console.log(params);
      axios
        .get(url, {
          params,
        })
        .then((res) => {
          console.log(res.data);
          this.items = res.data.result.events;
          this.pageLength = res.data.result.pageCount;
          if (this.pageLength < this.page) this.page = this.pageLength;
          if (this.pageLength < 1) this.page = 1;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchCategorys();
    this.fetchData();
  },
};
</script>

<style></style>
