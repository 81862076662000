<template>
  <v-card class="mt-2" width="100%" min-height="200">
    <div v-if="!edit">
      <v-list-item class="align-start">
        <v-list-item-content>
          <v-list-item-title class="text-h6 text-wrap">
            {{ data.title }}
          </v-list-item-title>
        </v-list-item-content>
        <div class="d-flex align-center text-subtitle-2 pt-4">
          <div
            class="mr-1"
            v-bind:class="{
              green: data.status === 'FINISHED',
              orange: data.status === 'ONGOING',
              red: data.status === 'CANCLED',
            }"
            style="height: 10px; width: 10px; border-radius: 50%"
          />
          <span
            v-bind:class="{
              'green--text': data.status === 'FINISHED',
              'orange--text': data.status === 'ONGOING',
              'red--text': data.status === 'CANCLED',
            }"
            class="d-flex flex-shrink-0"
            >{{ data.status }}</span
          >
        </div>
      </v-list-item>
      <v-card-subtitle class="pt-1 pb-0">
        {{ startDateVal + " - " + endDateVal }}
      </v-card-subtitle>

      <v-card-subtitle class="pt-0 pb-0 text-body-1 ma-0"
        >{{ data.budget }} THB</v-card-subtitle
      >
      <v-card-text class="d-flex flex-column mt-2">
        <span class="title">Authors</span>
        <span class="text-subtitle-1">Principal Investigator</span>
        <div>
        <v-chip class="mr-2 my-1 text-wrap" label small color="primary" outlined
        style="height:auto">
          {{
            `${data.principal_investigator.name_prefix_en || ""} ${
              data.principal_investigator.firstname_en
            } ${data.principal_investigator.lastname_en}`
          }}
        </v-chip>
        </div>
        <span class="text-subtitle-1">Researcher</span>
        <div>
        <v-chip class="mr-2 my-1 text-wrap" label small color="primary" outlined
        style="height:auto">
          {{
            `${data.researcher_user.name_prefix_en || ""} ${data.researcher_user.firstname_en} ${data.researcher_user.lastname_en}`
          }}
        </v-chip>
        </div>
        <span class="text-subtitle-1">Research Assistant</span>
        <div>
        <v-chip class="mr-2 my-1 text-wrap" label small color="primary" outlined
        style="height:auto">
          {{
            `${data.research_assistant.name_prefix_en || ""} ${data.research_assistant.firstname_en} ${data.research_assistant.lastname_en}`
          }}
        </v-chip>
        </div>
        <span class="title">Publication</span>
        <div v-if="data.publications && data.publications.length > 0" class="d-flex flex-column">
        <span v-for="publication in data.publications" :key="publication.publication_id" class="text-body-2 pl-5">
          {{`-  ${publication.title}`}}
        </span>
        </div>
        <div v-else>-</div>
      </v-card-text>
      
      <v-card-text class="d-flex flex-column">
        <span class="title">KeyWords</span>
        <div class="d-flex flex-wrap" v-if="tagsVal && tagsVal.length > 0">
          <div v-for="(item, index) in tagsVal" :key="item + index">
            <v-chip
              class="mr-2 mt-1 text-wrap"
              label
              small
              color="primary"
              outlined
              style="height:auto"
              >{{ item }}</v-chip
            >
          </div>
        </div>
        <div v-else>-</div>
      </v-card-text>
      <v-card-actions v-if="editAble">
        <v-btn text class="blue--text" @click="edit = true">Edit</v-btn>
        <v-btn text class="blue--text" @click="emitHandler('Remove')">
          Delete
        </v-btn>
      </v-card-actions>
    </div>

    <div class="pl-2" v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="ma-0">
          <v-col md="10">
            <v-text-field
              v-model="titleVal"
              outlined
              dense
              hide-details=""
              label="Title"
              :rules="rules.titleRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="5">
            <v-autocomplete
              v-model="principalInvestigatorVal"
              outlined
              dense
              hide-details=""
              label="Principal Investigator"
              :items="userItems"
              :item-text="(user) => user.fullname_en"
              :item-value="(user) => user.user_id"
            ></v-autocomplete>
          </v-col>
          <v-col md="5">
            <v-autocomplete
              v-model="researcherVal"
              outlined
              dense
              hide-details=""
              label="Researcher"
              :items="userItems"
              :item-text="(user) => user.fullname_en"
              :item-value="(user) => user.user_id"
            ></v-autocomplete>
          </v-col>
          <v-col md="5">
            <v-autocomplete
              v-model="researchAssistantVal"
              outlined
              dense
              hide-details=""
              label="Research Assistant"
              :items="userItems"
              :item-text="(user) => user.fullname_en"
              :item-value="(user) => user.user_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="5">
            <DatePicker
              label="Start Date"
              refPicker="datepicker1"
              menuRef="menu1"
              :date="startDateVal"
              @onChange="startDateVal = $event.value"
            />
          </v-col>
          <v-col md="5">
            <DatePicker
              label="End Date"
              refPicker="datepicker2"
              menuRef="menu2"
              :date="endDateVal"
              :rule="dateTimeRules"
              @onChange="endDateVal = $event.value"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="5">
            <v-select
              :items="['FINISHED', 'CANCLED', 'ONGOING']"
              v-model="statusVal"
              outlined
              dense
              hide-details=""
              label="Status of Project"
              :rules="rules.rStatusRules"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="5">
            <v-text-field
              type="number"
              v-model="budgetVal"
              outlined
              dense
              hide-details=""
              label="Budget"
              :rules="[
                budgetVal >= 0 || 'Budget is invalid',
                !!budgetVal || 'Budget is required',
              ]"
            ></v-text-field>
          </v-col>
          <v-col md="1" class="d-flex align-center">
            <span class="text-body-1">THB</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="10">
            <v-combobox
              outlined
              v-model="tagsVal"
              :items="allSkill"
              :search-input.sync="search"
              hide-selected
              label="Add some tags"
              multiple
              persistent-hint
              small-chips
              :rules="[
                checktags(tagsVal) ||
                  'Each tag must be less than 60 characters',
              ]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="10">
            <v-combobox
              outlined
              v-model="publicationVal"
              :items="publicationItems"
              :item-text="(value) => value.title"
              :search-input="searchPublication"
              hide-selected
              label="Add Publication"
              multiple
              persistent-hint
              small-chips
              return-object
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn text class="blue--text" @click="saveHandler">Save</v-btn>
          <v-btn text class="blue--text" @click="cancleHandler">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </v-card>
</template>

<script>
// publication_ids
import DatePicker from "../../common/DatePicker";
import rules from "../../../../rules";
import axios from "axios";
import api from "../../../../api.config";
export default {
  components: {
    DatePicker,
  },
  watch: {
    edit() {
      if (this.edit) {
        this.budgetVal = this.budgetVal.toString();
      }
    },
  },
  props: {
    data: Object,
    allSkill: Array,
    publicationItems: Array,
    userItems:Array,
    editAble:Boolean,
  },
  data() {
    return {
      rules,
      valid: true,
      edit: true,
      tempData: JSON.parse(JSON.stringify(this.data)),
      search: "",
      searchPublication: "",
    };
  },
  mounted() {
    console.log(this.publicationItems);
    if (this.data.research2_id != undefined) this.edit = false;
    if(this.data.publications && this.data.publications.length > 0) {
      console.log('testtest')
    this.data.publication_ids = this.data.publications
    }
  },
  updated(){
    if(this.data.publications && this.data.publications.length > 0) {
      console.log('testtest')
    this.data.publication_ids = this.data.publications
    }
  },
  computed: {
    dateTimeRules() {
      const rules = [];
      const rule = (v) =>
        (!!v && v) >= this.startDateVal || "End date must be after start date.";
      rules.push(rule);
      return rules;
    },
    titleVal: {
      get() {
        return this.data.title;
      },
      set(value) {
        this.onChangeHandler(value, "title");
      },
    },
    statusVal: {
      get() {
        return this.data.status;
      },
      set(value) {
        this.onChangeHandler(value, "status");
      },
    },
    budgetVal: {
      get() {
        return this.data.budget;
      },
      set(value) {
        console.log(value);
        this.onChangeHandler(value, "budget");
      },
    },
    startDateVal: {
      get() {
        return this.data.start_date.slice(0, 10);
      },
      set(value) {
        this.onChangeHandler(value, "start_date");
      },
    },
    endDateVal: {
      get() {
        return this.data.end_date.slice(0, 10);
      },
      set(value) {
        this.onChangeHandler(value, "end_date");
      },
    },
    tagsVal: {
      get() {
        return this.data.tags;
      },
      set(value) {
        console.log(value);
        this.onChangeHandler(value, "tags");
      },
    },
    principalInvestigatorVal: {
      get() {
        return (
          this.data.principal_investigator ||
          this.data.principal_investigator_user_id
        );
      },
      set(value) {
        this.onChangeHandler(value, "principal_investigator_user_id");
      },
    },
    researcherVal: {
      get() {
        return this.data.researcher_user || this.data.researcher_user_id;
      },
      set(value) {
        this.onChangeHandler(value, "researcher_user_id");
      },
    },
    researchAssistantVal: {
      get() {
        return (
          this.data.research_assistant || this.data.research_assistant_user_id
        );
      },
      set(value) {
        this.onChangeHandler(value, "research_assistant_user_id");
      },
    },
    publicationVal: {
      get() {
        return this.data.publications || this.data.publication_ids;
      },
      set(value) {
        console.log(value);
        this.onChangeHandler(value, "publication_ids");
      },
    },
  },
  methods: {
    checkdate() {
      if (
        this.startDateVal &&
        this.endDateVal &&
        this.startDateVal <= this.endDateVal
      ) {
        return true;
      } else {
        return false;
      }
    },
    checktagsLength(tags) {
      if (tags && tags.length < 5) {
        return true;
      } else {
        return true;
      }
    },
    checktags(tags) {
      console.log("asdasd");
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          if (tag.length > 60) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    onChangeHandler(value, from) {
      this.$emit("onChange", { value, from });
    },
    emitHandler(value) {
      this.$emit(value);
    },
    cancleHandler() {
      if (this.data.research2_id === undefined) {
        this.emitHandler("Cancle");
      } else {
        this.onChangeHandler(this.tempData.title, "title");
        this.onChangeHandler(this.tempData.start_date, "start_date");
        this.onChangeHandler(this.tempData.end_date, "end_date");
        this.onChangeHandler(this.tempData.status, "status");
        this.onChangeHandler(this.tempData.budget, "budget");
        this.edit = false;
      }
    },
    saveHandler() {
      console.log(this.data);
      if (
        this.$refs.form.validate() &&
        this.budgetVal >= 0 &&
        this.checkdate()
      ) {
        const headers = {
          authorization: this.$store.state.user.token,
        };
        if (this.data.research2_id === undefined) {
          let url =
            api.routes.user.create.researchProject +
            this.$store.state.user.user_id;
          axios
            .post(
              url,
              {
                title: this.data.title,
                start_date: this.data.start_date,
                end_date: this.data.end_date,
                budget: this.data.budget,
                status: this.data.status,
                tags: this.data.tags,
                principal_investigator_user_id: this.data
                  .principal_investigator_user_id,
                researcher_user_id: this.data.researcher_user_id,
                research_assistant_user_id: this.data
                  .research_assistant_user_id,
                publication_ids: this.data.publication_ids.map(
                  (item) => item.publication_id
                ),
              },
              { headers }
            )
            .then((res) => {
              console.log(res.data);
              this.edit = false;
              this.$emit("Save");
            });
        } else {
          console.log(this.data.tags);
          let url =
            api.routes.user.update.researchProject + this.data.research2_id;
          let data = {
                title: this.data.title,
                start_date: this.data.start_date,
                end_date: this.data.end_date,
                budget: this.data.budget,
                status: this.data.status,
                tags: this.data.tags,
                principal_investigator_user_id: this.data
                  .principal_investigator_user_id,
                researcher_user_id: this.data.researcher_user_id,
                research_assistant_user_id: this.data
                  .research_assistant_user_id,
                publication_ids: this.data.publication_ids.map(
                  (item) => item.publication_id
                ),
              }
              console.log(data)
          axios
            .put(
              url,
              data,
              { headers }
            )
            .then((res) => {
              console.log(res.data);
              this.edit = false;
              this.$emit("Save");
            });
        }
      }
    },
  },
};
</script>

<style></style>
