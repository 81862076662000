<template>
  <router-link
    class="text-decoration-none black--text"
    :to="'/profile/' + uid"
    @click.native="$scrollToTop"
  >
    <v-card
      class="pa-2"
      style="display: flex; align-items: center;"
      max-width="500px"
    >
      <v-row class="ma-0">
        <v-col cols="12" lg="6" v-if="photoUrl !== null">
          <v-img
            class="ma-auto"
            :src="photoUrl"
            width="200px"
            height="200px"
            aspect-ratio="1"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="d-flex justify-center align-center"
          v-else
        >
          <v-icon
            class="mx-auto"
            style="width: 200px; height:200px"
            size="150px"
            >mdi-account-outline</v-icon
          >
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="d-flex flex-column justify-center align-center"
        >
          <div class="text-left">
            <h3>{{ name }}</h3>
            <p>Faculty of Information Technology</p>
            <h4>Expertise</h4>
            <div
              v-if="expertise && expertise.length > 0"
              style="height: 50px; overflow-y: hidden;"
            >
              <v-chip
                class="ml-1 text-wrap"
                outlined
                color="primary"
                v-for="skill in expertise"
                label
                style="height:auto"
                :key="skill.skill_id"
                x-small
                >{{ skill.skill_name }}</v-chip
              >
            </div>
            <div v-if="!expertise || expertise.length == 0">
              <p class="body-2 grey--text">
                No Data
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </router-link>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    name: String,
    expertise: Array,
    photo: String,
    uid: Number,
  },
  computed: {
    photoUrl: {
      get() {
        if (this.photo !== null) {
          return this.photo;
        } else {
          return null;
        }
      },
    },
  },
};
</script>

<style scoped></style>
