<template>
  <Layout>
    <PageBar msg="Laboratory Information" />
    <Loading v-if="loading" />
    <LabLayout v-else :lab="data" />
  </Layout>
</template>

<script>
import Layout from '../components/layout/Layout.vue'
import LabLayout from "../components/LabLayout.vue";
import PageBar from "../components/PageBar.vue";
import Loading from "../components/common/Loading.vue";
import axios from "axios";
import api from "../../api.config";
export default {
  components: {
    Layout,
    LabLayout,
    PageBar,
    Loading,
  },
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let url = api.routes.lab.byid + this.$route.params.id;
      axios.get(url).then((res) => {
        this.data = res.data.result;
        this.loading = false;
      });
    },
  },
};
</script>

<style scope></style>
