<template>
  <div>
    <a :href="newUrl + data.publication_id" target="_blank" class="warp">
      <v-list-item class="align-start">
        <!-- @click="view(data)" -->
        <v-list-item-content>
          <v-list-item-title
            class="d-flex text-wrap"
            style="color: #296d98"
            v-text="data.title"
          >
          </v-list-item-title>
          <v-list-item-subtitle>
            <p class="ma-0 mt-1">{{ data.l_type }}</p>
            <p class="ma-0 mt-1">{{ isNaN(data.year) ? "-" : data.year }}</p>
          </v-list-item-subtitle>
        </v-list-item-content>
        <div class="d-sm-flex justify-end pt-2">
          <span
            v-bind:class="{
              'green--text': data.is_published === 'pub',
              'gray--text': data.is_published === 'unpub',
              'orange--text': data.is_published === 'inpress',
            }"
            class="ma-0 mr-3 pl-2 pt-1 d-flex align-center"
          >
            <div
              v-bind:class="{
                green: data.is_published === 'pub',
                gray: data.is_published === 'unpub',
                orange: data.is_published === 'inpress',
              }"
              style="height: 10px; width: 10px; border-radius: 50%"
              class="mr-1"
            />
            <div style="font-size: 16px">
              {{
                data.is_published == "inpress"
                  ? capitalize(data.is_published)
                  : capitalize(data.is_published) + "lish"
              }}
            </div>
          </span>
        </div>
      </v-list-item>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    detailcomponent_name: String,
  },
  data() {
    return {
      newUrl: null,
    };
  },
  mounted() {
    this.setURL();
  },
  methods: {
    // view(val) {
    //   this.$router.push({
    //     name: this.detailcomponent_name,
    //     params: {
    //       publication_id: val.publication_id,
    //     },
    //   });
    // },
    capitalize(word) {
      if (word != null) {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      } else {
        return word;
      }
    },
    setURL() {
      const oldUrl = window.location.href;
      if (this.detailcomponent_name === "user_pubdetail_in_home_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/")) + "/pubdetail/";
      }
      if (this.detailcomponent_name === "user_pubdetail_in_profile_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/"));
        this.newUrl = this.newUrl.slice(0, this.newUrl.lastIndexOf("/")) + "/pubdetail/";
      }
      if (this.detailcomponent_name === "admin_pubdetail_in_admin_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/")) + "/admin/pubdetail/";
      }
      if (this.detailcomponent_name === "admin_pubdetail_in_account_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/"));
        this.newUrl = this.newUrl.slice(0, this.newUrl.lastIndexOf("/")) + "/admin/pubdetail/";
      }
    },
  },
};
</script>

<style>
.warp {
  text-decoration: none;
}
</style>
