<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cancle"> {{ disagree }} </v-btn>
          <v-btn color="blue" text @click="confirm">
            {{ agree }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "title", "message", "agree", "disagree"],
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    cancle() {
      this.$emit("cancle")
    },
  },
};
</script>