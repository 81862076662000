<template>
  <v-card class="pt-3 mb-3" max-width="100%" :key="index">
    <v-img
      class="black--text align-end"
      height="200px"
      aspect-ratio="2"
      :src="imgSrc"
    >
    </v-img>
    <v-card-title class="title font-weight-bold">{{ blog.title }}</v-card-title>
    <v-card-actions>
      <v-btn color="primary" text v-on:click="view()">
        Read More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const apiConfig = require("../../../../api.config");

export default {
  name: "Blog",
  props: {
    blog: Object,
  },
  data() {
    return {
      imgSrc: null,
      baseUrl: apiConfig.routes.base,
    };
  },
  mounted() {
    if (this.blog.coverImage != null) {
      this.imgSrc = this.baseUrl + this.blog.coverImage.url;
    } else {
      this.imgSrc = require("../../../assets/no-photo.jpeg");
    }
  },
  methods: {
    view() {
      this.$router.push({
        name: "Blog Detail",
        params: {
          id: this.blog.blog_id,
        },
      });
    },
  },
};
</script>

<style></style>
