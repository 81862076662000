<template>
  <Layout
    class="pa-0 ma-0"
    fluid
    style="background-color: #f2f7ff; height:100%"
  >
    <PageBar msg="ACCOUNT" />
    <!-- Tab Menu -->
    <v-tabs
      class="ma-0 pa-0"
      centered
      dark
      background-color="#2d569e"
      show-arrows
      v-model="tab"
    >
      <v-tab to="/account/profile">
        Profile
      </v-tab>
      <v-tab to="/account/education">
        Education
      </v-tab>
      <v-tab to="/account/training">
        Training
      </v-tab>
      <v-tab to="/account/certification">
        Certification
      </v-tab>
      <v-tab to="/account/publication">
        Publication
      </v-tab>
      <v-tab to="/account/academicservice">
        Academic Service
      </v-tab>
      <v-tab to="/account/researcher" v-if="userType !== 'RES_STUDENT'">
        researcher
      </v-tab>
      <v-tab to="/account/blog">
        Blog
      </v-tab>
      <!-- drop down Tab -->
      <v-menu :key="projectTabKey" offset-y class="white">
        <template v-slot:activator="{ on, attrs }">
          <v-tab v-bind="attrs" v-on="on" :to="tabMenu">
            {{ projectTab() }}
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-tab>
        </template>
        <v-list class="lighten-3 text-uppercase white">
          <v-list-item
            class="white"
            @click="forceRerender()"
            @click.native="$scrollToTop"
            to="/account/project"
          >
            project
          </v-list-item>
          <v-list-item
            class="white"
            @click="forceRerender()"
            @click.native="$scrollToTop"
            to="/account/master"
          >
            master
          </v-list-item>
          <v-list-item
            class="white"
            @click="forceRerender()"
            @click.native="$scrollToTop"
            to="/account/phd"
          >
            phd
          </v-list-item>
          <v-list-item
            class="white"
            @click="forceRerender()"
            @click.native="$scrollToTop"
            to="/account/research"
          >
            research
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Reset password btn in tab -->
      <v-btn
        text
        class="my-auto py-0"
        @click="resetpassword_dialog = true"
        v-if="
          $store.state.user.user_type === 'STAFF' && $store.state.user.is_admin
        "
      >
        Reset password
      </v-btn>
    </v-tabs>
    <!-- admin section -->
    <v-row justify="center" class="ma-0">
      <v-col class="" cols="12" md="10" lg="8">
        <v-row v-if="$store.state.admin.viewing" class="mt-2 mb-2">
          <div width="100%" class="pa-5 mx-auto">
            <div class="d-inline">
              You are watching user id: {{ $store.state.user.user_id }}
            </div>
            <v-btn
              @click="
                $store.commit('adminStopView');
                $router.replace({ name: 'admin' });
              "
              class="mx-auto d-block"
              >GO BACK</v-btn
            >
          </div>
        </v-row>
        <v-row class="mt-5" justify="center">
          <!--- This is content --->
          <v-col cols="12" md="10" class="mb-10 pa-0">
            <v-card width="100%" class="pb-5">
              <Profile
                v-if="
                  selectComponent === 'profile' ||
                    selectComponent === 'researcherprofile'
                "
              />
              <Education v-if="selectComponent === 'education'" />
              <Publication v-if="selectComponent === 'publication'" />
              <Project v-if="selectComponent === 'project'" />
              <SeniorProject
                v-if="
                  selectComponent === 'seniorproject' ||
                    selectComponent === 'isproject'
                "
              />
              <!-- <ISProject v-if="selectComponent === 'isproject'" /> -->
              <Master
                v-if="selectComponent === 'master' || selectComponent === 'phd'"
              />
              <SeniorDetail v-if="selectComponent === 'seniordetail'" />
              <MasterDetail
                v-if="
                  selectComponent === 'masterdetail' ||
                    selectComponent === 'createmaster' ||
                    selectComponent === 'createphd' ||
                    selectComponent === 'phddetail'
                "
              />
              <CreateSenior
                v-if="
                  selectComponent === 'createsenior' ||
                    selectComponent === 'createis'
                "
              />
              <Certification v-if="selectComponent === 'certification'" />
              <Training v-if="selectComponent === 'training'" />
              <PublicationDetail
                v-if="
                  selectComponent === 'publicationdetail' ||
                    selectComponent === 'createpublication'
                "
              />
              <ResearchProject v-if="selectComponent === 'research'" />
              <AcademicService v-if="selectComponent === 'academicservice'" />
              <Researcher v-if="selectComponent === 'researcher'" />
              <Blog v-if="selectComponent === 'blog'" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="resetpassword_dialog" persistent max-width="500">
        <v-card>
          <v-card-title style="color: #296d98" class="headline">
            Reset password confirm.
          </v-card-title>
          <v-card-text>ยืนยันการรีเซ็ทรหัสผ่าน</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="resetpassword_dialog = false">
              ยกเลิก
            </v-btn>
            <v-btn color="primary" text @click="resetPassword()">
              ยืนยัน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </Layout>
</template>

<script>
import axios from "axios";
import Profile from "../components/account/contrainer/Profile/profile.vue";
import Education from "../components/account/contrainer/Education/education.vue";
import Publication from "../components/account/contrainer/Publication/publication.vue";
import SeniorProject from "../components/account/contrainer/Senior/SeniorProject.vue";
import Master from "../components/account/contrainer/Master/Master.vue";
import SeniorDetail from "../components/account/contrainer/Senior/SeniorDetail.vue";
import MasterDetail from "../components/account/contrainer/Master/MasterDetail.vue";
import CreateSenior from "../components/account/contrainer/Senior/createSenior.vue";
import Certification from "../components/account/contrainer/Certification/certification.vue";
import Training from "../components/account/contrainer/Training/trainning.vue";
import apiConfig from "../../api.config";
import PublicationDetail from "../components/account/contrainer/Publication/pubicationDetail";
import ResearchProject from "../components/account/contrainer/FundedProject/ResearchProject";
import AcademicService from "../components/account/contrainer/AcademicService/AcademicService";
import Researcher from "../components/account/contrainer/Researcher/Researcher.vue";
import PageBar from "../components/PageBar.vue";
import Layout from "../components/layout/Layout.vue";
import Blog from "../components/account/contrainer/Blog/Blog.vue";
import Project from "../components/account/contrainer/Project/project.vue";
// import store from "../store/service";
export default {
  data() {
    return {
      selectComponent: "profile",
      expandProject: false,
      resetpassword_dialog: false,
      projectTabKey: 0,
      tab: null,
      tabMenu: null,
      userType: this.$store.state.user.user_type,
    };
  },
  mounted() {
    this.selectComponent = this.$route.params.component;
    if (this.selectComponent === "researcherprofile") {
      this.tab = "/account/researcher";
    } else if (this.selectComponent === "publicationdetail") {
      this.tab = "/account/publication";
    }
  },
  components: {
    AcademicService,
    PublicationDetail,
    Layout,
    Profile,
    Education,
    Publication,
    SeniorProject,
    Master,
    SeniorDetail,
    MasterDetail,
    CreateSenior,
    Certification,
    Training,
    ResearchProject,
    PageBar,
    Researcher,
    Blog,
    Project,
  },
  watch: {
    $route(to) {
      this.selectComponent = to.params.component;
      if (to.params.component === "researcherprofile") {
        this.tab = "/account/researcher";
      }
    },
  },
  methods: {
    forceRerender() {
      this.projectTabKey++;
    },
    async resetPassword() {
      try {
        await axios.post(
          apiConfig.routes.login.reset +
            "/id/" +
            this.$store.state.user.user_id,
          {},
          { headers: { authorization: this.$store.state.user.token } }
        );
      } catch (e) {
        console.log(e);
      }
      this.resetpassword_dialog = false;
    },
    projectTab() {
      if (["phddetail", "phd", "createphd"].includes(this.selectComponent)) {
        this.tabMenu = "/account/phd";
        this.tab = "/account/phd";
        return "phd";
      } else if (["project"].includes(this.selectComponent)) {
        this.tabMenu = "/account/project";
        this.tab = "/account/project";
        return "project";
      } else if (
        ["isproject", "createis", "seniordetail"].includes(this.selectComponent)
      ) {
        this.tabMenu = "/account/isproject";
        this.tab = "/account/isproject";
        return "isproject";
      } else if (
        ["master", "masterdetail", "createmaster"].includes(
          this.selectComponent
        )
      ) {
        this.tabMenu = "/account/master";
        this.tab = "/account/master";
        return "master";
      } else if ("research" === this.selectComponent) {
        this.tabMenu = "/account/research";
        this.tab = "/account/research";
        return "research";
      }
      return "project";
    },
  },
};
</script>
