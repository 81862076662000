<template>
  <Layout class="blog-container">
    <PageBar msg="BLOG" />
    <v-row
      class="ma-0 pa-2 white justify-start justify-sm-center"
      align="center"
    >
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
        >
          Order By
        </span>
        <div class="px-3">
          <v-select
            v-model="orderBy"
            :items="orderBy_items"
            v-on:change="getBlog"
            label="Select Order By"
            outlined
            hide-details=""
            dense
          >
          </v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
          >Order Option
        </span>
        <div class="px-3">
          <v-select
            v-model="orderOption"
            :items="orderOption_items"
            v-on:change="getBlog"
            outlined
            hide-details=""
            dense
            label="Select Order Option"
          ></v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Search Title
        </span>
        <div class="px-3">
          <v-text-field
            label="Blog Title"
            v-model="selectName"
            :items="searchText"
            hide-details=""
            v-on:change="getBlog"
            dense
            clearable
          />
        </div>
      </div>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-row class="ma-3 pt-0" justify="center" style="min-height: 50vh" v-else>
      <v-col cols="11" v-if="!notFound">
          <v-list flat>
            <template v-for="item in blogs">
              <BlogCard :key="item.blog_id" :blog="item"></BlogCard>
            </template>
          </v-list>
        <v-row class="ma-0" justify="center" align="center">
          <v-pagination
            class="mt-5 mb-5"
            v-model="pageNumber"
            :length="pageLength"
            :total-visible="5"
            @input="fetchData"
          ></v-pagination>
        </v-row>
      </v-col>
      <v-col cols="11" v-else>
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          class="mx-auto mt-5"
        >
          Not Found Any Blog
        </v-alert>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import axios from "axios";
const apiConfig = require("../../api.config");
import Loading from "../components/common/Loading.vue";
import Layout from "../components/layout/Layout.vue";
import PageBar from "../components/PageBar.vue";
import BlogCard from "../components/BlogCard.vue";
export default {
  components: {
    Layout,
    PageBar,
    Loading,
    BlogCard,
  },
  data() {
    return {
      loading: true,
      notFound: true,
      blogs: [],
      pageNumber: 1,
      size: 2,
      pageLength: null,
      orderBy: "Title",
      orderBy_items: ["Title", "Date"],
      orderOption: "DESC",
      orderOption_items: ["DESC", "ASC"],
      selectName: "",
      searchText: null,
    };
  },
  async created() {
    await this.fetchData();
  },
  watch: {
    selectName: function() {
      this.getBlog();
    },
  },
  methods: {
    async fetchData() {
      try {
        let params = {
          page: this.pageNumber,
          size: this.size,
          orderby: this.orderBy,
          orderoption: this.orderOption,
          search_text: this.searchText,
        };
        if (this.selectName === "") delete params["search_text"];
        else {
          params.search_text = this.selectName;
        }
        let atp = await axios.get(apiConfig.routes.blog.allBlog, { params });
        if (atp.data.statusCode == 200) {
          this.blogs = atp.data.result.data;
          this.pageLength = atp.data.result.pageCount;
          this.loading = false;
          console.log(this.blogs);
          if (this.blogs.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getBlog() {
      this.pageNumber = 1;
      await this.fetchData();
    },
  },
};
</script>

<style>
.blog-container {
  background-color: #f2f7ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;
}
</style>
