<template>
  <v-container fluid class="ma-0 pa-0" style="min-height: 60vh">
    <Header title="Research Project" create @createHandler="create()" />
    <v-row
      class="pl-5 body-2 ma-0"
      style="background-color: #f2f2f2"
      align="center"
    >
      <v-col cols="12" md="1" class="pa-0"> Filter By </v-col>
      <v-col cols="12" md="2">
        <v-select
          flat
          solo
          :items="filterItems"
          v-model="filter"
          dense
          hide-details=""
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" class="pa-0"> Order By </v-col>
      <v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          :items="orderByItems"
          v-model="orderBy"
          dense
        ></v-select> </v-col
      ><v-col cols="12" md="2">
        <v-select
          hide-details=""
          flat
          solo
          :items="sortByItems"
          v-model="sortBy"
          dense
        ></v-select>
      </v-col>
      <v-spacer />
      <v-col class="" cols="12" md="2">
        <v-text-field
          hide-details=""
          solo
          flat
          label="Search"
          append-icon="mdi-magnify"
          v-model="search"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- content -->
    <div class="pa-2">
      <div v-for="item in items" :key="item.research2_id">
        <ResearchCard
          :data="item"
          @Remove="setRemoveItem(item)"
          @Edit="item.edit = !item.edit"
          @Save="
            fetchSkill();
            fetchData();
          "
          @Cancle="cancleHandler(item)"
          @onChange="item[$event.from] = $event.value"
          :allSkill="allSkill"
          :publicationItems="publicationItems"
          :userItems="userItems"
          editAble
        />
      </div>
    </div>
    <ConfirmDialog
      :show="dialog"
      title="Delete"
      agree="Delete"
      disagree="Cancle"
      @onDialogChange="dialog = !dialog"
      @cancle="removeItem(false)"
      @confirm="removeItem(true)"
    />
    <div class="text-center mt-5">
      <v-pagination v-model="page" :length="pageLength"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import ResearchCard from "../../card/ResearchCard";
import Header from "../../layout/Header";
import ConfirmDialog from "../../../common/ConfirmDialog.vue";
import axios from "axios";
import api from "../../../../../api.config";
export default {
  components: {
    ResearchCard,
    Header,
    ConfirmDialog,
  },
  watch: {
    page() {
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    filter() {
      this.fetchData();
    },
    orderBy() {
      this.fetchData();
    },
    sortBy() {
      this.fetchData();
    },
  },
  data() {
    return {
      page: 1,
      pageLength: 0,
      dialog: false,
      removeThis: null,
      items: [],
      allSkill: [],
      filterItems: ["All", "FINISHED", "CANCLED", "ONGOING"],
      filter: "All",
      orderByItems: ["Title", "Start_date"],
      orderBy: "Title",
      sortByItems: ["ASC", "DESC"],
      sortBy: "ASC",
      search: "",
      publicationItems: [],
      userItems: [],
    };
  },
  methods: {
    fetchData() {
      let url =
        api.routes.user.get.researchProject + this.$store.state.user.user_id;
      let params = {
        page: this.page,
        size: 6,
        orderby: this.orderBy,
        orderoption: this.sortBy,
        filter: this.filter,
        search_text: this.search,
      };
      if (this.search === "") delete params["search_text"];
      if (this.filter === "All") delete params["filter"];
      axios
        .get(url, {
          params,
        })
        .then((res) => {
          console.log(res.data.result);
          this.items = res.data.result.researchs;
          this.page = res.data.result.currentPage;
          this.pageLength = res.data.result.pageCount;
          if (this.pageLength < this.page) this.page = this.pageLength;
          if (this.pageLength < 1) this.page = 1;
        });
    },
    fetchSkill() {
      let url = api.routes.user.get.skillAll;
      axios.get(url).then((res) => {
        this.allSkill = res.data.result;
      });
    },
    async fetchPublication() {
      let url = api.routes.web.allPublication;
      let data = await axios
        .get(url, {
          params: {
            page: 1,
            size: 999999,
            orderby: "Title",
            orderoption: "ASC",
          },
        })
        .catch((error) => console.log(error));
      console.log(data.data.result.publications);
      this.publicationItems = data.data.result.publications;
    },
    create() {
      this.items.unshift({
        title: "",
        principal_investigator_user_id: 0,
        researcher_user_id: 0,
        research_assistant_user_id: 0,
        start_date: "",
        end_date: "",
        year: "",
        status: "",
        budget: "",
        publication_ids: [],
      });
    },
    setRemoveItem(item) {
      this.removeThis = item;
      this.dialog = true;
    },
    cancleHandler(item) {
      if (item.id === undefined) {
        this.removeThis = item;
        this.removeItem(true);
      }
    },
    removeItem(delOrNot) {
      let data = this.removeThis;
      if (delOrNot) {
        if (data.research2_id !== undefined) {
          const url =
            api.routes.user.delete.researchProject + data.research2_id;
          const headers = {
            authorization: this.$store.state.user.token,
          };
          axios.delete(url, { headers }).then(() => {
            this.fetchData;
          });
        }
        let result = this.items.filter((item) => {
          return item !== data;
        });
        this.items = result;
        this.dialog = false;
      } else {
        this.removeThis = null;
        this.dialog = false;
      }
    },
    async fetchUser() {
      let url = api.routes.web.peopleAll + "/fullname";
      let data = (await axios.get(url)).data;
      if (data.statusCode === 200) {
        this.userItems = data.result;
      }
    },
  },
  mounted() {
    this.fetchData();
    this.fetchSkill();
    this.fetchPublication();
    this.fetchUser();
  },
};
</script>

<style></style>
