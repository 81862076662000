<template>
  <v-container>
    <v-row class="ma-0">
      <h1 class="text-h4">DASHBOARD</h1>
    </v-row>
    <v-container class="pa-0 pb-9 mt-5">
      <v-row class="white" justify="center" align="center">
        <v-col cols="12" md="7">
          <Loading v-if="scholaryGraphLoading" :size="50" />
          <!-- Scholary out put -->
          <bar-chart
            class="chart-wrapper"
            v-if="!scholaryGraphLoading && scholarlyOutPut != null"
            :chartData="scholarlyOutPutData"
            :options="scholarlyOutPutOptions"
          />
          <p
            class="text-body-1 grey--text"
            v-if="scholarlyOutPut === null && !scholaryGraphLoading"
          >
            No Scholaly Output
          </p>
        </v-col>
        <v-col cols="12" md="5">
          <!-- Skill -->
          <radar-chart
            v-if="!skillChartLoading && skills.length !== 0"
            class="chart-wrapper"
            :chartData="skillsData"
            :options="skillsOption"
          />
          <p class="text-body-1 grey--text" v-if="skills.length === 0">
            No Skill Data
          </p>
        </v-col>
      </v-row>
      <v-row class="white mt-5" justify="center" align="center">
        <v-col cols="12" md="6">
          <Loading v-if="topJournalGraphLoading" :size="50" />
          <!-- "Publications In Top Journal Percentiles" -->
          <line-chart
            class="chart-wrapper"
            v-if="
              !topJournalGraphLoading &&
              publicationsInTopJournalPercentiles !== null
            "
            :chartData="topJournalGraphData"
            :options="topJournalGraphOption"
          />
          <p
            class="text-body-1 grey--text"
            v-if="
              publicationsInTopJournalPercentiles === null &&
              !topJournalGraphLoading
            "
          >
            No Top Journal Percentiles Data
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Field Weighted -->
          <Loading v-if="fieldWeightedGraphLoading" :size="50" />
          <line-chart
            class="chart-wrapper"
            v-if="
              !fieldWeightedGraphLoading && fieldWeightedCitationImpact !== null
            "
            :chartData="fieldWeightedGraphData"
            :options="fieldWeightedGraphOption"
          />
          <p
            class="text-body-1 grey--text"
            v-if="
              fieldWeightedCitationImpact === null && !fieldWeightedGraphLoading
            "
          >
            No Field Weighted Citation Impact Data
          </p>
        </v-col>
      </v-row>

      <v-row class="white mt-5" justify="start" align="center">
        <v-col cols="12">
          <!-- citation count -->
          <Loading v-if="citationGraphLoading" :size="50" />
          <line-chart
            v-if="!citationGraphLoading && citationCount !== null"
            class="chart-wrapper"
            :chartData="citationGraphData"
            :options="citationGraphOption"
          />
          <p
            class="text-body-1 grey--text"
            v-if="citationCount === null && !citationGraphLoading"
          >
            No Citation Count Data
          </p>
        </v-col>
      </v-row>

      <v-row class="white mt-5" justify="center" align="center">
        <v-col cols="12" md="3">
          <!-- {{ scivalData.PublicationsInTopJournalPercentiles.values }} -->
          <p>Publications In Top Journal Percentiles</p>
          <Loading v-if="scivalDataLoading" :size="40" />
          <v-simple-table
            v-else-if="
              scivalData.PublicationsInTopJournalPercentiles !== undefined
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Percentiles</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in scivalData.PublicationsInTopJournalPercentiles
                    .values"
                  :key="item.threshold"
                >
                  <td class="text-left">{{ 100 - item.threshold }}</td>
                  <td class="text-left">
                    {{ item.value === null ? "0" : item.value }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="text-body-2 grey--text" v-else>No data</p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center">
          <v-card class="mt-5 mb-5" width="250px" elevation="4">
            <v-row justify="center">
              <v-col cols="6" style="background-color: #2d569e; color: white"
                >Citation Count</v-col
              >
            </v-row>
            <Loading v-if="scivalDataLoading" :size="40" class="my-5" />
            <v-card-text v-else>
              <h2
                v-if="scivalData.CitationCount !== undefined"
                class="mt-2"
                style="text-decoration: underline"
              >
                {{ scivalData.CitationCount.value }}
              </h2>
              <p class="text-body-2 grey--text" v-else>No Data</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center">
          <v-card class="mt-5 mb-5" width="250px" elevation="4">
            <v-row justify="center">
              <v-col cols="6" style="background-color: #2d569e; color: white"
                >Last 5 Year Publication</v-col
              >
            </v-row>
            <Loading v-if="scivalDataLoading" :size="40" class="my-5" />
            <v-card-text v-else>
              <h2
                v-if="scivalData.ScholarlyOutput !== undefined"
                class="mt-2"
                style="text-decoration: underline"
              >
                {{ scivalData.ScholarlyOutput.value }}
                <!-- 90 -->
              </h2>
              <p class="text-body-2 grey--text" v-else>No Data</p>
            </v-card-text>
            <!-- <v-card-actions>
                    <v-btn text color="deep-purple accent-4">
                      Learn More
                    </v-btn>
                  </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center">
          <v-card class="mt-5 mb-5" width="250px" elevation="4">
            <v-row justify="center">
              <v-col cols="8" style="background-color: #2d569e; color: white"
                >Field Weighted Citation Impact</v-col
              >
            </v-row>
            <Loading v-if="scivalDataLoading" :size="40" class="my-5" />
            <v-card-text v-else>
              <h2
                v-if="scivalData.FieldWeightedCitationImpact !== undefined"
                class="mt-2"
                style="text-decoration: underline"
              >
                {{ scivalData.FieldWeightedCitationImpact.value }}
                <!-- 90 -->
              </h2>
              <p class="text-body-2 grey--text" v-else>No Data</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../../../api.config";
import Loading from "../common/Loading";
import BarChart from "../common/chart/barChart.vue";
import LineChart from "../common/chart/lineChart.vue";
import RadarChart from "../common/chart/radarChart.vue";
export default {
  components: {
    Loading,
    BarChart,
    LineChart,
    RadarChart,
  },
  props: {
    skills: Array,
  },
  data() {
    return {
      citationCount: null,
      scholarlyOutPut: null,
      fieldWeightedCitationImpact: null,
      publicationsInTopJournalPercentiles: null,
      scholaryGraphLoading: true,
      citationGraphLoading: true,
      fieldWeightedGraphLoading: true,
      topJournalGraphLoading: true,
      skillChartLoading: true,
      scivalData: {},
      scivalDataLoading: true,
      scholarlyOutPutData: null,
      scholarlyOutPutOptions: null,
      fieldWeightedGraphData: null,
      fieldWeightedGraphOption: null,
      citationGraphData: null,
      citationGraphOption: null,
      skillsData: null,
      skillsOption: null,
    };
  },
  methods: {
    createRadar() {
      try {
        let name = [];
        let value = [];
        this.skills.map((item) => {
          name.push(item.skill_name);
          value.push(item.user_skill.skill_value);
        });

        if (value.length < 6) {
          const range = value.length;
          for (let i = 0; i < 6 - range; i++) {
            name.push("");
            value.push("");
          }
        }

        this.skillsData = {
          labels: name,
          datasets: [
            {
              backgroundColor: "rgba(45, 86, 158,0.2)",
              data: value,
            },
          ],
        };
        this.skillsOption = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Skill",
          },
          legend: {
            display: false,
          },
          scale: {
            ticks: {
              min: 0,
              max: 100,
              stepSize: 25,
            },
          },
        };
        this.skillChartLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    createScholarlyOutPutChart() {
      try {
        // create list for year
        let yearList = Object.keys(this.scholarlyOutPut.valueByYear);
        let dataList = [];
        let maxPublication = 0;
        for (let i = 0; i < yearList.length; i++) {
          let temp = this.scholarlyOutPut.valueByYear[yearList[i]];
          if (temp > maxPublication) maxPublication = temp;
          dataList.push(temp === null ? 0 : temp);
        }

        this.scholarlyOutPutData = {
          labels: yearList,
          datasets: [
            {
              label: "Scholary Output",
              data: dataList,
              backgroundColor: "rgba(45, 86, 158,0.2)",
              lineTension: 0,
            },
          ],
        };
        this.scholarlyOutPutOptions = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Scholary Output",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  max: maxPublication + 1,
                  min: 0,
                  stepSize: 1,
                },
              },
            ],
          },
        };
        this.scholaryGraphLoading = false; //eiei
      } catch (error) {
        console.log(error);
        this.scholaryGraphLoading = false; //eiei
      }
    },
    createCitationCountChart() {
      try {
        // create list for year
        let yearList = Object.keys(this.citationCount.valueByYear);
        let dataList = [];
        let maxPublication = 0;

        for (let i = 0; i < yearList.length; i++) {
          let temp = this.citationCount.valueByYear[yearList[i]];
          if (temp > maxPublication) maxPublication = temp;
          dataList.push(temp === null ? 0 : temp);
        }

        this.citationGraphData = {
          labels: yearList,
          datasets: [
            {
              label: "Citation Count",
              data: dataList,
              backgroundColor: "rgba(45, 86, 158,0.2)",
              lineTension: 0,
            },
          ],
        };
        this.citationGraphOption = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Citation Count",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  max: maxPublication + 2,
                  min: 0,
                  stepSize: 2,
                },
              },
            ],
          },
        };

        this.citationGraphLoading = false; //eiei
      } catch (error) {
        console.log(error);
        this.citationGraphLoading = false; //eiei
      }
    },

    createFieldWeightedChart() {
      try {
        // create list for year
        let yearList = Object.keys(
          this.fieldWeightedCitationImpact.valueByYear
        );
        let dataList = [];
        let maxPublication = 0;

        for (let i = 0; i < yearList.length; i++) {
          let temp = this.fieldWeightedCitationImpact.valueByYear[yearList[i]];
          if (temp > maxPublication) maxPublication = temp;
          dataList.push(temp === null ? 0 : temp);
        }

        this.fieldWeightedGraphData = {
          labels: yearList,
          datasets: [
            {
              label: "Field Weighted Citation",
              data: dataList,
              backgroundColor: "rgba(45, 86, 158,0.2)",
              lineTension: 0,
            },
          ],
        };
        this.fieldWeightedGraphOption = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Field Weighted Citation",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  max: maxPublication + 1,
                  min: 0,
                  stepSize: 1,
                },
              },
            ],
          },
        };
        this.fieldWeightedGraphLoading = false; //eiei
      } catch (error) {
        console.log(error);
        this.fieldWeightedGraphLoading = false; //eiei
      }
    },

    createTopJournalChart() {
      try {
        // create list for year
        const data = this.publicationsInTopJournalPercentiles.values;
        let yearList = Object.keys(data[0].valueByYear);
        let dataList = [];
        let maxPublication = 0;

        for (let i = 0; i < data.length; i++) {
          let temp2 = [];
          for (let j = 0; j < yearList.length; j++) {
            const value = data[i].valueByYear[yearList[j]];
            if (value > maxPublication) maxPublication = value;
            temp2.push(value ? value : 0);
          }
          dataList.push({ data: temp2, threshold: data[i].threshold });
        }
        // const colorlist = ["#2d569e", "#b650a2", "#ff5d69", "#ffa600"];

        const colorlist = [
          "rgba(45, 86, 158,0.5)",
          "rgba(182, 80, 162,0.5)",
          "rgba(255, 93, 105,0.5)",
          "rgba(255, 166, 0,0.5)",
        ];

        let datasets = dataList.map((item, index) => {
          return {
            label: 100 - item.threshold,
            data: item.data,
            borderColor: colorlist[index],
            lineTension: 0,
            fill: false,
          };
        });

        this.topJournalGraphData = {
          labels: yearList,
          datasets: datasets,
        };
        (this.topJournalGraphOption = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Publication Top Journal Percentiles",
          },
          plugins: {
            legend: {
              position: "top",
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  max: maxPublication + 2,
                  min: 0,
                  stepSize: 2,
                },
              },
            ],
          },
        }),
          (this.topJournalGraphLoading = false); //eiei
      } catch (error) {
        console.log(error);
        this.topJournalGraphLoading = false; //eiei
      }
    },

    fetchData() {
      this.createRadar();
      this.fetchScivalByYear();
      this.fetchScival();
    },
    fetchScivalByYear() {
      let url = api.routes.user.get.scival + this.$route.params.userId;
      axios
        .get(url, { params: { byYear: true } })
        .then((res) => {
          if (res.data.statusCode === 200) {
            let data = res.data.result.results[0].metrics;
            data.map((item) => {
              if (item.metricType === "ScholarlyOutput")
                this.scholarlyOutPut = item;
              if (item.metricType === "CitationCount")
                this.citationCount = item;
              if (item.metricType === "FieldWeightedCitationImpact")
                this.fieldWeightedCitationImpact = item;
              if (item.metricType === "PublicationsInTopJournalPercentiles")
                this.publicationsInTopJournalPercentiles = item;
            });
          }
        })
        .finally(() => {
          this.createScholarlyOutPutChart();
          this.createCitationCountChart();
          this.createFieldWeightedChart();
          this.createTopJournalChart();
        });
    },
    fetchScival() {
      let url = api.routes.user.get.scival + this.$route.params.userId;
      axios.get(url, { params: { byYear: false } }).then((res) => {
        if (res.data.statusCode === 200) {
          let result = res.data.result.results[0];
          result.metrics.map((item) => {
            if (item.metricType === "ScholarlyOutput")
              this.scivalData.ScholarlyOutput = item;
            if (item.metricType === "CitationCount")
              this.scivalData.CitationCount = item;
            if (item.metricType === "FieldWeightedCitationImpact")
              this.scivalData.FieldWeightedCitationImpact = item;
            if (item.metricType === "PublicationsInTopJournalPercentiles")
              this.scivalData.PublicationsInTopJournalPercentiles = item;
          });
        }
        this.scivalDataLoading = false; // eiei
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.pubCard {
  width: 80%;
  height: 140px;
  background-color: #c4c4c4;
  margin-top: 25px;
}
.card-content {
  padding: 20px;
}
.loadingHeight {
  height: 80vh;
}
.chart-wrapper {
  position: relative;
  height: 250px;
}
</style>
