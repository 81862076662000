<template>
  <Layout class="container-custom pa-0">
    <Loading class="loadingHeight" v-if="loading" />
    <div v-else class="relative">
      <div class="about">
        <PageBar
          :msg="
            user.firstname_en.toUpperCase() +
              ' ' +
              user.lastname_en.toUpperCase()
          "
        />
      </div>
      <v-tabs centered dark background-color="#2d569e " show-arrows>
        <v-tab v-on:click="page = 0">
          Dashboard
        </v-tab>
        <v-tab v-on:click="page = 1">
          Personal Information
        </v-tab>
        <v-tab v-on:click="page = 2">
          Publication
        </v-tab>
        <v-tab v-on:click="page = 3">
          Senior & Is Project
        </v-tab>
        <v-tab @click="page = 4">
          Master & PHD Project
        </v-tab>
        <v-tab v-on:click="page = 5">
          Funded Project
        </v-tab>
        <v-tab v-on:click="page = 6">
          Personal Blog
        </v-tab>
        <v-tab :to="'/cv/' + $route.params.userId" target="_blank">
          CV
        </v-tab>
      </v-tabs>
      <div class="content">
        <v-row class="pl-3 pr-3 mx-0 mt-5">
          <v-col cols="12" md="3">
            <v-card class="mx-auto mt-5 mb-4 text-left" max-width="400">
              <div class="d-flex justify-center align-center">
                <v-img
                  v-if="user.file !== null"
                  class="white--text align-end"
                  width="100%"
                  :src="user.file.url"
                />
                <v-icon v-else size="200">
                  mdi-account-outline
                </v-icon>
              </div>
              <v-card-title>{{
                user.firstname_en + " " + user.lastname_en
              }}</v-card-title>

              <v-card-text class="text--primary">
                <p class="title">Contact</p>
                <p class="text-subtitle-1">Office Tel</p>
                <p class="text-body-2 pl-5">
                  {{ user.office_tel === null ? "-" : user.office_tel }}
                </p>
                <p class="text-subtitle-1">mobile</p>
                <p class="text-body-2 pl-5">
                  {{ user.mobile === null ? "-" : user.mobile }}
                </p>
                <p class="text-subtitle-1">Email</p>
                <p class="text-body-2 pl-5">
                  {{ user.email === null ? "-" : user.email }}
                </p>
                <!-- change to icon -->
                <a
                  v-if="user.googlescholar_link"
                  class="text-body-2 pl-5 icon"
                  :href="user.googlescholar_link"
                  target="_blank"
                >
                  <img
                    src="../assets/google-scholar.png"
                    alt="google scholar"
                    style="height:32px; width:32px"
                  />
                </a>
                <v-container
                  fluid
                  class="pa-0 ma-0"
                  v-for="item in user.links"
                  :key="item.link_id"
                >
                  <p class="text-subtitle-1">{{ item.link_title }}</p>
                  <a
                    class="text-body-2 pl-5"
                    :href="
                      item.link_url.indexOf('http') > -1 ||
                      item.link_url.indexOf('https') > -1
                        ? item.link_url
                        : '//' + item.link_url
                    "
                    target="_blank"
                  >
                    {{ item.link_url }}</a
                  >
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="9">
            <div v-if="page == 0">
              <Dashboard :skills="user.skills" />
            </div>
            <div v-if="page == 1">
              <Detail :user="user" />
            </div>
            <div v-if="page == 2">
              <Pub :user="user" />
            </div>
            <div v-if="page == 3">
              <Project :user="user"/>
            </div>
            <div v-if="page == 4">
              <Master />
            </div>
            <div v-if="page == 5">
              <Funding />
            </div>
            <div v-if="page == 6">
              <Blog :user="user"/>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </Layout>
</template>

<script>
import PageBar from "../components/PageBar";
import Detail from "../components/profile/Detail";
import Pub from "../components/profile/Pub";
import Project from "../components/profile/Project";
import Dashboard from "../components/profile/Dashboard";
import axios from "axios";
import api from "../../api.config";
import Loading from "../components/common/Loading";
import Funding from "../components/profile/Funding.vue";
import Layout from "../components/layout/Layout.vue";
import Master from "../components/profile/Master.vue";
import Blog from "../components/profile/Blog.vue";
export default {
  components: {
    PageBar,
    Detail,
    Pub,
    Project,
    Dashboard,
    Loading,
    Funding,
    Layout,
    Master,
    Blog,
  },
  data: function() {
    return {
      page: 0,
      user: null,
      loading: true,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let url = api.routes.profile + this.$route.params.userId;
      await axios.get(url).then((item) => {
        this.user = item.data.result;
        this.loading = false;
      });
    },
    viewCV() {
      this.$router.push({
        name: "cv",
        params: { userId: this.$route.params.userId },
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped>
.bar {
  height: 50px;
  background-color: #e5e5e5;
}

.dashboard {
  height: 1020px;
}

.container-custom {
  background-color: #f2f7ff;
  text-align: center;
}

.content {
  width: 100%;
}
.left {
  width: 60%;
  float: left;
  text-align: center;
  height: 50px;
}

.pubCard {
  width: 80%;
  height: 140px;
  background-color: #c4c4c4;
  margin-top: 25px;
}
.card-content {
  padding: 20px;
}

.right {
  width: 40%;
  float: right;
  text-align: center;
}

.barButton {
  max-width: 100px;
}

.barButton:hover {
  cursor: pointer;
  background-color: lightgray;
}

.leftText {
  width: 50%;
  text-align: left;
  color: #ffffff;
  float: left;
}

.rightText {
  width: 50%;
  float: right;
  color: #ffffff;
  text-align: left;
}

.selected {
  background-color: #f2f7ff;
}
.icon {
  &:hover {
    opacity: 0.7;
  }
}
</style>
