<template>
  <v-card
    style="width: 100%"
    :elevation="whiteBG ? '2' : '0'"
    class="ma-2 pa-3"
  >
    <v-row v-bind:class="{ 'whiteBG ': whiteBG, blueBG: !whiteBG }">
      <v-col class="pa-3" cols="3" v-if="showImage"
        ><div style="width: 100%; height: 100%" class="">
          <v-img
            :src="imageSrc"
            :width="imageSize"
            class="mt-4 mx-auto"
          ></v-img></div
      ></v-col>
      <v-col class="pa-0 pl-5" :cols="showImage != true ? 12 : 9">
        <v-card-title v-if="edit === false">{{ title }}</v-card-title>
        <v-form ref="editform" v-model="editvalid" lazy-validation>
          <v-card-title v-if="edit === true">
            <v-col class="pa-0">
              <div class="body-2 grey--text font-weight-regular">
                Title
                <div class="d-inline red--text body-2 font-weight-regular">
                  *
                </div>
              </div>
              <v-text-field
                :rules="rules.titleRules"
                class="mr-10"
                outlined
                v-model="titleVal"
              />
            </v-col>
          </v-card-title>
          <v-card-subtitle v-if="edit === false" class="text-left">{{
            year
          }}</v-card-subtitle>
          <v-card-subtitle v-else>
            <v-col class="pa-0">
              <div class="body-2 grey--text font-weight-regular">
                Year
                <div class="d-inline red--text body-2 font-weight-regular">
                  *
                </div>
              </div>
              <v-select
                class="mr-10"
                outlined
                :items="semesterItems"
                v-model="yearVal"
                :rules="rules.yearRules"
              ></v-select>
            </v-col>
          </v-card-subtitle>
          <v-card-actions v-if="edit === false">
            <v-btn
              v-if="showViewBtn"
              @click="clicked('View')"
              text
              color="primary"
            >
              View </v-btn
            ><v-btn
              v-if="showEditBtn"
              @click="clicked('Edit')"
              text
              color="primary"
            >
              Edit </v-btn
            ><v-btn
              v-if="showDeleteBtn"
              @click="clicked('Remove')"
              text
              color="primary"
            >
              Delete
            </v-btn>
            <v-spacer v-if="showShowInCV" />
            <v-checkbox
              v-if="showShowInCV"
              class="mr-5"
              v-model="showInCV"
              :label="`Put in CV`"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions v-if="edit === true" class="justify-end mr-10 mb-5">
            <v-btn @click="save()" color="green" class="white--text"
              >Save</v-btn
            >
            <v-btn @click="cancle()" color="red" class="white--text"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import rules from "../../../../rules";
export default {
  props: {
    title: String,
    year: String,
    showImage: Boolean,
    imageSrc: String,
    showViewBtn: Boolean,
    showEditBtn: Boolean,
    showDeleteBtn: Boolean,
    showShowInCV: Boolean,
    showInCV: Boolean,
    imageSize: String,
    edit: Boolean,
    whiteBG: Boolean,
  },
  data() {
    return {
      rules,
      editvalid: true,
      titlePrev: this.title,
      yearPrev: this.year,
    };
  },
  computed: {
    semesterItems: {
      get() {
        let year = new Date().getFullYear();
        let arry = [];
        for (let index = year; index > year - 40; index--) {
          arry.push(index.toString());
        }
        return arry;
      },
    },
    titleVal: {
      get() {
        return this.title;
      },
      set(value) {
        this.$emit("onChange", { value, from: "certificate_title" });
      },
    },
    yearVal: {
      get() {
        return this.year;
      },
      set(value) {
        this.$emit("onChange", { value, from: "certificate_year" });
      },
    },
  },
  methods: {
    clicked(clickFrom) {
      if (clickFrom === "Edit") {
        this.titlePrev = this.titleVal;
        this.yearPrev = this.yearVal;
      }
      this.$emit(clickFrom);
    },
    cancle() {
      this.$emit("Cancle", {
        title: this.titlePrev,
        year: this.yearPrev,
      });
    },
    save() {
      if (this.$refs.editform.validate()) {
        this.$emit("Save", {
          title: this.titlePrev,
          year: this.yearPrev,
        });
        this.$refs.editform.resetValidation();
      }
    },
  },
};
</script>

<style scope>
.whiteBG {
  background-color: #ffff;
}
.blueBG {
  background-color: #f2f7ff;
}
</style>
