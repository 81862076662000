<template>
  <v-card class="mt-2 d-flex flex-column" min-height="200" width="100%">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-title v-if="!edit">
        {{ titleVal }}
      </v-card-title>
      <div v-else>
        <v-row class="ma-0 pl-5" justify="start">
          <v-col cols="12" md="10">
            <v-text-field
              v-model="titleVal"
              hide-details=""
              outlined
              dense
              label="Title *"
              class="mt-4"
              :rules="rules.titleRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pl-5" justify="start">
          <v-col cols="12" md="10">
            <v-select
              outlined
              dense
              hide-details=""
              :items="categoryItem"
              label="Category *"
              v-model="typeVal"
              :rules="rules.categorogyRules"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-card-subtitle v-if="!edit" class="d-flex flex-column">
        <span>{{ typeVal }}</span>
        <span>{{ startDateVal + " - " + endDateVal }}</span>
      </v-card-subtitle>
      <v-row v-else class="ma-0 mt-2 pl-5" justify="start">
        <v-col cols="12" md="5">
          <DatePicker
            label="Start Date *"
            refPicker="datepicker1"
            menuRef="menu1"
            :date="startDateVal"
            @onChange="startDateVal = $event.value"
            :rule="[dateRule.requireRule]"
          />
        </v-col>
        <v-col cols="12" md="5">
          <DatePicker
            label="End Date *"
            refPicker="datepicker2"
            menuRef="menu2"
            :date="endDateVal"
            @onChange="endDateVal = $event.value"
            :rule="[dateRule.requireRule, dateRule.moreRule]"
          />
        </v-col>
      </v-row>
      <v-card-text v-if="!edit">
        {{ descriptionVal }}
      </v-card-text>
      <v-row v-else class="ma-0 mt-4 pl-5" justify="start">
        <v-col cols="12" md="10">
          <v-textarea
            outlined
            dense
            hide-details=""
            v-model="descriptionVal"
            label="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row
        class="ma-0"
        v-bind:class="{ 'pl-5': edit }"
        justify="start"
        align="center"
      >
        <v-col
          class="d-flex flex-row justify-start align-center"
          cols="12"
          md="10"
        >
          <span class="mr-3">Put In CV</span>
          <v-checkbox v-model="putInCVVal" />
        </v-col>
      </v-row>
      <v-card-actions v-if="!edit">
        <v-btn text class="blue--text" @click="edit = true">Edit</v-btn>
        <v-btn text class="blue--text" @click="emitHandler('Remove')"
          >Delete</v-btn
        >
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn text class="blue--text" @click="saveHandler">Save</v-btn>
        <v-btn text class="blue--text" @click="cancleHandler">Cancel</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import rules from "../../../../rules";
import DatePicker from "../../common/DatePicker";
import axios from "axios";
const url = require("../../../../api.config").routes.user.create
  .academicService;
export default {
  components: {
    DatePicker,
  },
  props: {
    data: Object,
    categorys: Array,
  },
  data() {
    return {
      rules,
      dateRule: {
        requireRule: (value) => !!value || "Required.",
        moreRule: (value) => this.startDateVal <= value.slice(0, 10),
      },
      valid: true,
      edit: false,
      tempData: JSON.parse(JSON.stringify(this.data)),
    };
  },
  mounted() {
    console.log(this.data);
    if (this.data.academicservice_id === undefined) {
      this.edit = true;
    }
  },
  computed: {
    titleVal: {
      get() {
        return this.data.title;
      },
      set(value) {
        this.onChangeHandler(value, "title");
      },
    },
    typeVal: {
      get() {
        return this.data.category;
      },
      set(value) {
        console.log(value);
        this.onChangeHandler(value, "category");
      },
    },
    startDateVal: {
      get() {
        console.log("test");
        // console.log(this.data.start_data.toString().slice(0, 10));
        return this.data.start_date.slice(0, 10);
      },
      set(value) {
        console.log("set");
        this.onChangeHandler(value, "start_date");
      },
    },
    endDateVal: {
      get() {
        return this.data.end_date.slice(0, 10);
      },
      set(value) {
        this.onChangeHandler(value, "end_date");
      },
    },
    descriptionVal: {
      get() {
        return this.data.description;
      },
      set(value) {
        this.onChangeHandler(value, "description");
      },
    },
    putInCVVal: {
      get() {
        return this.data.putinCV;
      },
      set(value) {
        console.log("test");
        this.onChangeHandler(value, "putinCV");
        if (!this.edit) this.$emit("changePutInCv");
      },
    },
    categoryItem() {
      return this.categorys.filter((item) => item !== "ALL");
    },
  },
  methods: {
    checkdate() {
      if (
        this.startDateVal &&
        this.endDateVal &&
        this.startDateVal <= this.endDateVal
      ) {
        return true;
      } else {
        return false;
      }
    },
    endDateRules() {
      console.log("checking");
      if (this.endDateVal <= this.startDateVal) {
        return true;
      } else {
        return false;
      }
    },
    onChangeHandler(value, from) {
      this.$emit("onChange", { value, from });
    },
    emitHandler(val) {
      console.log(val);
      this.$emit(val);
    },
    async saveHandler() {
      if (this.$refs.form.validate() && this.checkdate()) {
        const headers = {
          authorization: this.$store.state.user.token,
        };
        for (const key of Object.keys(this.data)) {
          if (this.data[key] == "") delete this.data[key];
        }
        if (this.data.academicservice_id === undefined) {
          console.log("aa")
          await axios
            .post(url + this.$store.state.user.user_id, this.data, { headers })
            .then((res) => {
              console.log(res.data);
              this.$emit("Save");
              this.edit = false;
            });
        } else {
          await axios
            .put(
              url + this.data.academicservice_id,
              {
                title: this.data.title,
                description: this.data.description,
                start_date: this.data.start_date,
                end_date: this.data.end_date,
                category: this.data.category,
                putinCV: this.data.putinCV,
              },
              { headers }
            )
            .then(() => {
              this.$emit("Save");
              this.edit = false;
            });
        }
      }
    },
    cancleHandler() {
      if (this.data.academicservice_id === undefined) {
        this.emitHandler("Cancle");
      } else {
        this.onChangeHandler(this.tempData.title, "title");
        this.onChangeHandler(this.tempData.start_date, "start_date");
        this.onChangeHandler(this.tempData.end_date, "end_date");
        this.onChangeHandler(this.tempData.category, "category");
        this.onChangeHandler(this.tempData.description, "description");
        this.edit = false;
      }
    },
  },
};
</script>

<style></style>
