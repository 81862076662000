<template>
  <Layout class="blogdetail">
    <PageBar msg="Blog Detail" />
    <v-row justify="center" class="pt-10 ma-0 pb-15">
      <v-col cols="12" sm="6" class="pa-0">
        <div>
          <v-col>
            <v-row class="text-h4">
              {{ titleBlog }}
            </v-row>
            <v-row>
              <v-card-subtitle class="text-caption pa-1">
                Written By: {{ blog.author.fullname_en }} ({{
                  blog.author.email
                }})
              </v-card-subtitle>
            </v-row>
            <v-row>
              <v-card-subtitle class="text-caption pa-2">
                Latest Updated: {{ updatedAgo }}
              </v-card-subtitle>
            </v-row>
          </v-col>
          <hr class="ma-2" />
          <div class="output ql-snow pa-5">
            <div class="ql-editor" v-html="content"></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import dedent from "dedent";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Layout from "./layout/Layout.vue";
import PageBar from "../components/PageBar";
import axios from "axios";
const apiConfig = require("../../api.config");

export default {
  components: { Layout, PageBar },
  data() {
    return {
      titleBlog: "",
      blog: null,
      content: "",
      updatedAgo: null,
    };
  },
  async created() {
    await this.fetchdata();
  },
  methods: {
    async fetchdata() {
      try {
        let atp = await axios.get(
          apiConfig.routes.blog.eachBlog + this.$route.params.id
        );
        this.blog = atp.data.result;
        this.updatedAgo = atp.data.updated_timeago;
        this.titleBlog = this.blog.title;
        this.content = dedent`${this.blog.content}`;
        if (this.blog.coverImage.url) {
          this.imageBinary = apiConfig.routes.base + this.blog.coverImage.url;
        }
        console.log(atp);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>
