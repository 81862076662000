<template>
  <div>
    <v-sheet class="pa-0" style="position: relative; font-size: 20px">
      <div class="">
        <v-container class="overflow-y-auto pa-0 pb-3" fluid>
          <Header title="Project" />
          <v-row
            class="pl-6 text-body-2 ma-0 flex-column flex-md-row"
            style="background-color: #f2f2f2"
            align="center"
          >
            <v-col cols="12" md="1" class="pa-0">Filter by Type</v-col>
            <v-col cols="12" md="2">
              <v-select
                flat
                solo
                v-model="selectType"
                :items="types"
                hide-details=""
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="pa-0">Filter by Semester</v-col>
            <v-col cols="12" md="2">
              <v-select
                flat
                solo
                v-model="selectSemester"
                :items="semesters"
                hide-details=""
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="pa-0">Filter by Year</v-col>
            <v-col cols="12" md="2">
              <v-select
                flat
                solo
                v-model="selectYear"
                :items="years"
                hide-details=""
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                label="Project Name"
                v-model="selectName"
                :items="searchText"
                hide-details=""
                dense
                clearable
              />
            </v-col>
          </v-row>
          <Loading v-if="pageLoading" class="loadingHeight" />
          <div v-else-if="!pageLoading && pageCount > 0">
            <template v-for="(item, index) in items">
              <ProjectList
                detailcomponent_name="admin_projectdetail_in_account_page"
                :key="index"
                :data="item"
              />
            </template>
            <v-row class="ma-0" justify="center" align="center">
              <v-pagination
                class="mt-5 mb-5"
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                @input="fetchData"
              ></v-pagination>
            </v-row>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center text-h6 grey--text"
            style="height: 40vh"
          >
            No Data
          </div>
        </v-container>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import Header from "../../layout/Header.vue";
import Loading from "../../../common/Loading";
import ProjectList from "../../../ProjectList.vue";
const apiConfig = require("../../../../../api.config");
import axios from "axios";
export default {
  components: {
    Header,
    Loading,
    ProjectList,
  },
  data() {
    return {
      pageLoading: true,
      selectType: "All",
      selectSemester: "All",
      items: [],
      types: [
        "All",
        "Senior Projects",
        "Co-operatives",
        "Independence Studies",
      ],
      semesters: ["All", "1", "2"],
      selectYear: "All",
      selectName: "",
      searchText: null,
      page: 1,
      size: 6,
      pageCount: 0,
    };
  },
  watch: {
    selectType() {
      this.getProject();
    },
    selectYear() {
      this.getProject();
    },
    selectSemester() {
      this.getProject();
    },
    selectName() {
      this.getProject();
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      let result = [];
      console.log(year, "years");
      result = Array.from(
        { length: year - 2012 },
        (value, index) => 2013 + index + 543
      );
      result.push("All");
      return result.reverse();
    },
  },
  methods: {
    async getProject() {
      this.page = 1;
      this.fetchData();
    },
    async fetchUser() {
      let urlFetch =
        apiConfig.routes.profile +
        (this.$route.query.uid === undefined
          ? this.$store.state.user.user_id
          : this.$route.query.uid);
      console.log("urlFetch", urlFetch);
      this.skillKey += 1;
      let atp = (
        await axios.get(urlFetch, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp.statusCode == 200) {
        this.user = atp.result;
      }
    },
    async fetchData() {
      this.pageLoading = true;
      let params = {
        page: this.page,
        size: this.size,
        course: null,
        semester: null,
        year: null,
        supervisorUuid: null,
        project_topic: this.searchText,
        type: null,
      };
      let urlFetch =
        apiConfig.routes.project_supervisor + "/" + this.user.email;
      if (this.selectType == "All") {
        params.type = null;
      } else if (this.selectType == "Senior Projects") {
        params.type = "PJ";
      } else if (this.selectType == "Co-operatives") {
        params.type = "CP";
      } else if (this.selectType == "Independence Studies") {
        params.type = "IS";
      } else {
        params.type = this.selectType;
      }

      // selectSemester
      if (this.selectSemester == "All") {
        params.semester = null;
      } else if (this.selectSemester == "1") {
        params.semester = 1;
      } else if (this.selectSemester == "2") {
        params.semester = 2;
      } else {
        params.semester = this.selectSemester;
      }

      // selectYear
      if (this.selectYear != null) {
        if (this.selectYear == "All") {
          params.year = null;
        } else {
          params.year = this.selectYear;
        }
      }

      // search ProjectName
      if (this.selectName === "") delete params["project_topic"];
      else {
        params.project_topic = this.selectName;
      }
      let atp = await axios.get(urlFetch, { params });
      console.log(atp);
      if (atp.status == 200) {
        console.log(atp.data.result.data);
        this.items = atp.data.result.data;
        this.page = atp.data.result.currentPage;
        this.pageCount = atp.data.result.pageCount;
        if (atp.data.result.data.length == 0) {
          this.pageLoading = false;
        } else {
          this.pageLoading = false;
        }
      }
    },
  },
  async mounted() {
    await this.fetchUser();
    this.fetchData();
  },
};
</script>

<style></style>
