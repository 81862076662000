import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Admin from "../views/Admin.vue";
import Cv from "../views/Cv.vue";
import Account from "../views/Account.vue";
import Lab from "../views/Lab.vue";
import Login from "../views/Login.vue";
import store from "../store/service";
import People from "../views/People.vue";
import LabDetail from "../views/LabDetail.vue";
import AdminPubdetail from "../components/admin/AdminPublicationDetail.vue";
import AdminProjectdetail from "../components/admin/AdminProjectDetail.vue";
import Publication from "../views/Publication.vue";
import PublicationDetail from "../components/PublicationDetail.vue";
import Project from "../views/Project.vue";
import ProjectDetail from "../components/ProjectDetail.vue";
import BlogDetail from "../components/BlogDetail.vue";
import editBlogDetail from "../components/account/editBlogDetail.vue";
import Blog from "../views/Blog.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile/:userId",
    name: "profile",
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { requiresAdmin: true },
  },
  {
    path: "/publication",
    name: "publication",
    component: Publication,
  },
  {
    path: "/project",
    name: "project",
    component: Project,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/projectdetail/:project_id",
    name: "user_projectdetail",
    component: ProjectDetail,
  },
  {
    path: "/admin/projectdetail/:project_id",
    name: "admin_projectdetail",
    component: AdminProjectdetail,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/pubdetail/:publication_id",
    name: "admin_pubdetail",
    component: AdminPubdetail,
    meta: { requiresAdmin: true },
  },
  {
    path: "/pubdetail/:publication_id",
    name: "user_pubdetail",
    component: PublicationDetail,
  },
  {
    path: "/cv/:uid",
    name: "cv",
    component: Cv,
  },
  {
    path: "/account/:component",
    name: "account",
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    redirect: "/account/profile",
  },
  {
    path: "/account/blog/:id",
    name: "EditBlogDetail",
    component: editBlogDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/lab",
    name: "Lab",
    component: Lab,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/people/:userType",
    name: "people",
    component: People,
  },
  {
    path: "/people",
    redirect: "/people/teacher",
  },
  {
    path: "/labdetail/:id",
    name: "LabDetail",
    component: LabDetail,
  },
  {
    path: "/labdetail",
    redirect: "/lab",
  },
  {
    path: "/blog/:id",
    name: "Blog Detail",
    component: BlogDetail,
  },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: require('./views/utility/404').default,
  // },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: '*',
  //   redirect: '404',
  // },
];

// scroll to save position when back
// scroll to top when go to new route
const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            behavior: "smooth",
            offset: savedPosition,
          });
        }, 200);
      });
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history",
});

//  Check Authen before enter route
router.beforeEach(async (to, from, next) => {
  if (!store.getters.authenticated) {
    await store.dispatch("CheckToken");
  }
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    if (store.state.user !== null) {
      next();
      return;
    }
    next("/login");
  } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
    if (store.getters.user !== null && store.state.user.is_admin === true) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
