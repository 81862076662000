<template>
  <v-container class="pt-0" fluid>
    <v-row
      class="pr-5 white--text pl-5 title"
      style="background-color: #296d98; height: 50px; line-height: 50px"
      align="center"
    >
      {{ create ? "Create" : "Detail" }}
      <v-spacer />
      <v-btn v-if="!create" color="white" icon v-on:click="edit = !edit">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <div v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-5">
          <v-col cols="10" class="text-h6">
            <div>
              Title
              <div v-if="create || edit" class="red--text d-inline">*</div>
            </div>
            <p class="text-body-1" v-if="!create && !edit">{{ title }}</p>
            <v-text-field
              class="text-body-1 mr-10"
              :rules="rules.titleRules"
              v-if="create || edit"
              v-model="title"
              placeholder="Title"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- tags-->
        <v-row justify="center">
          <v-col cols="10" class="text-h6">
            Tags
            <div class="d-flex flex-wrap" v-if="!create && !edit">
              <v-chip
                v-for="item in tag"
                :key="item"
                outlined
                color="primary"
                label
                class="mr-2 text-wrap"
                style="height: auto"
              >
                {{ item }}
              </v-chip>
            </div>
            <v-combobox
              class="text-body-1 mr-10"
              v-if="create || edit"
              v-model="tag"
              hide-details=""
              :items="allSkill"
              :search-input.sync="search"
              hide-selected
              placeholder="Tags"
              outlined
              multiple
              persistent-hint
              small-chips
              :rules="[
                checktags(tag) || 'Each tag must be les than 60 characters',
              ]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <!-- Advisor -->
        <v-row justify="center" class="">
          <v-col cols="10" class="text-h6">
            {{
              advisor2 !== "" ? "Advisor 1" : create ? "Advisor 1 " : "Advisor"
            }}
            <div class="red--text d-inline" v-if="create || edit">*</div>
            <p v-if="!create && !edit" class="text-body-1">{{ advisor1 }}</p>
            <v-autocomplete
              v-if="create || edit"
              v-model="advisor1"
              :loading="teacherLoading"
              :items="teacherName"
              :search-input.sync="teacherSearch"
              cache-items
              class="mr-10 text-body-1"
              outlined
              hide-no-data
              hide-details
              label="Find Advisor by name"
              :rules="rules.adviser1Rules"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="advisor2 !== '' || create" justify="center" class="">
          <v-col cols="10" class="text-h6">
            Advisor 2
            <p v-if="!create && !edit" class="text-body-1">
              {{ advisor2 || "-" }}
            </p>
            <v-autocomplete
              v-if="create || edit"
              v-model="advisor2"
              :loading="teacherLoading2"
              :items="teacherName2"
              :search-input.sync="teacherSearch2"
              cache-items
              class="mr-10 text-body-1"
              outlined
              hide-no-data
              hide-details
              label="Find Advisor by name"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" class="">
          <v-col cols="10" class="text-h6">
            <div>
              Student
              <div v-if="create || edit" class="red--text d-inline">*</div>
            </div>
            <p v-if="!create && !edit" class="text-body-1">{{ student }}</p>
            <v-autocomplete
              v-if="create || edit"
              v-model="student"
              :loading="studentLoading"
              :items="studentName"
              :search-input.sync="studentSearch"
              cache-items
              class="mr-10 text-body-1"
              outlined
              hide-no-data
              hide-details
              label="Find Student by name"
              :rules="rules.studentRules"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end">
          <CreateReseacher
            v-if="create || edit"
            :dialog="expand"
            @Close="expand = false"
          />
          <v-btn
            class="blue--text mr-15"
            text
            v-if="(create || edit) && !student"
            @click="expand = true"
          >
            don't have yet?
          </v-btn>
        </v-row>

        <v-row justify="center" class="">
          <v-col cols="10" class="text-h6">
            Abstract
            <p v-if="!create && !edit" class="text-body-1">{{ abstract }}</p>
            <v-textarea
              v-if="create || edit"
              class="text-body-1 mr-10"
              v-model="abstract"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- <v-row justify="center">
      <v-col cols="10" class="body-1">
        Status of project
        <p v-if="!create && !edit">{{ status }}</p>
        <v-select v-model="status" :items="statusItem" />
      </v-col>
    </v-row> -->
        <v-row v-if="create || edit" justify="end">
          <v-btn v-if="create" @click="createNew()" color="primary"
            >Create</v-btn
          >
          <v-btn v-if="edit" @click="saveData()" color="primary">Save</v-btn>
          <v-btn class="mr-5 ml-5" color="error" @click="cancle()"
            >Cancel</v-btn
          >
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import rules from "../../../../../rules";
import axios from "axios";
import apiConfig from "../../../../../api.config";
import CreateReseacher from "../../createReseacher";
import Loading from "../../../common/Loading";
export default {
  components: {
    CreateReseacher,
    Loading,
  },
  data() {
    return {
      valid: true,
      rules,
      loading: true,
      selectComponent: null,
      expand: false,
      create: null,
      title: "",
      tag: [],
      advisor1: "",
      advisor2: "",
      student: "",
      abstract: "",
      search: "",
      teacherLoading: false,
      teacherLoading2: false,
      allSkill: [],
      teacherName: [],
      selectTeacher: null,
      teacherSearchResult: [],
      teacherSearch: "",
      select: "",
      teacherName2: [],
      selectTeacher2: null,
      teacherSearchResult2: [],
      teacherSearch2: "",
      select2: "",
      studentLoading: false,
      studentName: [],
      studentSearch: "",
      studentSelect: "",
      studentSearchResult: [],
      edit: false,
      research_id: null,
      temp: null,
    };
  },
  methods: {
    checktags(tags) {
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          if (tag.length > 60) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    cancle() {
      if (this.selectComponent === "createmaster") {
        this.$router.push({
          name: "account",
          params: {
            component: "master",
          },
        });
      } else if (this.selectComponent === "createphd") {
        this.$router.push({
          name: "account",
          params: {
            component: "phd",
          },
        });
      } else {
        this.edit = false;
        this.fetchData();
      }
    },
    changePage() {
      let component = this.$route.params.component;
      this.selectComponent = component;
      this.create = component === "createmaster" || component === "createphd";
      if (component === "createmaster" || component === "createphd") {
        this.fetchSkill();
        this.autoFillAdvisor();
        this.loading = false;
      } else {
        this.fetchData();
      }
    },
    createNew() {
      console.log(this.studentSearchResult);
      if (this.$refs.form.validate()) {
        let url = apiConfig.routes.user.create.researcher;
        let headers = {
          authorization: this.$store.state.user.token,
        };
        let data = {
          adviser_user_id1: this.teacherSearchResult[0].user_id,
          adviser_user_id2:
            this.teacherSearchResult2.length != 0
              ? this.teacherSearchResult2[0].user_id.toString()
              : null,
          student_user_id:
            this.studentSearchResult.length != 0
              ? this.studentSearchResult[0].student_id
              : null,
          project_topic: this.title,
          project_abstract: this.abstract,
          research_type:
            this.selectComponent === "createmaster" ? "MASTER" : "PHD",
          confirm_status: "APPROVED",
          tags: this.tag,
        };
        if (data.adviser_user_id2 === null) delete data.adviser_user_id2;
        axios.post(url, data, { headers: headers }).then(() => {
          this.$router.go(-1);
        });
      }
    },
    fetchSkill() {
      let _this = this;
      let url = apiConfig.routes.user.get.skillAll;
      axios.get(url).then((item) => {
        _this.allSkill = item.data.result;
      });
    },
    querySelections(v, num) {
      if (num === 1) {
        this.teacherLoading = true;
      } else if (num === 2) {
        this.teacherLoading2 = true;
      } else {
        this.studentLoading = true;
      }
      let url;
      if (num === 1 || num === 2) {
        url = apiConfig.routes.web.peopleByName + "LECTURER";
      } else {
        url = apiConfig.routes.web.peopleByName + "RES_STUDENT";
      }

      axios
        .get(url, { params: { search_text: v } })
        .then((item) => {
          let data = item.data.result;
          let final = data.map((item) => {
            return item.firstname_en + " " + item.lastname_en;
          });
          if (num === 1) {
            this.teacherName = final;
            this.teacherSearchResult = data;
          } else if (num === 2) {
            this.teacherName2 = final;
            this.teacherSearchResult2 = data;
          } else if (num === 3) {
            this.studentName = final;
            this.studentSearchResult = data;
          }
        })
        .finally(() => {
          if (num === 1) {
            this.teacherLoading = false;
          } else if (num === 2) {
            this.teacherLoading2 = false;
          } else {
            this.studentLoading = false;
          }
        });
    },
    autoFillAdvisor() {
      let url = apiConfig.routes.profile + this.$store.state.user.user_id;
      axios.get(url).then((res) => {
        this.teacherSearchResult = [res.data.result];
        this.teacherName = [
          res.data.result.firstname_en + " " + res.data.result.lastname_en,
        ];
        this.advisor1 =
          res.data.result.firstname_en + " " + res.data.result.lastname_en;
      });
    },
    fetchData() {
      this.loading = true;
      let urlGetMaster =
        apiConfig.routes.user.get.researcher + this.$route.query.id;
      let headers = {
        authorization: this.$store.state.user.token,
      };
      axios
        .get(urlGetMaster, { headers: headers })
        .then((res) => {
          let data = res.data.result.research;
          this.research_id = data.research_id;
          this.title = data.project_topic;
          this.tag = data.skills.map((item) => {
            return item.skill_name;
          });
          this.advisor1 = data.adviser_user_id1;
          this.advisor2 = data.adviser_user_id2;
          this.student = data.student_user_id;
          this.abstract = data.project_abstract;
        })
        .then(() => {
          let urlGetAdvisor = apiConfig.routes.profile;
          if (this.advisor1) {
            axios.get(urlGetAdvisor + this.advisor1).then((res) => {
              let data = res.data.result;
              this.advisor1 = data.firstname_en + " " + data.lastname_en;
              this.teacherSearch = data.firstname_en + " " + data.lastname_en;
            });
          }
          if (this.advisor2) {
            axios.get(urlGetAdvisor + this.advisor2).then((res) => {
              let data = res.data.result;
              this.advisor2 = data.firstname_en + " " + data.lastname_en;
              this.teacherSearch2 = data.firstname_en + " " + data.lastname_en;
            });
          }

          let urlGetStudent = apiConfig.routes.studentProfile;
          axios.get(urlGetStudent + this.student).then((res) => {
            let data = res.data.result;
            this.student = data.firstname_en + " " + data.lastname_en;
            this.studentSearch = data.firstname_en + " " + data.lastname_en;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveData() {
      console.log(this.studentSearchResult);
      if (this.$refs.form.validate()) {
        let url =
          apiConfig.routes.user.create.researcher + "/" + this.research_id;
        let headers = {
          authorization: this.$store.state.user.token,
        };
        let data = {
          adviser_user_id1: this.teacherSearchResult[0].user_id,
          student_user_id: this.studentSearchResult[0].student_id,
          project_topic: this.title,
          project_abstract: this.abstract,
          tags: this.tag,
        };
        if (this.teacherSearchResult2[0]) {
          data.adviser_user_id2 = this.teacherSearchResult2[0].user_id;
        }
        axios.put(url, data, { headers: headers }).then(() => {
          this.edit = false;
        });
      }
    },
  },
  created() {
    this.changePage();
    // this.fetchData();
  },
  watch: {
    "$route.params.component": function() {
      this.changePage();
    },
    teacherSearch(val) {
      val && val !== this.select && this.querySelections(val, 1);
      if (
        val === "" ||
        val === null ||
        this.advisor1 === "" ||
        this.advisor1 === null
      ) {
        this.teacherName = [];
        this.teacherSearchResult = [];
      }
    },
    teacherSearch2(val) {
      val && val !== this.select && this.querySelections(val, 2);
      if (
        val === "" ||
        val === null ||
        this.advisor2 === "" ||
        this.advisor2 === null
      ) {
        this.teacherName2 = [];
        this.teacherSearchResult2 = [];
      }
    },
    studentSearch(val) {
      val && val !== this.studentSelect && this.querySelections(val, 3);
      if (
        val === "" ||
        val === null ||
        this.student === "" ||
        this.student === null
      ) {
        this.studentName = [];
        this.studentSearchResult = [];
      }
    },
  },
};
</script>

<style></style>
