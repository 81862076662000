<template>
  <v-card class="mb-2">
    <v-card-title>
      {{ user.firstname_en + " " + user.lastname_en }}
    </v-card-title>
    <v-card-subtitle>
      <p>{{ user.student_id }}</p>
    </v-card-subtitle>
    <v-card-text>
      <p>{{ user.email }}</p>
      <p>{{ user.mobile }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" @click="$emit('View')">View</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    user: Object,
  },
};
</script>

<style></style>
