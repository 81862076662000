var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('ConfirmDialog',{attrs:{"show":_vm.showDialog,"title":"คำเตือน","message":"ยืนยันการลบข้อมูล","agree":"ยืนยัน","disagree":"ยกเลิก"},on:{"confirm":_vm.confirm,"cancle":_vm.cancle}}),_c('v-tabs',[_c('v-tab',{on:{"click":function($event){return _vm.changeTab('LAB')}}},[_vm._v("ห้องปฏิบัติการ")]),_c('v-tab',{on:{"click":function($event){return _vm.changeTab('CREATE')}}},[_vm._v(" + สร้างห้อง")]),(_vm.tab != 'CREATE')?_c('div',{staticClass:"search-box"},[_c('v-text-field',{attrs:{"dense":"","label":"Search","outlined":"","clearable":""},model:{value:(_vm.search_text),callback:function ($$v) {_vm.search_text=$$v},expression:"search_text"}})],1):_vm._e()],1),(_vm.loading)?_c('Loading',{staticClass:"loadingHeight"}):_c('div',[(_vm.tab != 'CREATE' && !_vm.viewing)?_c('div',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_vm._v(" Sort by "),_c('div',{staticClass:"mr-2 ml-2 mt-3",staticStyle:{"width":"100px","height":"50px"}},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.items1},model:{value:(_vm.order1),callback:function ($$v) {_vm.order1=$$v},expression:"order1"}})],1),_c('div',{staticClass:"mr-2 ml-2 mt-3",staticStyle:{"width":"100px","height":"50px"}},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.items2},model:{value:(_vm.order2),callback:function ($$v) {_vm.order2=$$v},expression:"order2"}})],1),_c('v-spacer')],1),_c('v-row',{staticClass:"mx-auto mx-auto",staticStyle:{"min-height":"820px"}},_vm._l((_vm.labList),function(lab){return _c('v-card',{key:lab.lab_id,staticClass:"ml-3 mt-3",class:{
            'black--text': lab.lab_text_color == 'black',
            'white--text': lab.lab_text_color == 'white',
          },attrs:{"color":lab.lab_color,"width":"24%","height":"auto"}},[_c('div',{staticClass:"pa-4"},[_c('v-img',{attrs:{"width":"350px","height":"250px","src":lab.file
                  ? _vm.apiBase + lab.file.url
                  : 'https://www.it.kmitl.ac.th/wp-content/themes/itkmitl2017wp/img/300x200-thumb.png'}})],1),_c('v-responsive',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(lab.lab_title)+" ")]),_c('v-card-subtitle',{class:{
              'black--text': lab.lab_text_color == 'black',
              'white--text': lab.lab_text_color == 'white',
            }},[_vm._v(" สมาชิก "+_vm._s(lab.users.length)+" คน ")]),_c('div',[_c('v-card-text',{staticClass:"text-truncate body-1",class:{
                'black--text': lab.lab_text_color == 'black',
                'white--text': lab.lab_text_color == 'white',
              }},[_vm._v(" "+_vm._s(lab.lab_description || "-")+" ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.targetLabId = lab.lab_id;
                _vm.viewing = true;}}},[_vm._v(" ดูรายละเอียด ")]),_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.openDeleteDialog(lab.lab_id)}}},[_vm._v(" ลบข้อมูล ")])],1)],1)}),1),_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{attrs:{"length":_vm.pageLength},on:{"input":_vm.fetchData},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1):_vm._e(),(_vm.tab == 'CREATE')?_c('div',{staticStyle:{"min-height":"820px"}},[_c('CreateLab')],1):_vm._e(),(_vm.viewing)?_c('div',[_c('Lab',{attrs:{"lab_id":_vm.targetLabId},on:{"e":function($event){_vm.viewing = false}}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }