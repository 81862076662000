<template>
  <v-card
    style="width: 100%"
    :elevation="whiteBG ? '2' : '0'"
    class="ma-2 pa-3"
    id="test"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row v-bind:class="{ 'whiteBG ': whiteBG, blueBG: !whiteBG }">
        <v-col class="pa-3" cols="3" v-if="showImage"
          ><div style="width: 100%; height: 100%" class="">
            <v-img
              :src="imageSrc"
              :width="imageSize"
              class="mt-4 mx-auto"
            ></v-img></div
        ></v-col>
        <v-col class="pa-0 pl-5" :cols="showImage != true ? 12 : 9">
          <v-card-title v-if="edit === false">{{ title }}</v-card-title>
          <v-card-title v-if="edit === true">
            <v-row>
              <v-col class="pa-0">
                <div class="body-2 grey--text font-weight-regular">
                  Title
                  <div
                    class="
                      d-inline
                      red--text
                      body-2
                      font-weight-regular
                    "
                  >
                    *
                  </div>
                </div>
                <v-text-field
                  class="mr-10"
                  outlined
                  v-model="titleVal"
                  :rules="globalRules.titleRules"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle v-if="edit === false" class="text-left">{{
            start.replace(/-/gi, "/") + " - " + end.replace(/-/gi, "/")
          }}</v-card-subtitle>
          <v-card-subtitle v-else>
            <v-row justify="space-around">
              <v-col cols="12" md="5" class="pa-0">
                <div class="body-2 grey--text font-weight-regular">
                  Start Date
                  <div
                    class="
                      d-inline
                      red--text
                      body-2
                      font-weight-regular
                    "
                  >
                    *
                  </div>
                </div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startVal"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="startVal"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="5" class="pa-0">
                <div class="body-2 grey--text font-weight-regular">
                  End Date
                  <div
                    class="
                      d-inline
                      red--text
                      body-2
                      font-weight-regular
                    "
                  >
                    *
                  </div>
                </div>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endVal"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      :rules="rules"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker2"
                    v-model="endVal"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-actions v-if="edit === false">
            <v-btn
              v-if="showViewBtn"
              @click="clicked('View')"
              text
              color="primary"
            >
              View </v-btn
            ><v-btn
              v-if="showEditBtn"
              @click="clicked('Edit')"
              text
              color="primary"
            >
              Edit </v-btn
            ><v-btn
              v-if="showDeleteBtn"
              @click="clicked('Remove')"
              text
              color="primary"
            >
              Delete
            </v-btn>
            <v-spacer v-if="showShowInCV" />
            <v-checkbox
              v-if="showShowInCV"
              class="mr-5"
              v-model="showInCV"
              :label="`Put in CV`"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions v-if="edit === true" class="justify-end mr-10 mb-5">
            <v-btn @click="save()" color="green" class="white--text"
              >Save</v-btn
            >
            <v-btn @click="cancle()" color="red" class="white--text"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import globalRules from "../../../../rules";
export default {
  props: {
    title: String,
    start: String,
    end: String,
    showImage: Boolean,
    imageSrc: String,
    showViewBtn: Boolean,
    showEditBtn: Boolean,
    showDeleteBtn: Boolean,
    showShowInCV: Boolean,
    showInCV: Boolean,
    imageSize: String,
    edit: Boolean,
    whiteBG: Boolean,
  },
  data() {
    return {
      globalRules,
      titlePrev: this.title,
      startPrev: this.start,
      endPrev: this.end,
      menu: false,
      menu2: false,
      valid: true,
    };
  },
  // watch: {
  //   menu(val) {
  // val && setTimeout(() => this.$emit("menu"));
  // val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
  // },
  // menu2(val) {
  // val && setTimeout(() => this.$emit("menu2"));
  // val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
  //   },
  // },
  computed: {
    rules() {
      const rules = [];
      const rule = (v) =>
        (!!v && v) >= this.startVal || "End date must be after start date.";
      rules.push(rule);
      return rules;
    },
    titleVal: {
      get() {
        return this.title;
      },
      set(value) {
        this.$emit("onChange", { value, from: "training_title" });
      },
    },
    startVal: {
      get() {
        return this.start;
      },
      set(value) {
        this.$emit("onChange", { value, from: "training_start_date" });
      },
    },
    endVal: {
      get() {
        return this.end;
      },
      set(value) {
        this.$emit("onChange", { value, from: "training_end_date" });
      },
    },
  },
  methods: {
    clicked(clickFrom) {
      if (clickFrom === "Edit") {
        this.titlePrev = this.titleVal;
        this.startPrev = this.startVal;
        this.endPrev = this.endVal;
      }
      this.$emit(clickFrom);
    },
    cancle() {
      this.$emit("Cancle", {
        title: this.titlePrev,
        start: this.startPrev,
        end: this.endPrev,
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("Save", {
          title: this.titlePrev,
          training_start_date: this.startPrev,
          training_end_date: this.endPrev,
        });
      }
    },
    savedate(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style scope>
.whiteBG {
  background-color: #ffff;
}
.blueBG {
  background-color: #f2f7ff;
}
</style>
