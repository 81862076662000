<template>
  <v-container class="pt-0 pa-0" fluid style="min-height: 60vh">
    <v-row
      class="pr-5 white--text pl-5 py-2 title ma-0"
      style="background-color: #296d98;"
      align="center"
    >
      {{
        selectComponent === "seniorproject" ? "Senior Project" : "IS Project"
      }}
      <v-spacer />
      <v-btn
        color="green darken-1"
        class="white--text"
        v-on:click="view('create')"
      >
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        Create
      </v-btn>
    </v-row>
    <v-row
      class="pl-2 body-2 ma-0"
      style="background-color: #f2f2f2;"
      align="center"
    >
      <v-col cols="12" md="1" class="pa-0">
        Filter By
      </v-col>
      <v-col class="my-1" cols="12" md="2">
        <v-select
          flat
          solo
          v-model="filter"
          :items="filterItems"
          hide-details=""
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" class="pa-0">
        Order By
      </v-col>
      <v-col class="my-1" cols="12" md="2">
        <v-select
          flat
          solo
          :items="orderByItems"
          v-model="orderBy"
          hide-details=""
          dense
          class="mt-0"
        ></v-select> </v-col
      ><v-col class="my-1" cols="12" md="2">
        <v-select
          flat
          solo
          v-model="sortBy"
          :items="sortByItems"
          hide-details=""
          dense
          class="mt-0"
        ></v-select>
      </v-col>
      <v-spacer />
      <v-col class="my-1" cols="12" md="2">
        <v-text-field
          solo
          flat
          label="Search"
          append-icon="mdi-magnify"
          hide-details=""
          v-model="search"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <Loading v-if="loading" class="loadingHeight" />
    <v-row
      class="ma-0"
      v-else-if="!loading && project.length > 0"
      v-for="item in project"
      :key="item.seniorproject_id"
    >
      <ProjectCard
        :title="item.project_title"
        :tag="item.tags"
        :abstract="item.project_abstract"
        :edit="false"
        @Edit="item.edit = !item.edit"
        @onChange="item[$event.from] = $event.value"
        @Cancle="cancle($event, item)"
        @View="view('seniordetail', item.seniorproject_id)"
        @Remove="showDialog(item)"
        showViewBtn
        showEditBtn
        showDeleteBtn
      />
    </v-row>

    <div
      v-else
      class="d-flex justify-center align-center mt-16 text-h5 grey--text"
    >
      No Data
    </div>
    <v-row class="ma-0" justify="center" align="center">
      <v-pagination
        class="ma-0 mt-5"
        v-model="page"
        :total-visible="7"
        :length="pageLength"
      ></v-pagination>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialog" v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">
              Are you sure?
            </v-card-title>
            <v-card-text>{{ removeThis.project_title }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="removeItem(false)">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="removeItem(true)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ProjectCard from "../../card/ProjectCard";
import api from "../../../../../api.config";
import axios from "axios";
import Loading from "../../../common/Loading";
export default {
  components: {
    ProjectCard,
    Loading,
  },
  data() {
    return {
      project: [],
      selectComponent: null,
      projectType: "",
      removeThis: null,
      dialog: false,
      loading: true,
      page: 1,
      pageLength: null,
      orderByItems: ["Title", "Semester"],
      orderBy: "Title",
      sortByItems: ["ASC", "DESC"],
      sortBy: "ASC",
      search: "",
      filterItems: ["All", "Done", "Not_done"],
      filter: "All",
    };
  },
  methods: {
    cancle: function(value, item) {
      item.edit = !item.edit;
      item.title = value.title;
      item.tag = value.tag;
      item.abstract = value.abstract;
    },
    view: function(value, id) {
      if (value === "create") {
        this.$router.push({
          name: "account",
          params: {
            component:
              this.$route.params.component === "seniorproject"
                ? "createsenior"
                : this.$route.params.component === "isproject"
                ? "createis"
                : "createis",
          },
        });
      } else {
        this.$router.push({
          name: "account",
          params: { component: value },
          query: { id: id },
        });
      }
    },
    fetchData() {
      this.loading = true;
      const url =
        api.routes.user.get.seniorProjectAll + this.$store.state.user.user_id;
      let params = {
        page: this.page,
        size: 5,
        orderby: this.orderBy,
        orderoption: this.sortBy,
        search_text: this.search,
        filter: this.filter,
        type:
          this.$route.params.component === "seniorproject" ? "PROJECT" : "IS",
      };
      if (this.search === "") delete params["search_text"];
      if (this.filter === "All") delete params["filter"];
      if (this.filterType === "All") delete params["type"];
      axios.get(url, { params }).then((res) => {
        var result = res.data.result.items;
        this.project = result;
        this.pageLength = res.data.result.pageCount;
        if (this.pageLength < this.page) this.page = this.pageLength;
        if (this.pageLength < 1) this.page = 1;
      });
      this.loading = false;
    },
    showDialog(item) {
      this.removeThis = item;
      this.dialog = true;
    },
    removeItem(temp) {
      if (temp) {
        let item = this.removeThis;
        let url = api.routes.user.delete.seniorProject + item.seniorproject_id;
        var headers = {
          authorization: this.$store.state.user.token,
        };
        axios.delete(url, { headers: headers });
        var filtered = this.project.filter(function(value) {
          return value != item;
        });
        this.project = filtered;
      }
      this.dialog = false;
      this.removeThis = null;
    },
    changePage() {
      this.selectComponent = this.$route.params.component;
      this.projectType =
        this.$route.params.component === "seniorproject" ? "PROJECT" : "IS";
      this.fetchData();
    },
  },
  created() {
    this.changePage();
    this.fetchData();
  },
  watch: {
    "$route.params.component": function() {
      this.changePage();
    },
    page() {
      this.fetchData();
    },
    sortBy() {
      this.fetchData();
    },
    orderBy() {
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    filter() {
      this.fetchData();
    },
  },
};
</script>

<style>
.loadingHeight {
  height: 80vh;
}
</style>
