<template>
  <div class="d-flex flex-column pa-0 ma-0">
    <Header title="Researcher"></Header>
    <v-row
      class="pl-5 text-body-2 ma-0 flex-column flex-md-row"
      style="background-color: #f2f2f2"
      align="center"
      justify="end"
    >
      <v-col class="" cols="12" md="4">
        <v-text-field
          hide-details=""
          solo
          flat
          label="Name or ID"
          v-model="search"
          append-icon="mdi-magnify"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex flex-column px-2" style="min-height:30vh">
      <div v-for="item in userFilter" :key="item.user_id" class="py-2">
        <ResearcherCard :user="item" @View="viewResearcherProfile(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../layout/Header.vue";
import ResearcherCard from "../../card/ResearcherCard.vue";
import axios from "axios";
import api from "../../../../../api.config";
export default {
  components: {
    Header,
    ResearcherCard,
  },
  data() {
    return {
      users: [],
      search: "",
    };
  },
  computed: {
    userFilter() {
      console.log("this.users", this.users);
      return this.users.filter((user) => {
        let temp =
          user.firstname_en.toLowerCase() +
          " " +
          user.lastname_en.toLowerCase();
        return (
          temp.includes(this.search.toLowerCase()) ||
          user.student_id.includes(this.search)
        );
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let url =
        api.routes.user.get.researcherPeople + this.$store.state.user.user_id;
      console.log(url);
      axios.get(url).then((res) => {
        console.log(res.data.result);
        this.users = res.data.result;
      });
    },
    viewResearcherProfile(user) {
      console.log(user);
      this.$router.push({
        path: "/account/researcherprofile",
        query: {
          uid: user.user_id,
        },
      });
    },
  },
};
</script>

<style></style>
