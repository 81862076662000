<template>
  <div>
    <div
      v-if="
        becholors.length == 0 && masters.length == 0 && doctorals.length == 0
      "
    >
      <p class="text-body-1 grey--text">No Data</p>
    </div>
    <div v-if="becholors.length > 0">
      <div class="text-h6">Bachelor Degree</div>
      <v-row
        v-for="edu in becholors"
        :key="edu.education_id"
        class="mx-auto mt-5"
      >
        <v-col cols="12" md="8">
          <EducationCard
            :year="edu.graduation_year"
            :title="edu.education_major"
            :subtitle="edu.education_univesity_name"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="masters.length > 0">
      <div class="text-h6 mt-4">Master Degree</div>
      <v-row
        v-for="edu in masters"
        :key="edu.education_id"
        class="mx-auto mt-5"
      >
        <v-col cols="12" md="8">
          <EducationCard
            :year="edu.graduation_year"
            :title="edu.education_major"
            :subtitle="edu.education_univesity_name"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="doctorals.length > 0">
      <div class="text-h6 mt-4">Doctoral Degree</div>
      <v-row
        v-for="edu in masters"
        :key="edu.education_id"
        class="mx-auto mt-5"
      >
        <v-col cols="12" md="8">
          <EducationCard
            :year="edu.graduation_year"
            :title="edu.education_major"
            :subtitle="edu.education_univesity_name"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EducationCard from "../card/EduCard";
export default {
  props: { becholors: Array, masters: Array, doctorals: Array },
  components: {
    EducationCard,
  },
  data: () => ({
    data: null,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      //this.data = educations;
    },
  },
};
</script>
