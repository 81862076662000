<script>
import { Radar } from "vue-chartjs";
export default {
  extends: Radar,
  props: {
    chartData: Object,
    options: Object,
  },
  mounted() {
    console.log("this.chartData", this.chartData);
    this.renderChart(this.chartData, this.options);
  },
};
</script>
