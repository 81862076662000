<template>
  <Layout>
    <PageBar msg="A D M I N" />
    <v-row>
      <v-col class="col-2 pt-3" style="background-color: #f2f2f2">
        <MenuCard v-on:clicked="changePage" />
      </v-col>
      <v-col
        class="col-10 pa-3 pb-0"
        style="background-color: #c6e2ff; width: 90%"
      >
        <div
          class="mx-auto"
          style="min-width: 880px; background-color: #f2f7ff; max-width: 1560px"
        >
          <Publication v-if="page == 'Publication'" />
          <Project v-if="page == 'Project'" />
          <User v-if="page == 'user'" />
          <Lab v-if="page == 'lab'" />
        </div>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from "../components/layout/Layout.vue";
import PageBar from "../components/PageBar.vue";
import MenuCard from "../components/admin/MenuCard.vue";
import User from "../components/admin/page/User.vue";
import Publication from "../components/admin/page/Publication.vue";
import Project from "../components/admin/page/Project.vue";
import Lab from "../components/admin/page/Lab.vue";
export default {
  components: {
    Layout,
    PageBar,
    MenuCard,
    User,
    Lab,
    Publication,
    Project,
  },
  data: () => ({
    page: "Publication",
  }),
  methods: {
    changePage(q) {
      this.page = q;
    },
  },
};
</script>
