import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";
import apiConfig from "../../api.config";
import EnumState from "./enumState";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    apiState: EnumState.INIT,
    authenticated: false,
    user: null,
    admin: {
      viewing: false,
      admin_user_id: null,
    },
  },
  mutations: {
    setApiState(state, apiState) {
      state.apiState = apiState;
    },
    logout(state) {
      state.authenticated = false;
      state.user = null;
    },
    adminStartView(state, user_id) {
      state.admin.viewing = true;
      state.admin.admin_user_id = state.user.user_id;
      state.user.user_id = user_id;
    },
    adminStopView(state) {
      state.admin.viewing = false;
      state.user.user_id = state.admin.admin_user_id;
      state.admin.admin_user_id = null;
    },
    setUser(state, user) {
      state.user = user;
      if (user.token) state.authenticated = true;
      state.apiState = EnumState.SUCESS;
    },
  },
  actions: {
    LogIn({ commit }, { user, ldapCheck }) {
      commit("setApiState", EnumState.LOADING);
      return new Promise((resolve, reject) => {
        let api_url = ldapCheck
          ? apiConfig.routes.login.ldap
          : apiConfig.routes.login.default;
        axios.post(api_url, user).then(
          (res) => {
            if (res.data.statusCode === 200) {
              commit("setUser", res.data.result);
              Vue.prototype.$cookies.set("token", res.data.result.token);
              resolve("Success");
            } else {
              resolve("Error");
              commit("setApiState", EnumState.ERROR);
            }
          },
          (error) => {
            reject(error);
            commit("setApiState", EnumState.ERROR);
          }
        );
      });
    },
    async CheckToken({ commit }) {
      commit("setApiState", EnumState.LOADING);
      if (Vue.prototype.$cookies.isKey("token")) {
        try {
          await axios
            .get(apiConfig.routes.login.checktoken, {
              headers: { authorization: Vue.prototype.$cookies.get("token") },
            })
            .then((res) => {
              let data = res.data;
              if (data.statusCode === 200) {
                commit("setUser", data.result);
              }
            });
        } catch (error) {
          Vue.prototype.$cookies.remove("token");
          commit("setApiState", EnumState.ERROR);
        }
      } else {
        commit("setApiState", EnumState.ERROR);
      }
    },
    LogOut({ commit }) {
      Vue.prototype.$cookies.remove("token");
      commit("logout");
    },
  },
  getters: {
    user: (state) => state.user,
    apiState: (state) => state.apiState,
    authenticated: (state) => !!state.authenticated,
  },
});

export default store;
