<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0">
      <h1 class="pl-0 text-h4">MASTER & PHD PROJECT</h1>
    </v-row>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="5">
        <v-text-field
          hide-details=""
          outlined
          dense
          label="Search"
          v-model="searchValue"
        ></v-text-field>
        <div class="d-flex align-center mt-2">
          <span class="mr-2 text-body-2">Filter By</span>
          <v-select
            hide-details=""
            outlined
            dense
            v-model="filterValue"
            :items="filterItems"
          ></v-select>
        </div>
        <div class="d-flex align-center mt-2">
          <span class="mr-2 text-body-2">Category</span>
          <v-select
            hide-details=""
            outlined
            dense
            v-model="category"
            :items="categoryItems"
          ></v-select>
        </div>
        <div class="d-flex flex-row align-center">
          <span class="text-body-2 mr-1">Order By</span>
          <v-select
            class="mt-2"
            :items="orderByItems"
            :item-text="(v) => v.name"
            :item-value="(v) => v.value"
            v-model="orderBy"
            dense
            label=""
            outlined
            hide-details=""
          />
        </div>
        <div class="d-flex flex-row align-center">
          <span class="text-body-2 mr-1">Sort By</span>
          <v-select
            class="mt-2"
            :items="sortByItems"
            :item-text="(v) => v.name"
            :item-value="(v) => v.value"
            v-model="sortBy"
            dense
            label=""
            outlined
            hide-details=""
          />
        </div>
      </v-col>
    </v-row>
    <Loading v-if="loading" class="loadingHeight" />
    <div v-else>
      <v-row v-if="projects.length === 0" justify="center" class="ma-0">
        <v-col class="" cols="12" md="8">
          <p class="text-body-1 grey--text">
            No Data
          </p>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-for="item in projects" :key="item.seniorproject_id">
        <v-col cols="12" md="8">
          <ProjectCard
            :title="item.project_topic"
            :tag="item.tags"
            :abstract="item.project_abstract"
            :edit="false"
            :background="true"
          />
        </v-col>
      </v-row>
    </div>
    <v-row justify="start" class="ma-0">
      <v-col cols="12" md="8">
        <v-pagination
          class="mt-5"
          v-model="page"
          :total-visible="7"
          :length="pageLength"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectCard from "../account/card/ProjectCard";
import api from "../../../api.config";
import axios from "axios";
import Loading from "../common/Loading";
export default {
  components: {
    ProjectCard,
    Loading,
  },
  data: () => {
    return {
      page: 1,
      projects: null,
      loading: true,
      pageLength: null,
      searchValue: "",
      filterValue: "--",
      filterItems: ["--", "WAIT", "REJECTED", "APPROVED"],
      orderBy: "Title",
      sortBy: "ASC",
      orderByItems: [
        { name: "Title", value: "Title" },
        { name: "Create Date", value: "Create_date" },
      ],
      sortByItems: [
        { name: "A - Z", value: "ASC" },
        { name: "Z - A", value: "DESC" },
      ],
      category: "All",
      categoryItems: ["All", 'PHD', 'MASTER'],
    };
  },
  watch: {
    page() {
      this.fetchData();
    },
    searchValue() {
      this.fetchData();
    },
    filterValue() {
      this.fetchData();
    },
    sortBy() {
      this.fetchData();
    },
    orderBy() {
      this.fetchData();
    },
    category() {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      let url = api.routes.user.get.researcherAll + this.$route.params.userId;
      let params = {
        page: this.page,
        size: 5,
        orderby: this.orderBy,
        orderoption: this.sortBy,
      };
      if (this.searchValue !== "") params.search_text = this.searchValue;
      if (this.filterValue !== "--") params.filter = this.filterValue;
      if (this.category !== "All") params.type = this.category;
      axios.get(url, { params }).then((res) => {
        console.log(res.data);
        this.projects = res.data.result.items;
        this.loading = false;
        this.pageLength = res.data.result.pageCount;
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.loadingHeight {
  height: 60vh;
}
</style>
