<template>
  <v-card class="mx-auto" style="width: 100%" tile>
    <v-list dense>
      <v-subheader class="ml-5">Admin control</v-subheader>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          @click="onClickButton(item.tabName)"
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedTab: "",
    selectedItem: 0,
    items: [
      { text: "Publication", icon: "mdi-home", tabName: "Publication" },
      { text: "Project", icon: "mdi-image", tabName: "Project" },
      { text: "User", icon: "mdi-account", tabName: "user" },
      { text: "Lab", icon: "mdi-book", tabName: "lab" },
    ],
  }),
  methods: {
    onClickButton(b) {
      this.$emit("clicked", b);
    },
  },
};
</script>
