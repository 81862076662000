<template>
  <v-container class="pt-0" fluid>
    <v-row
      class="pr-5 white--text pl-5 title"
      style="background-color: #296d98; height: 50px; line-height: 50px"
      align="center"
    >
      {{ selectComponent === "profile" ? "Profile" : "Researcher Profile" }}
      <v-spacer />
      <v-btn color="white" icon v-on:click="cancle()">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-row>
    <Loading class="loadingHeight" v-show="loading" />
    <div v-show="!loading">
      <!-- Image Section -->
      <div class="d-flex pt-5 align-center my-5">
        <div v-if="edit == false">
          <v-icon v-if="image.url == ''" size="150" class="mx-auto">
            mdi-account-outline
          </v-icon>
          <v-img
            v-if="image.url != ''"
            class="mx-auto"
            :src="image.url"
            style="object-fit: fill"
            width="150px"
            height="150px"
            aspect-ratio="1"
          />
        </div>
        <div v-if="edit == true">
          <div
            v-if="image.url == ''"
            style="
              line-height: 150px;
              margin: auto;
              width: 100%;
              height: 100%;
              border: 5px dashed lightgrey;
              border-radius: 5px;
              color: lightgrey;
              width: 150px;
              height: 150px;
            "
            class="text-center"
          >
            Image
          </div>
          <v-img
            v-if="image.url != ''"
            class="mx-auto"
            :src="image.url"
            style="object-fit: fill"
            width="150px"
            aspect-ratio="1"
          >
          </v-img>
          <v-btn
            color="primary"
            class="text-none my-2"
            rounded
            depressed
            :loading="isSelecting"
            @click="onButtonClick"
          >
            <v-icon left> mdi-cloud-upload </v-icon>
            Upload image
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="handleImage"
          />
        </div>
        <div class="mt-3" v-if="edit"></div>
        <v-col class="text-left">
          <p class="title">
            {{ userData.firstname_en + " " + userData.lastname_en }}
          </p>
        </v-col>
      </div>
      <!-- Information content -->
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- TH Information Section -->
        <v-row>
          <v-col>
            <p class="subtitle1">Account Information (TH)</p>
          </v-col>
        </v-row>
        <hr class="mb-5" />
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Prefix (TH)</span>
          <span class="body-1 graytext" v-if="edit == false">{{
            userData.name_prefix_th === null ? "-" : userData.name_prefix_th
          }}</span>
          <v-text-field
            v-if="edit == true"
            hide-details=""
            :rules="rules.nameTHRules"
            v-model="userData.name_prefix_th"
            outlined
            dense
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">First Name (TH)</span>
          <span class="body-1 graytext" v-if="edit == false">{{
            userData.firstname_th === null ? "-" : userData.firstname_th
          }}</span>
          <v-text-field
            v-if="edit == true"
            hide-details=""
            :rules="rules.nameTHRules"
            v-model="userData.firstname_th"
            outlined
            dense
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Last Name (TH)</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.lastname_th === null ? "-" : userData.lastname_th
          }}</span>
          <v-text-field
            v-if="edit"
            :rules="rules.nameTHRules"
            v-model="userData.lastname_th"
            outlined
            dense
          />
        </v-row>
        <!-- EN Information Section -->
        <v-row>
          <v-col>
            <p class="subtitle1">Account Information</p>
          </v-col>
        </v-row>
        <hr class="mb-5" />
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Prefix</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.name_prefix_en === null ? "-" : userData.name_prefix_en
          }}</span>
          <v-text-field
            :rules="rules.nameENRules"
            hide-details=""
            v-model="userData.name_prefix_en"
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">First Name</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.firstname_en === null ? "-" : userData.firstname_en
          }}</span>
          <v-text-field
            :rules="rules.nameENRules"
            hide-details=""
            v-model="userData.firstname_en"
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Last Name</span>
          <span v-if="!edit" class="body-1 graytext">{{
            userData.lastname_en === null ? "-" : userData.lastname_en
          }}</span>
          <v-text-field
            :rules="rules.nameENRules"
            hide-details=""
            v-model="userData.lastname_en"
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Position</span>
          <span v-if="!edit" class="body-1 graytext">{{
            userData.position === null ? "-" : userData.position
          }}</span>
          <v-text-field
            :rules="rules.positionRules"
            hide-details=""
            v-model="userData.position"
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Scival ID</span>
          <span class="body-1 graytext" v-if="!edit">
            {{ userData.scival_id === null ? "-" : userData.scival_id }}
          </span>
          <v-text-field
            hide-details=""
            dense
            v-model="userData.scival_id"
            outlined
            :rules="rules.scivalIdRules"
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">ORCID</span>
          <span class="body-1 graytext" v-if="!edit">
            {{ userData.orcid === null ? "-" : userData.orcid }}
          </span>
          <v-text-field
            :rules="rules.orcidRules"
            hide-details=""
            v-model="userData.orcid"
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-1">
          <p class="body-1 graytext title-text">Bio</p>
          <p
            class="body-1 graytext"
            style="word-break: break-word; text-indent: 2rem"
            v-if="!edit"
          >
            {{ userData.bio === null ? "-" : userData.bio }}
          </p>
          <v-textarea v-model="userData.bio" hide-details="" outlined v-else />
        </v-row>
        <!-- Contact Information Section -->
        <v-row>
          <v-col>
            <p class="subtitle1">Contact Information</p>
          </v-col>
        </v-row>
        <hr class="mb-5" />
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Office Tel</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.office_tel === null ? "-" : userData.office_tel
          }}</span>
          <v-text-field
            :rules="rules.officeTelRules"
            v-model="userData.office_tel"
            hide-details=""
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Mobile</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.mobile === null ? "-" : userData.mobile
          }}</span>
          <v-text-field
            :rules="rules.mobileRules"
            v-model="userData.mobile"
            hide-details=""
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Email</span>
          <span class="body-1 graytext" v-if="!edit">{{
            userData.email === null ? "-" : userData.email
          }}</span>
          <v-text-field
            :rules="rules.emailRules"
            v-model="userData.email"
            hide-details=""
            outlined
            dense
            v-else
          />
        </v-row>
        <v-row class="px-5 px-md-10 mb-2 align-center">
          <span class="body-1 graytext title-text">Google Scholar Link</span>
          <a
            class="body-1"
            style="word-break: break-all"
            v-if="!edit"
            :href="userData.googlescholar_link"
            >{{
              userData.googlescholar_link === null
                ? "-"
                : userData.googlescholar_link
            }}</a
          >
          <v-text-field
            :rules="rules.urlRules"
            v-model="userData.googlescholar_link"
            hide-details=""
            outlined
            full-width
            dense
            v-else
          />
        </v-row>
      </v-form>

      <!-- show link-->
      <v-form ref="newlinkForm" v-model="valid3" lazy-validation>
        <div class="my-5" v-for="(item, index) in newLink" :key="index">
          <v-row v-if="!edit" class="px-5 px-md-10 mb-2">
            <span class="body-1 graytext title-text">{{
              item.link_title
            }}</span>
            <a
              class="body-1"
              :href="
                item.link_url.indexOf('http') > -1 ||
                item.link_url.indexOf('https') > -1
                  ? item.link_url
                  : '//' + item.link_url
              "
              target="_blank"
              >{{ item.link_url }}</a
            >
          </v-row>
          <v-row v-if="edit" class="px-5 px-md-10 mb-2">
            <span class="text-body-1 graytext title-text" v-if="index === 0"
              >Link</span
            >
            <div class="title-text" v-else></div>
            <div class="d-flex">
              <v-text-field
                class="mr-1"
                :rules="rules.nameRules"
                v-model="item.link_title"
                hide-details=""
                outlined
                dense
              />
              <v-text-field
                class="mx-1"
                :rules="rules.urlStrictRules"
                v-model="item.link_url"
                hide-details=""
                outlined
                dense
              />
              <v-btn icon @click="deleteLink(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
          </v-row>
        </div>
      </v-form>
      <!-- create link -->

      <div v-if="edit == true" class="my-5">
        <v-row class="px-5 px-md-10 align-center">
          <span class="body-1 graytext title-text">Create New Link</span>
          <div class="d-flex">
            <!-- <v-text-field
                class="mr-1"
                :rules="rules.nameRules"
                v-model="newLinkKey"
                hide-details=""
                outlined
                dense
              />
              <v-text-field
                class="mx-1"
                :rules="rules.urlRules"
                v-model="newLinkValue"
                hide-details=""
                outlined
                dense
              /> -->

            <v-btn icon @click="addNewLink()">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <p class="subtitle1">Skills</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <!-- Chart -->
      <v-row v-show="edit != true">
        <v-col class="" cols="6">
          <radar-chart
            v-if="skills.length > 0 && !skillsChartLoading"
            class="chart-wapper"
            :chartData="skillsChartData"
            :options="skillsChartOption"
          />
          <p v-if="skills.length === 0" class="ml-15 body-2 grey--text">
            {{ skillLoading ? "" : "No Data" }}
            <Loading v-if="skillLoading" />
          </p>
          <v-btn
            v-if="skills.length === 0 && !skillLoading"
            color="primary"
            @click="getRecSkill"
            small
          >
            Get Recommend Skills
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="edit == true">
        <v-row v-for="(skill, i) in skillData.skill_array" :key="i">
          <v-col cols="2" class="ml-10 justify-center">
            <p class="body-1 graytext">{{ skill.skill_name }}</p>
          </v-col>
          <v-col cols="4" class="pa-0 pt-1">
            <v-text-field
              :rules="rules.skillValueRules"
              max="100"
              min="1"
              type="number"
              v-model="skillData.skill_array[i].skill_value"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4" class="pa-0 pt-2 pl-3">
            <v-btn @click="deleteSkill(skill)" color="error" fab x-small dark>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="form2" v-model="valid2" lazy-validation>
          <v-row class="mt-2">
            <v-col cols="2" class="ml-10 justify-center">
              <v-combobox
                outlined
                v-model="skillData.new_name"
                :items="
                  items.filter(
                    (e) =>
                      !skillData.skill_array
                        .map((s) => s.skill_name)
                        .includes(e)
                  )
                "
                :rules="rules.skillNameRules"
              ></v-combobox>
            </v-col>
            <v-col cols="4" class="pa-0 pt-1">
              <v-btn
                @click="addskill()"
                small
                class="mx-2 mt-2"
                fab
                dark
                color="indigo"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-row justify="center" class="pr-5 mb-2" v-if="edit == true">
        <v-btn @click="updateToDb()" class="mr-5" color="primary">Save</v-btn>
        <v-btn @click="cancle()" color="red" class="white--text"> Cancel</v-btn>
      </v-row>
    </div>
  </v-container>
</template>

<script>
const apiConfig = require("../../../../../api.config");
const rules = require("../../../../../rules");
import axios from "axios";
import Loading from "../../../common/Loading";
import RadarChart from "../../../common/chart/radarChart.vue";
export default {
  components: {
    Loading,
    RadarChart,
  },
  props: ["user"],
  data() {
    return {
      skillKey: 1,
      rules,
      valid: true,
      valid2: true,
      valid3: true,
      selectedFile: null,
      loading: true,
      skillLoading: false,
      newLink: [],
      skills: [],
      skill_names: [],
      skill_values: [],
      skill_object_array: {},
      isSelecting: false,
      edit: false,
      items: [],
      skillData: {
        skill_array: [],
        new_name: "",
      },
      userData: {
        user_id: "",
        username: "",
        name_prefix_th: "",
        name_prefix_en: "",
        firstname_th: "",
        firstname_en: "",
        lastname_th: "",
        lastname_en: "",
        position: "",
        bio: "",
        mobile: "",
        office_tel: "",
        email: "",
        orcid: "",
        user_type: "",
        is_admin: "",
        createBy: "",
        updateBy: "",
        createdAt: "",
        updatedAt: "",
        googlescholar_link: "",
        scival_id: "",
      },
      image: { url: "" },
      selectComponent: this.$route.params.component,
      skillsChartLoading: true,
    };
  },
  mounted() {
    this.fetchuserdata();
    this.fetchSkill();
    this.selectComponent = this.$route.params.component;
  },
  watch: {
    $route() {
      this.fetchuserdata();
    },
  },
  methods: {
    async getRecSkill() {
      this.skillLoading = true;
      let url = apiConfig.routes.fetchSkill + this.$store.state.user.user_id;
      const headers = {
        authorization: this.$store.state.user.token,
      };
      console.log(headers);
      await axios.put(url, {}, { headers }).then(() => {
        this.fetchuserdata();
      });
    },
    fetchSkill() {
      let _this = this;
      let url = apiConfig.routes.user.get.skillAll;
      axios.get(url).then((item) => {
        _this.items = item.data.result;
      });
    },
    addskill() {
      if (this.$refs.form2.validate() && !!this.skillData.new_name) {
        this.skillData.skill_array.push({
          skill_name: this.skillData.new_name,
          skill_value: 0,
        });
        this.skillData.new_name = "";
        this.$refs.form2.resetValidation();
      }
    },
    deleteLink(val) {
      this.newLink = this.newLink.filter((item) => {
        return item !== val;
      });
    },
    addNewLink() {
        let data = {
          link_title: "",
          link_url: "",
        };
        this.newLink.push(data);
        this.$refs.newlinkForm.resetValidation();
    },
    async deleteSkill(skill) {
      let newlist = this.skillData.skill_array.filter((item) => {
        return item != skill;
      });
      this.skillData.skill_array = newlist;
    },
    async cancle() {
      if (this.edit === true) {
        this.image = { url: "" };
        this.edit = false;
        await this.fetchuserdata();
      } else {
        this.edit = true;
      }
    },
    async fetchuserdata() {
      this.loading = true;
      this.skill_names = [];
      this.skill_values = [];
      this.skills = [];
      this.skill_object_array = [];
      this.skillData.skill_array = [];
      let urlFetch =
        apiConfig.routes.profile +
        (this.$route.query.uid === undefined
          ? this.$store.state.user.user_id
          : this.$route.query.uid);
      console.log("urlFetch", urlFetch);
      this.skillKey += 1;
      let atp = (
        await axios.get(urlFetch, {
          headers: { authorization: this.$store.state.user.token },
        })
      ).data;
      if (atp.statusCode == 200) {
        this.userData = atp.result;
        this.image = atp.result.file || { url: "" };
        this.skills = atp.result.skills;
        this.newLink = atp.result.links;
      }
      for (const key in this.skills) {
        this.skill_names.push(this.skills[key].skill_name);
        this.skill_values.push(this.skills[key].user_skill.skill_value);
        this.skillData.skill_array.push({
          skill_name: this.skills[key].skill_name,
          skill_value: this.skills[key].user_skill.skill_value,
        });
        this.skill_object_array[this.skills[key].skill_name] = this.skills[
          key
        ].user_skill.user_skill_id;
      }
      this.loading = false;
      this.createGraph();
    },
    async updateToDb() {
      if (this.$refs.form.validate() && this.$refs.newlinkForm.validate()) {
        //upload image
        let urlImage =
          apiConfig.routes.image +
          (this.$route.query.uid === undefined
            ? this.$store.state.user.user_id
            : this.$route.query.uid);
        if (this.selectedFile) {
          let formData = new FormData();
          formData.append("file", this.selectedFile);
          let uploadFile = (
            await axios.put(urlImage, formData, {
              headers: {
                authorization: this.$store.state.user.token,
                "Content-Type": "multipart/form-data",
              },
            })
          ).data;
          if (uploadFile.statusCode == 200) {
            //alert("good img");
          } else {
            //alert("bad img");
          }
        }

        // update Link
        let urlLink =
          apiConfig.routes.user.create.link +
          "all/" +
          (this.$route.query.uid === undefined
            ? this.$store.state.user.user_id
            : this.$route.query.uid);
        let urlData = this.newLink.filter((item) => {
          return item.link_title !== "" && item.link_url !== "";
        });
        urlData = urlData.map((item) => {
          return { link_title: item.link_title, link_url: item.link_url };
        });
        console.log(urlData);
        if (urlData && urlData.length > 0) {
          await axios.put(urlLink, urlData, {
            headers: { authorization: this.$store.state.user.token },
          });
        }

        //update skill
        let urlSkill =
          apiConfig.routes.skill +
          (this.$route.query.uid === undefined
            ? this.$store.state.user.user_id
            : this.$route.query.uid);
        await axios.put(urlSkill, this.skillData.skill_array, {
          headers: { authorization: this.$store.state.user.token },
        });

        //update profile
        let urlProfile =
          apiConfig.routes.profile +
          (this.$route.query.uid === undefined
            ? this.$store.state.user.user_id
            : this.$route.query.uid);
        let dataObj = this.userData;
        for (const key of Object.keys(dataObj)) {
          if (
            dataObj[key] == null ||
            [
              "user_id",
              "is_admin",
              "user_type",
              "createBy",
              "updateBy",
              "createdAt",
              "updatedAt",
              "username",
              "lab_id",
              "skills",
              "file",
              "student_id",
              "links",
            ].includes(key)
          ) {
            delete dataObj[key];
          }
        }
        let attempt = (
          await axios.put(urlProfile, dataObj, {
            headers: { authorization: this.$store.state.user.token },
          })
        ).data;
        if (attempt.statusCode == 200) {
          this.edit = false;
          await this.fetchuserdata();
        }
      }
    },
    handleImage(e) {
      this.selectedFile = e.target.files[0];
      this.createBase64Image(e.target.files[0]);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image.url = e.target.result;
        console.log(this.image);
      };
      reader.readAsDataURL(fileObject);
    },
    createGraph() {
      try {
        if (this.skill_names.length < 6) {
          const range = this.skill_names.length;
          for (let i = 0; i < 6 - range; i++) {
            this.skill_names.push("");
            this.skill_values.push("");
          }
        }

        this.skillsChartData = {
          labels: this.skill_names,
          datasets: [
            {
              backgroundColor: "rgba(200,0,0,0.2)",
              data: this.skill_values,
            },
          ],
        };
        this.skillsChartOption = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scale: {
            ticks: {
              min: 0,
              max: 100,
              stepSize: 10,
            },
          },
        };
        this.skillsChartLoading = false;
      } catch {
        // if catch because ctx1 cannot getContext() because change component
      }
    },
  },
};
</script>

<style scoped>
.graytext {
  color: gray;
}
.loadingHeight {
  height: 80vh;
}
.title-text {
  width: 160px;
}
.chart-wapper {
  position: relative;
  height: 300px;
}
</style>
