<template>
  <div style="text-align: left">
    <h1 class="text-h6 text-md-h4">PERSONAL INFORMATION</h1>
    <Loading class="loadingHeight" v-if="loading" />
    <div class="mt-5" v-else>
      <v-row>
        <v-col>
          <p class="text-h5">Account Information (TH)</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row class="ma-0 ml-4">
        <span class="text-subtitle-1 graytext subtitle-text">Prefix</span>
        <span class="body-1 graytext">{{ user.name_prefix_th }}</span>
      </v-row>
      <v-row class="ma-0 ml-4 mt-2">
        <span class="text-subtitle-1 graytext subtitle-text">First Name</span>
        <span class="body-1 graytext">{{ user.firstname_th }}</span>
      </v-row>
      <v-row class="ma-0 ml-4 mt-2">
        <span class="text-subtitle-1 graytext subtitle-text">Last Name</span>
        <span class="body-1 graytext">{{ user.lastname_th }}</span>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-h5">Account Information</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <v-row class="ma-0 ml-4 mt-2">
        <span class="body-1 graytext subtitle-text">Prefix</span>
        <span class="body-1 graytext">{{ user.name_prefix_en }}</span>
      </v-row>
      <v-row class="ma-0 ml-4 mt-2">
        <span class="body-1 graytext subtitle-text">First Name</span>
        <span class="body-1 graytext">{{ user.firstname_en }}</span>
      </v-row>
      <v-row class="ma-0 ml-4 mt-2">
        <span class="body-1 graytext subtitle-text">Last Name</span>
        <span class="body-1 graytext">{{ user.lastname_en }}</span>
      </v-row>
      <v-row class="ma-0 mt-2 ml-4">
        <span class="body-1 graytext subtitle-text">Position</span>
        <span class="body-1 graytext">{{ user.position }}</span>
      </v-row>
      <v-row class="ma-0 mt-2 ml-4">
        <span class="body-1 graytext subtitle-text">Bio</span>
      </v-row>
      <v-row class="ma-0 mt-1 ml-8">
        <v-col cols="12" md="7" class="bio-text">
          <span class="body-1 graytext text-wrap bio-text">
            {{ user.bio }}
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="text-h5">Education</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <div class="mb-10">
        <Education
          :becholors="educationData.bachelor"
          :masters="educationData.master"
          :doctorals="educationData.doctoral"
        />
      </div>
      <v-row>
        <v-col>
          <p class="text-h5">Training</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <div v-if="training && training.length !== 0">
        <v-row v-for="item in training" :key="item.training_id" class="ma-0">
          <v-col cols="12" md="8">
            <TrainingCard
              :whiteBG="true"
              :title="item.training_title"
              :start="moment(item.training_start_date)"
              :end="moment(item.training_end_date)"
              :startDate="item.training_start_date"
              :endDate="item.training_end_date"
            />
          </v-col>
        </v-row>
      </div>
      <p class="grey--text text-body-1" v-else>
        No Data
      </p>
      <v-row>
        <v-col>
          <p class="text-h5">Certificate</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <div v-if="certificate && certificate.length !== 0">
        <v-row
          v-for="item in certificate"
          :key="item.certificate_id"
          class="ma-0"
        >
          <v-col cols="12" md="8">
            <CertificateCard
              :whiteBG="true"
              :title="item.certificate_title"
              :year="item.certificate_year"
            />
          </v-col>
        </v-row>
      </div>
      <p class="grey--text text-body-1" v-else>
        No Data
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Education from "./layout/EduLayout";
const apiConfig = require("../../../api.config");
import Loading from "../common/Loading";
import TrainingCard from "../account/card/TrainingCard";
import CertificateCard from "../account/card/CertificateCard";
import moment from "moment";

export default {
  components: {
    Education,
    Loading,
    TrainingCard,
    CertificateCard,
  },
  props: {
    user: Object,
  },
  data: () => {
    return {
      educationData: {
        bachelor: [],
        master: [],
        doctoral: [],
      },
      training: null,
      loading: true,
      certificate: null,
    };
  },
  async mounted() {
    await this.fetchData();
    // this.fetchTraining();
    // this.fetchCertificate();
  },
  methods: {
    moment(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async fetchData() {
      let urlFetch = apiConfig.routes.user.get.educationAll + this.user.user_id;
      let atp = (await axios.get(urlFetch)).data;
      let educations = atp.result.education;

      for (const edu of educations) {
        switch (edu.education_level) {
          case "bachelor":
            this.educationData.bachelor.push(edu);
            break;
          case "master":
            this.educationData.master.push(edu);
            break;
          case "doctoral":
            this.educationData.doctoral.push(edu);
            break;
          default:
            break;
        }
      }
      this.fetchCertificate();
      this.fetchTraining();
    },
    fetchTraining() {
      let url = apiConfig.routes.user.get.trainingAll + this.user.user_id;
      axios
        .get(url)
        .then((res) => {
          this.training = res.data.result.training;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCertificate() {
      let url = apiConfig.routes.user.get.certificateAll + this.user.user_id;
      axios.get(url).then((res) => {
        this.certificate = res.data.result.certificate;
      });
    },
  },
};
</script>

<style scoped>
.loadingHeight {
  height: 60vh;
}
.subtitle-text {
  width: 110px;
}
.bio-text {
  word-wrap: break-word;
  text-indent: 30px;
}
</style>
