module.exports = {
    categorogyRules: [(v) => !!v || 'Categorogy must be selected'],
    userTypeRules: [
        (v) => !!v || 'User type must be selected',
        (v) =>
            ['LECTURER', 'STAFF', 'RESEARCH STUDENT'].includes(v) ||
            'Please select user type',
    ],
    isAdminRules: [
        (v) => [true, false].includes(v) || 'Please select user type',
    ],
    nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    majorRules: [
        (v) => !!v || 'Major is required',
        (v) => (v && v.length <= 50) || 'Major must be less than 50 characters',
    ],
    universityRules: [
        (v) => !!v || v != '' || 'University is required',
        (v) =>
            (v && v.length <= 100) ||
            'University must be less than 100 characters',
    ],
    budgetRules: [(v) => !!v || 'Budget is required'],
    yearRules: [
        (v) => !!v || 'Year is required',
        (v) => !v || /^\d+$/.test(v) || 'Year numbers must be number only',
    ],
    rStatusRules: [(v) => !!v || 'Status is required'],
    nameTHRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
        (v) =>
            !v ||
            /^[ก-๏. ]+$/.test(v.replaceAll('-', 'a')) ||
            'Must be Thai language.',
    ],
    nameENRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
        (v) =>
            !v ||
            /^[A-Za-z. ]+$/.test(v.replaceAll('-', 'a')) ||
            'Must be English.',
    ],
    usernameRules: [
        (v) => !!v || 'Username is required',
        (v) =>
            (v && v.length <= 20 && v.length >= 6) ||
            'Username must be between 6 to 20 characters',
    ],
    passwordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
            (v && v.length <= 20 && v.length >= 6) ||
            'Password must be between 6 to 20 characters',
    ],
    bioRules: [
        //v => !!v || 'Bio is required',
        //(v) => (v.length <= 1024) || "Bio must less than 1024 characters",
    ],
    descriptionRules: [
        (v) =>
            !v ||
            (v && v.length <= 300) ||
            'Description must less than 300 characters',
    ],
    positionRules: [
        //v => !!v || 'Position is required',
        (v) =>
            !v ||
            (v && v.length <= 50) ||
            'Position must less than 50 characters',
    ],
    labNameRules: [
        (v) => !!v || 'Lab name is required',
        (v) =>
            (v && v.length <= 40) || 'Lab name must be less than 40 characters',
    ],
    officeTelRules: [
        //v => !!v || 'Office Tel. is required',
        (v) =>
            !v ||
            v.length <= 20 ||
            'Office Tel. must be less than 20 characters',
    ],
    mobileRules: [
        //v => !!v || '  is required',
        (v) =>
            !v ||
            v.length <= 20 ||
            'Mobile numbers must be less than 20 characters',
    ],
    emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
            (v && v.length <= 100) || 'E-mail must be less than 100 characters',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    urlRules: [
        (v) => !v || v.length <= 100 || 'Link must less than 100 characters',
        (v) => !v || checkurl(v) || 'Must be valid URL',
    ],
    urlStrictRules: [
        (v) => !!v || 'Url is required',
        (v) => !v || v.length <= 100 || 'Link must less than 100 characters',
        (v) => !v || checkurl(v) || 'Must be valid URL',
    ],
    linkedinUrlRules: [
        (v) => v.length <= 100 || 'Linkedin url must less than 100 characters',
    ],
    facebookUrlRules: [
        (v) => v.length <= 100 || 'Facebook url must less than 100 characters',
    ],
    orcidRules: [
        (v) =>
            !v || v.length <= 100 || 'ORCID must be less than 100 characters',
    ],
    skillNameRules: [
        (v) => v.length <= 100 || 'Skill name must be less than 100 characters',
    ],
    // fix
    skillValueRules: [
        (v) => (!!v && v != 0) || 'Skill value is required',
        (v) => (v <= 100 && v >= 1) || 'Value must be between 1 to 100',
    ],
    titleRules: [
        (v) => !!v || 'Title name is required',
        (v) =>
            (!!v && v.length <= 256) ||
            'Title name must be less than 256 characters',
    ],
    codeRules: [
        (v) =>
            !v ||
            v.length <= 10 ||
            'Student code must be less than 10 characters',
    ],
    scivalIdRules: [
        (v) =>
            !v ||
            v.length <= 100 ||
            'Scival ID must be less than 100 characters',
    ],
    codeStrictRules: [
        (v) => !!v || 'Student code is required',
        (v) =>
            !v ||
            v.length <= 10 ||
            'Student code must be less than 10 characters',
    ],
    studentNameRules: [
        (v) =>
            !v ||
            v.length <= 30 ||
            'Student name must be less than 30 characters',
    ],
    abstractRules: [
        (v) => !!v || 'Abstract is required',
        //(v) => (v && v.length <= 1000) || "Abstract must be less than 1000 characters",
    ],
    adviser1Rules: [(v) => !!v || 'Adviser 1 is required'],
    studentRules: [(v) => !!v || 'Student is required'],
}

let checkurl = (string) => {
    try {
        let url = new URL(string)
        return url.protocol === 'http:' || url.protocol === 'https:'
    } catch (error) {
        return false
    }
}
