import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from './store/service'
import './designs/main.scss'

Vue.use(require('vue-cookies'))
Vue.config.productionTip = false;
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);


new Vue({
  store: store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
