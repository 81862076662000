<template>
  <div>
    <a :href="newUrl + data.id" target="_blank" class="warp">
      <v-list-item class="align-start">
        <!-- @click="view(data) -->
        <v-list-item-content>
          <v-list-item-title
            class="d-flex text-wrap"
            style="color: #296d98"
            v-text="data.detail.topicTh"
          >
          </v-list-item-title>
          <v-list-item-subtitle>
            <p class="ma-0 mt-1">{{ data.detail.topicEn }}</p>
            <p class="ma-0 mt-1">
              {{ isNaN(data.course.year) ? "-" : data.course.year }}
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    detailcomponent_name: String,
  },
  data() {
    return {
      newUrl: null,
    };
  },
  mounted() {
    this.setURL();
  },
  methods: {
    // view(val) {
    //   this.$router.push({
    //     name: this.detailcomponent_name,
    //     params: {
    //       project_id: val.id,
    //     },
    //   });
    // },
    capitalize(word) {
      if (word != null) {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      } else {
        return word;
      }
    },
    setURL() {
      const oldUrl = window.location.href;
      if (this.detailcomponent_name === "user_projectdetail_in_home_page") {
        this.newUrl =
          oldUrl.slice(0, oldUrl.lastIndexOf("/")) + "/projectdetail/";
      }
      if (this.detailcomponent_name === "user_projectdetail_in_profile_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/"));
        this.newUrl =
          this.newUrl.slice(0, this.newUrl.lastIndexOf("/")) +
          "/projectdetail/";
      }
      if (this.detailcomponent_name === "admin_projectdetail_in_admin_page") {
        this.newUrl =
          oldUrl.slice(0, oldUrl.lastIndexOf("/")) + "/admin/projectdetail/";
      }
      if (this.detailcomponent_name === "admin_projectdetail_in_account_page") {
        this.newUrl = oldUrl.slice(0, oldUrl.lastIndexOf("/"));
        this.newUrl =
          this.newUrl.slice(0, this.newUrl.lastIndexOf("/")) +
          "/admin/projectdetail/";
      }
    },
  },
};
</script>

<style>
.warp {
  text-decoration: none;
}
</style>
