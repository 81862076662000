<template>
  <v-container fluid class="pa-0">
    <v-row
      class="pr-5 white--text pl-5 title ma-0"
      style="background-color: #296d98; height: 50px"
      align="center"
      justify="space-between"
    >
      <span>{{ create ? "Create" : "Detail" }}</span>
      <v-btn v-if="!create" @click="editData()" icon class="white--text"
        ><v-icon>mdi-square-edit-outline</v-icon></v-btn
      >
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <div v-else class="pt-5 pl-md-2 ma-0 content">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div
          class="
            ma-0
            px-2
            d-flex
            flex-column flex-sm-row flex-nowrap
            align-baseline
          "
        >
          <div class="title titleText flex-shrink-0">
            Title
            <div v-if="create" class="d-inline red--text">*</div>
          </div>
          <span class="text-body-1" v-if="!create">
            {{ data.title !== null ? data.title : "-" }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.title"
            :rules="rules.titleRules"
          ></v-text-field>
        </div>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <div class="title titleText flex-shrink-0">
            Type
            <div class="d-inline red--text" v-if="create">*</div>
          </div>
          <span class="text-body-1" v-if="!create">
            {{ data.type !== null ? data.type : "-" }}
          </span>
          <v-select
            v-else
            outlined
            v-model="data.type"
            :items="typesItem"
          ></v-select>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="!create && !edit"
        >
          <div class="title titleText flex-shrink-0">
            Author
            <div class="d-inline red--text" v-if="create">*</div>
          </div>
          <v-chip
            v-for="(item, index) in data.author"
            :key="index"
            label
            outlined
            small
            class="text-wrap mr-2 my-1"
            style="height: auto"
            color="primary"
          >
            {{ item }}
          </v-chip>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-else
        >
          <span class="title titleText flex-shrink-0">Author</span>
          <div>
            <v-combobox
              class="mr-2"
              v-model="authorValue"
              outlined
              :items="authorItemCompute"
              :search-input.sync="searchAuthor"
              hide-selected
              label="Authors"
              multiple
              persistent-hint
              color="primary"
              small-chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ searchAuthor }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'CONFERENCE'"
        >
          <span class="title titleText flex-shrink-0">Even title</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.eventitle !== null && data.eventitle !== ""
                ? data.eventitle
                : "-"
            }}
          </span>
          <v-text-field v-else outlined v-model="data.eventitle"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'CONFERENCE'"
        >
          <span class="title titleText flex-shrink-0">Event type</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.eventtype !== null && data.eventtype !== ""
                ? data.eventtype
                : "-"
            }}
          </span>
          <v-text-field v-else outlined v-model="data.eventtype"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'BOOK_SECTION'"
        >
          <span class="title titleText flex-shrink-0">Book Title</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.booktitle !== null && data.booktitle !== ""
                ? data.booktitle
                : "-"
            }}
          </span>
          <v-text-field v-else outlined v-model="data.booktitle"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Year</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.year !== null && data.year !== "" ? data.year : "-" }}
          </span>
          <v-select
            v-else
            outlined
            v-model="data.year"
            :items="yearItem"
          ></v-select>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Status</span>
          <div v-if="!edit">
            <span
              v-bind:class="{
                'green--text': data.status === 'Published',
                'orange--text': data.status === 'In Press',
              }"
              class="ma-0 mr-3 d-flex flex-nowrap align-center"
            >
              <div
                v-bind:class="{
                  green: data.status === 'Published',
                  orange: data.status === 'In Press',
                }"
                style="height: 10px; width: 10px; border-radius: 50%"
                class="mr-1"
              />
              <span class="text-no-wrap text-body-2">{{ data.status }}</span>
            </span>
          </div>
          <v-select
            v-else
            outlined
            v-model="data.status"
            :items="statusItems"
          ></v-select>
        </v-row>

        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'ARTICLE'"
        >
          <span class="title titleText flex-shrink-0">Journal</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.journal !== null && data.journal !== "" ? data.journal : "-"
            }}</span
          >
          <v-text-field v-else outlined v-model="data.journal"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type !== 'CONFERENCE'"
        >
          <span class="title titleText flex-shrink-0">Publisher</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.publisher !== null && data.publisher !== ""
                ? data.publisher
                : "-"
            }}
          </span>
          <v-text-field v-else outlined v-model="data.publisher"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Referred</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.referred !== null && data.referred !== ""
                ? data.referred
                : "-"
            }}
          </span>
          <v-select
            v-else
            v-model="data.referred"
            :items="referredItems"
            outlined
          ></v-select>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Abstract</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.abstract !== null && data.abstract !== ""
                ? data.abstract
                : "-"
            }}
          </span>
          <v-textarea v-else outlined v-model="data.abstract"></v-textarea>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type !== 'BOOK'"
        >
          <span class="title titleText flex-shrink-0">Volume</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.volume !== null && data.volume !== "" ? data.volume : "-" }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.volume"
            type="number"
          ></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'ARTICLE'"
        >
          <span class="title titleText flex-shrink-0">Number</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.number !== null && data.number !== "" ? data.number : "-" }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.number"
            type="number"
          ></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type !== 'BOOK'"
        >
          <span class="title titleText flex-shrink-0">Page from</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.pagefrom !== null && data.pagefrom !== ""
                ? data.pagefrom
                : "-"
            }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.pagefrom"
            type="number"
          ></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type !== 'BOOK'"
        >
          <span class="title titleText flex-shrink-0">Page to</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.pageto !== null && data.pageto !== "" ? data.pageto : "-" }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.pageto"
            type="number"
          ></v-text-field>
        </v-row>
        <div
          class="
            ma-0
            mt-4
            px-2
            flex-nowrap
            d-flex
            flex-column flex-sm-row
            align-baseline
            justify-start
          "
          v-if="data.type !== 'BOOK'"
        >
          <span class="title titleText flex-shrink-0">Doi</span>
          <span class="text-body-1 text-wrap pl-2 pl-sm-0" v-if="!edit">
            {{ data.doi !== null ? data.doi : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.doi"></v-text-field>
        </div>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Lab</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.lab !== null && data.lab !== "" ? data.lab : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.lab"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
        >
          <span class="title titleText flex-shrink-0">Subject</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.subject !== null && data.subject !== "" ? data.subject : "-"
            }}
          </span>
          <v-text-field v-else outlined v-model="data.subject"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="['CONFERENCE', 'BOOK_SECTION'].includes(data.type)"
        >
          <span class="title titleText flex-shrink-0">Place</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.place !== null && data.place !== "" ? data.place : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.place"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="['CONFERENCE', 'BOOK_SECTION'].includes(data.type)"
        >
          <span class="title titleText flex-shrink-0">Date</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.date !== null && data.date !== "" ? data.date : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.date"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'CONFERENCE'"
        >
          <span class="title titleText flex-shrink-0">Series</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.series !== null && data.series !== "" ? data.series : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.series"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'BOOK'"
        >
          <span class="title titleText flex-shrink-0">Editor</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.editor !== null && data.editor !== "" ? data.editor : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.editor"></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="data.type === 'BOOK_SECTION'"
        >
          <span class="title titleText flex-shrink-0">Series Name</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.seriesname !== null && data.seriesname !== ""
                ? data.seriesname
                : "-"
            }}
          </span>
          <v-text-field
            v-else
            outlined
            v-model="data.seriesname"
          ></v-text-field>
        </v-row>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="['BOOK', 'BOOK_SECTION'].includes(data.type)"
        >
          <span class="title titleText flex-shrink-0">Isbn</span>
          <span class="text-body-1" v-if="!edit">
            {{ data.isbn !== null && data.isbn !== "" ? data.isbn : "-" }}
          </span>
          <v-text-field v-else outlined v-model="data.isbn"></v-text-field>
        </v-row>
        <div
          class="
            ma-0
            mt-4
            px-2
            flex-nowrap
            d-flex
            flex-column flex-sm-row
            justify-start
            align-baseline
          "
        >
          <span class="title titleText flex-shrink-0">Tags</span>
          <div v-if="!edit">
            <div v-if="data.tags && data.tags.length > 0">
              <v-chip
                v-for="item in data.tags"
                :key="item"
                outlined
                label
                color="primary"
                class="text-wrap mr-2 my-1"
                style="height: auto"
                >{{ item }}</v-chip
              >
            </div>
            <span v-else class="text-body-1">-</span>
          </div>
          <div
            v-else
            class="d-flex flex-column flex-sm-row align-md-baseline align-start"
          >
            <v-combobox
              class="mr-2"
              v-model="data.tags"
              outlined
              :items="items"
              :search-input.sync="search"
              hide-selected
              label="Tags"
              multiple
              persistent-hint
              color="primary"
              chips
              :rules="[
                checktags(data.tags) ||
                  'Each tag must be les than 60 characters',
              ]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  outlined
                  label
                  color="primary"
                  class="text-wrap"
                  style="height: auto"
                  >{{ data.item }}</v-chip
                >
              </template>
            </v-combobox>
            <Loading v-if="recommendLoading" :size="20" />
            <v-btn
              v-if="!editSkill && !recommendLoading && !create"
              color="primary"
              x-small
              @click="getRecommendTag()"
            >
              Get Recommend Tag
            </v-btn>
          </div>
        </div>
        <v-row
          class="ma-0 mt-4 px-2 flex-md-nowrap flex-column flex-sm-row"
          justify="start"
          align="baseline"
          v-if="!create"
        >
          <span class="title titleText flex-shrink-0">Put in CV</span>
          <span class="text-body-1" v-if="!edit">
            {{
              data.putinCV !== null && data.putinCV !== "" ? data.putinCV : "-"
            }}
          </span>
          <v-checkbox v-else v-model="data.putinCV"></v-checkbox>
        </v-row>
        <v-row justify="end" class="ma-0" v-if="edit">
          <v-btn class="mr-2 white--text" color="red" @click="cancleHandler()"
            >Cancel</v-btn
          >
          <v-btn class="mr-2" color="primary" @click="removeUnused()"
            >Save</v-btn
          >
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../../../../../api.config";
import rules from "../../../../../rules";
import Loading from "../../../common/Loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      rules,
      referredItems: ["Yes", "No"],
      statusItems: ["Published", "In Press"],
      valid: true,
      editSkill: false,
      search: null,
      searchAuthor: "",
      loadingSearch: false,
      items: [],
      typesItem: ["CONFERENCE", "ARTICLE", "BOOK_SECTION", "BOOK"],
      loading: true,
      recommendLoading: false,
      edit: true,
      create: false,
      itemsAuthor: [],
      data: {
        author_user_id: [],
        abstract: null,
        booktitle: null,
        createdAt: null,
        date: null,
        doi: null,
        editor: null,
        eventitle: null,
        eventtype: null,
        isbn: null,
        journal: null,
        lab: null,
        number: null,
        pagefrom: null,
        pageto: null,
        place: null,
        publisher: null,
        putinCV: null,
        referred: null,
        series: null,
        seriesname: null,
        status: null,
        subject: null,
        tags: null,
        title: null,
        type: "CONFERENCE",
        updatedAt: null,
        volume: null,
        year: null,
      },
    };
  },
  computed: {
    yearItem: {
      get() {
        let dateNow = new Date().getFullYear();
        let temp = [];
        for (let i = dateNow; i > dateNow - 50; i--) {
          temp.push(i.toString());
        }
        return temp;
      },
    },
    authorValue: {
      get() {
        let temp = [];
        for (let i = 0; i < this.data.author_user_id.length; i++) {
          const element = this.data.author_user_id[i];
          console.log("element", element);
          if (!isNaN(element)) {
            let dummy = this.itemsAuthor.filter((user) => {
              return element === user.user_id;
            })[0];
            console.log("dummy", dummy);
            temp.push(dummy.firstname_en + " " + dummy.lastname_en);
          } else {
            console.log(element);
            temp.push(element);
          }
        }
        return temp;
      },
      set(value) {
        let temp = [];
        console.log(value);
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          console.log(element);
          let dummy = this.itemsAuthor.filter(
            (user) => element === user.firstname_en + " " + user.lastname_en
          );
          console.log("dummy", dummy);
          if (dummy.length > 0) {
            temp.push(dummy[0].user_id);
          } else {
            temp.push(element);
          }
        }
        console.log(temp);
        this.data.author_user_id = temp;
      },
    },
    authorItemCompute() {
      return this.itemsAuthor.map(
        (item) => item.firstname_en + " " + item.lastname_en
      );
    },
  },
  watch: {
    "$route.params.component"() {
      if (this.$route.params.component === "createpublication")
        this.create = true;
      else this.create = false;
    },
    "data.author_user_id"() {
      console.log(this.data.author_user_id);
      let positionOfOwner = this.data.author_user_id.indexOf(
        this.$store.state.user.user_id
      );
      if (positionOfOwner === -1) {
        this.data.author_user_id.push(this.$store.state.user.user_id);
      }
    },
  },
  created() {
    if (this.$route.params.component === "createpublication") {
      this.create = true;
      this.data.author_user_id.push(this.$store.state.user.user_id);
    } else {
      this.fetchData();
    }
    this.fetchAuthor();
    this.fetchSkill();
  },
  methods: {
    cancleHandler() {
      if (this.create) {
        this.$router.push({
          path: "/account/publication",
        });
      } else {
        this.fetchData();
      }
    },
    fetchSkill() {
      let url = api.routes.user.get.skillAll;
      axios.get(url).then((res) => {
        this.items = res.data.result;
      });
    },
    fetchAuthor() {
      this.loading = true;
      let url = api.routes.web.peopleAll + "/" + "ALL";
      console.log("this.searchAuthor", this.searchAuthor);
      axios
        .get(url, {
          params: {
            orderby: "Name",
            orderoption: "ASC",
            size: 9999,
            page: 1,
            // search_text: this.searchAuthor,
          },
        })
        .then((res) => {
          console.log(res.data.result.rows);
          let data = res.data.result.rows;
          this.itemsAuthor = data;
          this.loading = false;
        });
    },
    checktags(tags) {
      if (tags && tags.length > 0) {
        for (const tag of tags) {
          if (tag.length > 60) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    fetchData() {
      this.loading = true;
      let url = api.routes.user.get.publicationById + this.$route.query.id;
      axios
        .get(url)
        .then((res) => {
          console.log(res.data.result);
          let pub = res.data.result;
          pub["author_user_id"] = [];
          this.data = pub;
        })
        .finally(() => {
          this.loading = false;
          this.edit = false;
        });
    },
    save(value) {
      let headers = {
        authorization: this.$store.state.user.token,
      };
      var url =
        api.routes.user.get.publicationById +
        (this.create ? "addone" : value.publication_id);
      delete value["publication_id"];
      delete value["author"];
      if (this.create) {
        axios.post(url, { ...value }, { headers }).then((res) => {
          console.log(res.data);
          this.$router.push({
            path: "/account/publication",
          });
        });
      } else {
        axios.put(url, { ...value }, { headers }).then((res) => {
          console.log(res.data);
          this.fetchData();
        });
      }
    },
    removeUnused() {
      if (this.$refs.form.validate()) {
        let temp = JSON.parse(JSON.stringify(this.data));
        for (const key in temp) {
          console.log(key);
          if (
            (temp[key] === null ||
              temp[key] === undefined ||
              ["updatedAt", "createdAt", "rank", "title", "type"].includes(
                key
              )) &&
            !this.create
          ) {
            delete temp[key];
          } else if (temp[key] === "" || temp[key] === null) {
            delete temp[key];
          }
          if (
            this.create &&
            ["updatedAt", "createdAt", "putinCV"].includes(key)
          )
            delete temp[key];
        }
        this.save(temp);
      }
    },
    cancle() {
      this.fetchData();
      this.editSkill = false;
    },
    getRecommendTag() {
      this.recommendLoading = true;
      let url = api.routes.user.get.getRecommentTag + this.$route.query.id;
      const headers = {
        authorization: this.$store.state.user.token,
      };
      axios.get(url, { headers }).then((res) => {
        const data = res.data.result.recommended_tags;
        console.log("data", data);
        this.recommendLoading = false;
        data.map((item) => {
          data.tags.indexOf(item) === -1
            ? data.tags.push(item)
            : console.log(`this ${item} already exists`);
        });
      });
    },
    editData() {
      if (this.edit) {
        this.fetchData();
      }
      this.edit = true;
    },
  },
};
</script>

<style scope>
.loadingHeight {
  height: 80vh;
}
.titleText {
  width: 160px;
}
</style>
