<template>
  <v-card
    :elevation="background ? '1' : '0'"
    class="ma-2"
    v-bind:class="{ whiteBackground: background, normal: !background }"
  >
    <v-row>
      <v-col class="pa-3" cols="3" v-if="showImage"
        ><div style="width: 100%; height: 100%" class="">
          <v-img
            :src="imageSrc"
            :width="imageSize"
            class="mt-4 mx-auto"
          ></v-img></div
      ></v-col>
      <v-col class="pa-0 pl-5" :cols="showImage != true ? 12 : 9">
        <v-card-title v-if="edit === false">{{ title }}</v-card-title>
        <v-card-title v-if="edit === true">
          <v-col class="pa-0">
            <p class="body-2 grey--text font-weight-regular mr-10">title</p>
            <v-text-field class="mr-10" outlined v-model="titleVal" />
          </v-col>
        </v-card-title>
        <v-card-subtitle v-if="edit === false" class="text-left">
          <v-row class="pl-3">
            <div v-for="(val, index) in tag" :key="index">
              <v-chip
                outlined
                color="primary"
                class="mr-2 my-1 text-wrap"
                style="height:auto"
                small
                label
                >{{ val }} &nbsp;</v-chip
              >
            </div>
          </v-row>
        </v-card-subtitle>
        <v-card-subtitle v-else>
          <v-col class="pa-0">
            <p class="body-2 grey--text font-weight-regular mr-10">tag</p>
            <v-text-field class="mr-10" outlined v-model="tagVal" />
          </v-col>
        </v-card-subtitle>
        <v-card-text v-if="edit === false" class="text-left">{{
          abstract
        }}</v-card-text>
        <v-card-text v-if="edit === true">
          <v-row>
            <v-col>
              <p class="body-2 grey--text font-weight-regular mr-10">
                abstract
              </p>
              <v-text-field class="mr-10" outlined v-model="abstractVal" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="edit === false && wait === false">
          <v-btn
            v-if="showViewBtn"
            @click="clicked('View')"
            text
            color="primary"
          >
            View
          </v-btn>
          <v-btn
            v-if="showDeleteBtn"
            @click="clicked('Remove')"
            text
            color="primary"
          >
            Delete
          </v-btn>
          <v-spacer v-if="showShowInCV" />
          <v-checkbox
            v-if="showShowInCV"
            class="mr-5"
            v-model="showInCV"
            :label="`Put in CV`"
          ></v-checkbox>
        </v-card-actions>
        <v-card-actions v-if="wait === true">
          <v-btn
            v-if="showViewBtn"
            @click="clicked('View')"
            text
            color="primary"
          >
            View
          </v-btn>
          <v-btn
            v-if="showViewBtn"
            @click="clicked('Accept')"
            text
            color="primary"
          >
            Accept
          </v-btn>
          <v-btn
            v-if="showViewBtn"
            @click="clicked('Deny')"
            text
            color="primary"
          >
            Deny
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="edit === true" class="justify-end mr-10 mb-5">
          <v-btn @click="clicked('Edit')" color="green" class="white--text"
            >Save</v-btn
          >
          <v-btn @click="cancle()" color="red" class="white--text"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    tag: Array,
    abstract: String,
    showImage: Boolean,
    imageSrc: String,
    showViewBtn: Boolean,
    showEditBtn: Boolean,
    showDeleteBtn: Boolean,
    showShowInCV: Boolean,
    showInCV: Boolean,
    imageSize: String,
    edit: Boolean,
    wait: Boolean,
    background: Boolean,
  },
  data() {
    return {
      titlePrev: this.title,
      tagPrev: this.tag,
      abstractPrev: this.abstract,
    };
  },
  computed: {
    titleVal: {
      get() {
        return this.title;
      },
      set(value) {
        this.$emit("onChange", { value, from: "title" });
      },
    },
    tagVal: {
      get() {
        return this.tag;
      },
      set(value) {
        this.$emit("onChange", { value, from: "tag" });
      },
    },
    abstractVal: {
      get() {
        return this.abstract;
      },
      set(value) {
        this.$emit("onChange", { value, from: "abstract" });
      },
    },
  },
  methods: {
    clicked(clickFrom) {
      if (clickFrom === "Edit") {
        this.titlePrev = this.titleVal;
        this.tagPrev = this.tagVal;
        this.abstractPrev = this.abstractVal;
      }
      this.$emit(clickFrom);
    },
    cancle() {
      this.$emit("Cancle", {
        title: this.titlePrev,
        tag: this.tagPrev,
        abstract: this.abstractPrev,
      });
    },
  },
  mounted() {
    console.log(this.tag)
  },
};
</script>

<style>
.normal {
  width: 100%;
  background-color: #f2f7ff !important;
}
.whiteBackground {
  width: 100%;
  background-color: #ffffff !important;
}
</style>
