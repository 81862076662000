<template>
  <v-container fluid class="loadingContainer">
    <v-row class="fullheight" justify="center" align="center">
      <v-progress-circular
        :size="size !== undefined ? size : 70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    size: Number,
  },
};
</script>

<style>
.loadingContainer {
  height: 100%;
}
.fullheight {
  height: 100%;
}
</style>
