<template>
  <div style="width: 100%">
    <ConfirmDialog
      :show="showDialog"
      title="คำเตือน"
      message="ยืนยันการลบข้อมูล"
      agree="ยืนยัน"
      disagree="ยกเลิก"
      @confirm="confirm"
      @cancle="cancle"
    />
    <v-tabs>
      <v-tab @click="changeTab('LAB')">ห้องปฏิบัติการ</v-tab>
      <v-tab @click="changeTab('CREATE')"> + สร้างห้อง</v-tab>
      <div class="search-box" v-if="tab != 'CREATE'">
        <v-text-field
          dense
          v-model="search_text"
          label="Search"
          outlined
          clearable
        ></v-text-field>
      </div>
    </v-tabs>
    <Loading class="loadingHeight" v-if="loading" />
    <div v-else>
      <div v-if="tab != 'CREATE' && !viewing">
        <v-row align="center" class="pa-0 ma-0" justify="center">
          <v-spacer />
          Sort by
          <div class="mr-2 ml-2 mt-3" style="width: 100px; height: 50px">
            <v-select solo dense v-model="order1" :items="items1"></v-select>
          </div>
          <div class="mr-2 ml-2 mt-3" style="width: 100px; height: 50px">
            <v-select solo dense v-model="order2" :items="items2"></v-select>
          </div>
          <v-spacer />
        </v-row>
        <v-row class="mx-auto mx-auto" style="min-height: 820px">
          <v-card
            :color="lab.lab_color"
            width="24%"
            height="auto"
            v-for="lab in labList"
            :key="lab.lab_id"
            class="ml-3 mt-3"
            v-bind:class="{
              'black--text': lab.lab_text_color == 'black',
              'white--text': lab.lab_text_color == 'white',
            }"
          >
            <div class="pa-4">
              <v-img
                width="350px"
                height="250px"
                :src="
                  lab.file
                    ? apiBase + lab.file.url
                    : 'https://www.it.kmitl.ac.th/wp-content/themes/itkmitl2017wp/img/300x200-thumb.png'
                "
              ></v-img>
            </div>

            <v-responsive class="pl-4"> {{ lab.lab_title }} </v-responsive>

            <v-card-subtitle
              v-bind:class="{
                'black--text': lab.lab_text_color == 'black',
                'white--text': lab.lab_text_color == 'white',
              }"
            >
              สมาชิก {{ lab.users.length }} คน
            </v-card-subtitle>
            <div>
              <v-card-text
                class="text-truncate body-1"
                v-bind:class="{
                  'black--text': lab.lab_text_color == 'black',
                  'white--text': lab.lab_text_color == 'white',
                }"
              >
                {{ lab.lab_description || "-" }}
              </v-card-text>
            </div>
            <v-card-actions>
              <v-btn
                depressed
                color="primary"
                @click="
                  targetLabId = lab.lab_id;
                  viewing = true;
                "
              >
                ดูรายละเอียด
              </v-btn>
              <v-btn
                depressed
                color="error"
                @click="openDeleteDialog(lab.lab_id)"
              >
                ลบข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
        <v-container class="max-width">
          <v-pagination
            v-model="pageNumber"
            :length="pageLength"
            @input="fetchData"
          ></v-pagination>
        </v-container>
      </div>

      <div v-if="tab == 'CREATE'" style="min-height: 820px">
        <CreateLab />
      </div>
      <div v-if="viewing">
        <Lab :lab_id="targetLabId" v-on:e="viewing = false" />
      </div>
    </div>
  </div>
</template>

<script>
import Lab from "../Lab";
import CreateLab from "../CreateLab";
import axios from "axios";
const apiConfig = require("../../../../api.config");
import Loading from "../../common/Loading";
import ConfirmDialog from "../../common/ConfirmDialog.vue";

export default {
  components: {
    ConfirmDialog,
    Lab,
    CreateLab,
    Loading,
  },
  data: () => ({
    target_lab_id: null,
    showDialog: false,
    apiBase: apiConfig.routes.base,
    show: {},
    loading: true,
    targetLabId: null,
    tab: "LAB",
    viewing: false,
    pageNumber: 1,
    pageLength: 0,
    search_text: "",
    labList: [],
    order1: "Title",
    order2: "ASC",
    items1: ["Title", "Date"],
    items2: ["ASC", "DESC"],
  }),
  async mounted() {
    await this.fetchData();
  },
  methods: {
    changeTab(tab) {
      this.viewing = false;
      this.tab = tab;
      if (tab == "LAB") {
        this.pageNumber = 1;
        this.fetchData();
      }
    },
    openDeleteDialog(lab_id) {
      this.target_lab_id = lab_id;
      this.showDialog = !this.showDialog;
    },
    confirm() {
      this.deleteLab(this.target_lab_id);
      this.showDialog = !this.showDialog;
    },
    cancle() {
      this.target_lab_id = null;
      this.showDialog = !this.showDialog;
    },
    async expandCard(lab_id) {
      console.log(lab_id);
      this.show[lab_id] = !this.show[lab_id];
      console.log(this.show);
    },
    async deleteLab(lab_id) {
      let atp = await axios.delete(apiConfig.routes.lab.byid + lab_id, {
        headers: { authorization: this.$store.state.user.token },
      });
      if (atp.data.statusCode == 200) {
        await this.fetchData();
      } else {
        await this.fetchData();
      }
    },
    async fetchData() {
      this.loading = true;
      let atp;

      atp = (
        await axios.get(
          apiConfig.routes.lab.all +
            `?page=${this.pageNumber}&size=8&orderby=${
              this.order1
            }&orderoption=${this.order2}${
              this.search_text && this.search_text != ""
                ? "&search_text=" + this.search_text
                : ""
            }`,
          {
            headers: {
              authorization: this.$store.state.user.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
      ).data;

      if (atp.statusCode === 200) {
        this.labList = atp.result.rows;
        this.pageLength = atp.pageCount;
        for (const lab of this.labList) {
          const lab_id = lab.lab_id;
          this.show[lab_id] = false;
        }
      }
      this.loading = false;
    },
  },
  watch: {
    show() {
      console.log(this.show);
    },
    search_text: async function () {
      this.pageNumber = 1;
      await this.fetchData();
    },
    async order1() {
      await this.fetchData();
    },
    async order2() {
      await this.fetchData();
    },
  },
};
</script>

<style scoped>
.search-box {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: white;
  width: 300px;
}

.page-selector {
  position: absolute;
  bottom: -50px;
  width: 80%;
}

.loadingHeight {
  height: 75vh;
}
</style>
