<template>
  <Layout class="pubdetail">
    <PageBar msg="PROJECT DETAIL" />
    <Loading class="loadingHeight" v-if="loading === true" />
    <div v-if="loading === false">
      <v-row justify="center" class="pt-10 ma-0 pb-15">
        <v-col cols="12" sm="6" class="pa-0">
          <v-row justify="start" class="pb-2 ma-0">
            <v-col class="text-h4"> Detail </v-col>
          </v-row>
          <v-simple-table class="pb-4">
            <thead>
              <tr>
                <th class="text-left">Key</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Topic TH</td>
                <td>{{ items.detail.topicTh }}</td>
              </tr>
              <tr>
                <td>Topic EN</td>
                <td>{{ items.detail.topicEn }}</td>
              </tr>
              <tr>
                <td>Program</td>
                <td>{{ items.program.name }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td v-if="items.type === 'PJ'">Senior Project</td>
                <td v-if="items.type === 'CP'">Co-operatives Project</td>
                <td v-if="items.type === 'IS'">Independence Studies Project</td>
              </tr>
              <tr>
                <td>Objective</td>
                <td>{{ items.detail.objective }}</td>
              </tr>
              <tr>
                <td>Process</td>
                <td>{{ items.detail.process }}</td>
              </tr>
              <tr>
                <td>Scope</td>
                <td>{{ items.detail.scope }}</td>
              </tr>
              <tr>
                <td>Benefit</td>
                <td>{{ items.detail.benefit }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Students</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="pb-4">
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Student ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(std, index) in items.students" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ std.name }}</td>
                    <td>{{ std.code }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Supervisors</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="pb-4">
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="items.advisor != null">
                    <td>1</td>
                    <td>
                      {{ items.advisor.fullname_th }} (
                      {{ items.advisor.fullname_en }} )
                    </td>
                    <td>Advisor</td>
                  </tr>
                  <tr v-if="items.co_advisor != null">
                    <td>2</td>
                    <td>
                      {{ items.co_advisor.fullname_th }} (
                      {{ items.co_advisor.fullname_en }} )
                    </td>
                    <td>Co-Advisor</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Documents</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="pb-4">
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(doc, index) in items.documents" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ doc.name }}</td>
                    <td v-if="doc.name === isYoutube">
                      <v-btn :href="doc.path" target="_blank" icon>
                        <v-icon color="red">mdi-youtube</v-icon>
                      </v-btn>
                    </td>
                    <td v-else>
                      <v-btn @click="downloadDocumentFile(doc)" icon>
                        <v-icon color="green">mdi-download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <hr class="mb-5" />
          <v-row justify="center" class="pb-0 ma-0">
            <v-col class="text-h4">Keywords</v-col>
          </v-row>
          <v-row class="pb-0 ma-0" v-show="edit != true">
            <v-col class="overflow-auto">
              <v-chip-group mandatory active-class="white--text">
                <v-chip
                  class="blue darken-3 white--text"
                  v-for="(keyword, index) in items.detail.keywords"
                  :key="index"
                >
                  <span>
                    <v-icon color="white">mdi-tag</v-icon>
                    {{ keyword.skill_name }}
                  </span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import Layout from "./layout/Layout.vue";
import PageBar from "../components/PageBar";
import axios from "axios";
import api from "../../api.config";
import Loading from "../components/common/Loading";
import rules from "../../rules";
import { saveAs } from "file-saver";
export default {
  components: { Layout, PageBar, Loading },
  async mounted() {
    this.fetchData();
  },
  data: () => ({
    rules,
    loading: true,
    notAbstract: false,
    items: [],
    contributors: [],
    isYoutube: "วิดีโอนำเสนอ",
    new_url: null,
  }),
  methods: {
    async fetchData() {
      await axios
        .get(api.routes.project + "/" + this.$route.params.project_id)
        .then((res) => {
          this.items = res.data.result;
          this.loading = false;
          console.log(this.items);
        });
    },
    async downloadDocumentFile(doc) {
      this.new_url = new URL(doc.path);
      this.new_url.port = "";
      console.log(this.new_url);
      return await axios({
        url: this.new_url.href,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        saveAs(new Blob([response.data]), doc.name);
      });
    },
  },
};
</script>

<style>
.home {
  background-color: #e4e3df;
}
.loadingHeight {
  height: 80vh;
}
</style>
