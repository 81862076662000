<template>
  <v-container fluid>
    <Loading
      v-if="
        loading &&
          educationLoading &&
          publicationLoading &&
          trainingLoading &&
          certificateLoading
      "
      class="loadingHeight"
    ></Loading>
    <v-row
      v-if="
        !loading &&
          !educationLoading &&
          !publicationLoading &&
          !trainingLoading &&
          !certificateLoading
      "
      justify="center"
      align-content="center"
      class="grey"
    >
      <div id="printMe">
        <section slot="pdf-content" class="white contrainer">
          <!-- PDF Content Here -->
          <div class="contrainer">
            <!-- New Content -->
            <section class="pdf-item">
              <div class="header-info-contrainer">
                <div class="header-info-warpper">
                  <img
                    :src="imageSrc"
                    class="image-profile my"
                    @error="handleImageError"
                  />
                  <h2 class="my">
                    {{
                      user.firstname_en.toUpperCase() +
                        " " +
                        user.lastname_en.toUpperCase()
                    }}
                  </h2>
                  <hr class="my" />
                  <div class="my">
                    <span class="mx">{{
                      user.email !== null ? `Email: ${user.email}` : ""
                    }}</span>
                    <span class="mx">{{
                      user.office_tel !== null
                        ? `Office Tel: ${user.office_tel}`
                        : ""
                    }}</span>
                  </div>
                </div>
              </div>
            </section>
            <!-- Bio -->
            <div class="content-warpper">
              <template v-if="user.bio">
                <h2 class="title">BIO</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <p class="text-paragraph">{{ user.bio }}</p>
                </div>
              </template>
              <!-- Link -->
              <template v-if="user.links.length > 0">
                <h2 class="title">LINK</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <div
                    class="my"
                    v-for="link in user.links"
                    :key="link.link_id"
                  >
                    <span>{{ `${link.link_title}: ` }}</span>
                    <a
                      :href="
                        link.link_url.indexOf('http') > -1 ||
                        link.link_url.indexOf('https') > -1
                          ? link.link_url
                          : '//' + link.link_url
                      "
                      target="_bank"
                    >
                      {{ link.link_url }}
                    </a>
                  </div>
                </div>
              </template>
              <!-- Education -->
              <template v-if="education.length > 0">
                <h2 class="title">Education</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>Major</th>
                      <th>Degree</th>
                      <th>Year</th>
                    </tr>
                    <tr v-for="item in education" :key="item.education_id">
                      <td>{{ item.education_univesity_name }}</td>
                      <td>{{ item.education_major }}</td>
                      <td>{{ item.education_level }}</td>
                      <td>{{ item.graduation_year }}</td>
                    </tr>
                  </table>
                </div>
              </template>
              <!-- Training -->
              <template v-if="training.length > 0">
                <h2 class="title">Training</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                    <tr v-for="item in training" :key="item.training_id">
                      <td>{{ item.training_title }}</td>
                      <td>{{ item.training_start_date.slice(0, 10) }}</td>
                      <td>{{ item.training_end_date.slice(0, 10) }}</td>
                    </tr>
                  </table>
                </div>
              </template>
              <!-- Certification -->
              <template v-if="certificate.length > 0">
                <h2 class="title">Certification</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Title</th>
                      <th>Year</th>
                    </tr>
                    <tr v-for="item in certificate" :key="item.certificate_id">
                      <td>{{ item.certificate_title }}</td>
                      <td>{{ item.certificate_year }}</td>
                    </tr>
                  </table>
                </div>
              </template>
              <!-- Academic Service -->
              <template v-if="academicService.length > 0">
                <h2 class="title">Academic Service</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                    <tr
                      v-for="item in academicService"
                      :key="item.academicService_id"
                    >
                      <td>{{ item.title }}</td>
                      <td>{{ item.category }}</td>
                      <td>
                        {{ item.start_date.slice(0, 10) }}
                      </td>
                      <td>
                        {{ item.end_date.slice(0, 10) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>
            </div>

            <!-- Funded Project -->
            <div class="content-warpper">
              <template v-if="fundedProject.length > 0">
                <h2 class="title">Funded Project</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Title</th>
                      <th>Bugget</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>

                    <tr
                      v-for="item in fundedProject"
                      :key="item.publication_id"
                    >
                      <td>{{ item.title }}</td>
                      <td>{{ `${item.budget} THB` }}</td>
                      <td>{{ item.start_date.slice(0, 10) }}</td>
                      <td>{{ item.end_date.slice(0, 10) }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </div>

            <!-- Publication -->
            <div class="content-warpper">
              <template v-if="publication.length > 0">
                <h2 class="title">Publication</h2>
                <hr class="sub-title-hr" />
                <div class="content-warpper">
                  <table>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Year</th>
                    </tr>

                    <tr v-for="item in publication" :key="item.publication_id">
                      <td>{{ item.title }}</td>
                      <td v-if="item.type == 'article'">Article</td>
                      <td v-if="item.type == 'book'">Book</td>
                      <td v-if="item.type == 'book_section'">Book Section</td>
                      <td v-if="item.type == 'conference_item'">Conference Item</td>
                      <td >{{item.year}}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </div>
          </div>
        </section>
      </div>
      <v-btn class="no-print ml-5" @click="toggleDailog()" icon>
        <v-icon color="white" size="50px">mdi-file-download-outline</v-icon>
      </v-btn>
    </v-row>
    <v-dialog
      class="no-print"
      v-model="showDialog"
      v-if="showDialog"
      width="500"
    >
      <v-card class="no-print">
        <v-card-title class="text-h6 dialog-header white--text lighten-2">
          Don't forget turn Background Graphics on.
        </v-card-title>

        <v-card-text class="mt-2">
          <p class="text-body-1">
            Click on More settings --> Background graphics.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="printHandler()">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "../../api.config";
import axios from "axios";
import Loading from "../components/common/Loading";
// import '../styles/_cv.css'
export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      education: null,
      loading: true,
      publication: null,
      training: null,
      certificate: null,
      educationLoading: true,
      publicationLoading: true,
      trainingLoading: true,
      certificateLoading: true,
      academicService: null,
      academicLoadiing: true,
      imageError: false,
      fundedProject: [],
      showDialog: false,
    };
  },
  computed: {
    imageSrc() {
      console.log(this.imageError);
      if (this.user.file !== null && !this.imageError)
        return this.user.file.url;
      return require("../assets/no-img.png");
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    toggleDailog() {
      this.showDialog = !this.showDialog;
    },
    async printHandler() {
      await this.toggleDailog();
      window.print();
    },
    handleImageError() {
      this.imageError = true;
    },
    async generateReport() {
      await this.$htmlToPaper("printMe");
    },
    async fetchData() {
      // fetch User Data
      let url = api.routes.profile + this.$route.params.uid;
      await axios.get(url).then((res) => {
        this.user = res.data.result;
        console.log(this.user.firstname_en);
      });

      // fetch Education
      let urlEducation =
        api.routes.user.get.educationAll + this.$route.params.uid;
      await axios.get(urlEducation).then((res) => {
        this.education = res.data.result.education;
        console.log(this.education);
        this.educationLoading = false;
      });
      // fetch Publication
      let publicationUrl =
        api.routes.publication +
        "/author" +
        "/" +
        this.user.firstname_en +
        "," +
        this.user.lastname_en;
      await axios
        .get(publicationUrl, {
          params: { page: 1, size: 9 },
        })
        .then((res) => {
          const data = res.data.result.data;
          this.publication = data.filter((item) => {
            return item.title;
          });
          console.log(this.publication);
          this.publicationLoading = false;
        });

      // fetch funded project
      let urlFunded =
        api.routes.user.get.researchProject + this.$route.params.uid;
      let params = {
        page: 1,
        size: 9999,
        orderby: "Title",
        orderoption: "ASC",
      };
      await axios.get(urlFunded, { params }).then((res) => {
        let result = res.data.result.researchs;
        console.log("result", result);
        this.fundedProject = result;
      });

      // fetch Training
      let urlTraining =
        api.routes.user.get.trainingAll + this.$route.params.uid;
      await axios.get(urlTraining).then((res) => {
        let result = res.data.result.training;
        this.training = result;
        this.trainingLoading = false;
        console.log(this.training);
      });

      // fetch Certificate
      let urlCertificate =
        api.routes.user.get.certificateAll + this.$route.params.uid;
      await axios.get(urlCertificate).then((res) => {
        this.certificate = res.data.result.certificate;
        this.certificateLoading = false;
      });

      // fetch academic service
      let academicServiceUrl =
        api.routes.user.get.academicService + this.$route.params.uid;
      await axios
        .get(academicServiceUrl, {
          params: {
            page: 1,
            size: 9999,
            orderby: "Title",
            orderoption: "ASC",
          },
        })
        .then((res) => {
          let data = res.data.result.events;
          data = data.filter((item) => item.putinCV);
          console.log(res.data.result.events);
          this.academicService = data;
          console.log(this.academicService);
          this.academicLoadiing = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped src="../designs/Page/_cv.scss"></style>
