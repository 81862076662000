<template>
  <v-container
    fluid
    class="ma-0 pa-0 d-flex flex-column justify-space-between"
  >
    <Navbar />
    <slot class="flex-grow-1"/>
    <Footer />
  </v-container>
</template>

<script>
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
export default {
  name: "Layout",
  components: {
    Navbar,
    Footer,
  },
};
</script>
