<template>
  <v-card style="width: 100%;">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-text>
      <span>Graduate Year {{ year }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title", "subtitle", "year"],
};
</script>

<style scoped></style>
