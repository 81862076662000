<template>
  <div style="width: 100%; min-height: 1000px">
    <v-row
      class="ma-0 pa-2 white justify-start justify-sm-center"
      align="center"
    >
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
        >
          Type
        </span>
        <div class="px-3">
          <v-select
            v-model="selectType"
            :items="types"
            v-on:change="getProject"
            label="Select type"
            outlined
            hide-details=""
            dense
          >
          </v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
          >Semester
        </span>
        <div class="px-3">
          <v-select
            v-model="selectSemester"
            :items="semesters"
            v-on:change="getProject"
            outlined
            hide-details=""
            dense
            label="Select semester"
          ></v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 flex-grow-1 flex-shrink-0"
          style="min-width: 30px"
          >Year
        </span>
        <div class="px-3">
          <v-select
            v-model="selectYear"
            :items="years"
            v-on:change="getProject"
            outlined
            hide-details=""
            dense
            label="Select year"
          ></v-select>
        </div>
      </div>
      <div class="d-flex justify-start align-center my-1">
        <span
          class="text-body-1 ml-sm-3 flex-grow-1 flex-shrink-0"
          style="min-width: 70px"
          >Search by
        </span>
        <div class="px-3">
          <v-text-field
            label="Project Name"
            v-model="selectName"
            :items="searchText"
            hide-details=""
            v-on:change="getProject"
            dense
            clearable
          />
        </div>
      </div>
      <v-btn class="ma-2" outlined color="indigo" @click="syncCarbon()">
        Sync Carbon
      </v-btn>
    </v-row>
    <Loading class="loadingHeight" v-if="loading" />
    <v-row class="ma-3 pt-0" justify="center" style="min-height: 50vh" v-else>
      <v-col cols="11" v-if="!notFound">
        <v-card class="mx-auto mt-5" width="100%">
          <v-list flat>
            <template v-for="(item, index) in items">
              <ProjectList
                :data="item"
                :key="item.detail.topicEn + index.toString()"
                :index="index"
                detailcomponent_name="admin_projectdetail_in_admin_page"
              />
            </template>
          </v-list>
        </v-card>
        <v-row class="ma-0" justify="center" align="center">
          <v-pagination
            class="mt-5 mb-5"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            @input="fetchData"
          ></v-pagination>
        </v-row>
      </v-col>
      <v-col cols="11" v-else>
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          class="mx-auto mt-5"
        >
          Not Found Any Publication
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Loading from "../../../components/common/Loading.vue";
import axios from "axios";
import ProjectList from "../../ProjectList.vue";
const apiConfig = require("../../../../api.config");

export default {
  components: {
    Loading,
    ProjectList,
  },
  data: () => ({
    selectType: "All",
    selectSemester: "All",
    items: [],
    types: ["All", "Senior Projects", "Co-operatives", "Independence Studies"],
    semesters: ["All", "1", "2"],
    selectYear: "All",
    selectName: "",
    searchText: null,
    loading: true,
    notFound: true,
    page: 1,
    size: 8,
    pageCount: 0,
  }),
  watch: {
    selectName: function () {
      this.getProject();
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      let result = [];
      console.log(year, "years");
      result = Array.from(
        { length: year - 2012 },
        (value, index) => 2013 + index + 543
      );
      result.push("All");
      return result.reverse();
    },
  },
  methods: {
    getProject() {
      this.page = 1;
      this.fetchData();
    },
    async fetchData() {
      try {
        this.loading = true;
        let atp;
        let url = apiConfig.routes.project;
        let params = {
          page: this.page,
          size: this.size,
          course: null,
          semester: null,
          year: null,
          supervisorUuid: null,
          project_topic: this.searchText,
          type: null,
        };
        //
        // select Type
        if (this.selectType == "All") {
          params.type = null;
        } else if (this.selectType == "Senior Projects") {
          params.type = "PJ";
        } else if (this.selectType == "Co-operatives") {
          params.type = "CP";
        } else if (this.selectType == "Independence Studies") {
          params.type = "IS";
        } else {
          params.type = this.selectType;
        }

        // selectSemester
        if (this.selectSemester == "All") {
          params.semester = null;
        } else if (this.selectSemester == "1") {
          params.semester = 1;
        } else if (this.selectSemester == "2") {
          params.semester = 2;
        } else {
          params.semester = this.selectSemester;
        }

        // selectYear
        if (this.selectYear != null) {
          if (this.selectYear == "All") {
            params.year = null;
          } else {
            params.year = this.selectYear;
          }
        }

        // search ProjectName
        if (this.selectName === "") delete params["project_topic"];
        else {
          params.project_topic = this.selectName;
        }
        atp = await axios.get(url, { params });
        if (atp.status == 200) {
          this.items = atp.data.result.data;
          this.pageCount = atp.data.result.pageCount;
          console.log(atp);
          console.log(this.items);
          // Not found
          if (atp.data.result.data.length == 0) {
            this.notFound = true;
            this.loading = false;
          } else {
            this.notFound = false;
            this.loading = false;
          }
        }
      } catch (e) {
        this.notFound = true;
        this.loading = false;
      }
    },
    syncCarbon() {
      this.loading = true;
      let url = apiConfig.routes.project + "/sync";
      axios
        .get(url, {
          headers: { authorization: this.$store.state.user.token },
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.project-container {
  background-color: #f2f7ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;
}
</style>
